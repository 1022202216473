import parseISO from 'date-fns/parseISO'
import { toast } from 'react-toastify'
import {
  CART_ITEM_UPDATED,
  CART_ITEM_REMOVED,
  FETCH_CART_COMPLETE,
  APPLY_COUPON_CODE,
  APPLY_COUPON_CODE_SUCCESS,
  APPLY_COUPON_CODE_FAILURE,
  CALCULATE_PRICING_CART,
  APPLY_SERVICE_FEE_SUCCESS,
  CLEAR_CART,
  CART_ITEM_ADD,
  CART_QUANTITY_UPDATE,
  EMPTY_CART,
  REMOVE_CART_DATA,
  GET_DELIVERY_DATES_SUCCESS,
  MEAL_DETAILS,
  CART_ITEM_ERROR,
  MINI_LOADER_UPDATE
} from 'actions/payment'
import { calculateTotal } from '../utils/currency'
import { LOGOUT_HANDLER } from 'actions/account'

const initial = {
  loading: true,
  miniLoading: 0,
  available_delivery_dates: [],
  meals: [],
  rendered: false,
  voucherMeal: [],
  pricing: {
    totalQuantity: 0,
    subtotal: 0,
    deliveryFee: 0,
    commissionFee: 0,
    localTax: 0,
    total: 0,
    cartTotal: 0
  },
  count: 0,
  voucherMealCount: 0,
  xpMealDetails: [],
  cartError: null
}

export default function cart(state = initial, action) {
  const { cartItem, newQuantity } = action
  let newMeals = []
  let cartCount
  let gg = state.meals.filter((mealData) => mealData.meal.guid !== cartItem)
  let d = JSON.parse(JSON.stringify(newMeals))

  let calculateTotalQuantity = (cartMeals) =>
    cartMeals.reduce((acc, cartMeal) => acc + cartMeal.selected_quantity, 0)
  let calculateSubtotal = (cartMeals) =>
    cartMeals.reduce(
      (acc, cartMeal) =>
        acc + cartMeal.selected_quantity * cartMeal.meal.price_cents,
      0
    )
  let calculateDeliveryFee = () => {
    if (state.voucherMealCount === state.count) {
      return 0
    } else {
      return state.fee === null || state.fee === undefined
        ? 1500
        : state.fee.deliveryFee
    }
  }

  let calculateCommissionFee = (subtotal) =>
    (subtotal / 100) *
    (state.fee === null || state.fee === undefined ? 20 : state.fee.serviceFee)

  let calculateServiceLocalFee = (total) =>
    (total / 100) *
    (state.fee === null || state.fee === undefined ? 6 : state.fee.localFee)
  let _calculateTotal = (subtotal, deliveryFee, serviceFee) =>
    calculateTotal(subtotal, deliveryFee, serviceFee)

  let getUpdatedPricing = (cartMeals) => {
    const subtotal = calculateSubtotal(cartMeals)

    const deliveryFee = calculateDeliveryFee()
    const serviceFee = calculateCommissionFee(subtotal)
    const localFee = calculateServiceLocalFee(subtotal)

    const totalFee = _calculateTotal(subtotal, deliveryFee, serviceFee)

    return {
      totalQuantity: calculateTotalQuantity(cartMeals),
      subtotal: subtotal,
      deliveryFee: deliveryFee,
      commissionFee: serviceFee,
      localTax: localFee,
      total: totalFee,
      cartTotal: totalFee + localFee
    }
  }

  const xpDetails = (cartItem) => {
    return {
      name: cartItem.cooking_schedules[0].name,
      price: Number(cartItem.meal.price_cents / 100).toFixed(2),
      chefName: cartItem.cooking_schedules[0].get_chef_name,
      mealImg: cartItem.cooking_schedules[0].listview_image,
      chefEmail: cartItem.cooking_schedules[0].get_chef_email
    }
  }

  switch (action.type) {
    case CART_QUANTITY_UPDATE:
      return Object.assign({}, action.cart, {
        meals: action.cart.meals,
        pricing: getUpdatedPricing(action.cart.meals)
      })
    case FETCH_CART_COMPLETE:
      return Object.assign({}, state, {
        available_delivery_dates: state.available_delivery_dates,
        pricing: getUpdatedPricing(action.localMeal),
        meals: action.localMeal,
        rendered: true,
        loading: false,
        voucherMeal: action.voucherMeal,
        voucherMealCount: calculateTotalQuantity(action.voucherMeal),
        count: action.cart.meals.reduce((a, c) => a + c.selected_quantity, 0),
        xpMealDetails:
          action.localMeal.length > 0 && xpDetails(action.localMeal[0])
      })
    case EMPTY_CART:
      return Object.assign({}, action.cart, {
        available_delivery_dates: [],
        meals: []
      })
    case CART_ITEM_UPDATED:
      newMeals = state.meals.filter(
        (mealData) => mealData.meal.guid === cartItem
      )

      d[0].selected_quantity = newQuantity

      return Object.assign({}, state, {
        meals: [...d, ...gg],
        pricing: getUpdatedPricing(newMeals)
      })
    case CART_ITEM_REMOVED:
      if (action.cart.meals.length === 0) {
        cartCount = 0
      } else {
        cartCount = action.cart.meals.reduce(
          (a, c) => a + c.selected_quantity,
          0
        )
      }
      return Object.assign({}, state, {
        meals: action.localMeal,
        pricing: getUpdatedPricing(action.localMeal),
        count: cartCount,
        voucherMeal: action.voucherMeal,
        voucherMealCount: calculateTotalQuantity(action.voucherMeal)
      })
    case CART_ITEM_ADD:
      if (action.cartResponse.error) {
        toast.error(
          action.cartResponse.error ||
            'Please login before adding items to your cart'
        )
        return Object.assign({}, state, {})
      }
      if (
        action.cartResponse.detail &&
        (action.cartResponse.detail + '').includes(
          'credentials were not provided'
        )
      ) {
        toast.error('Please login before adding items to your cart')
        setTimeout(() => {
          window.location.href = window.location.origin + '/login/'
        }, 2000)
        return Object.assign({}, state, {})
      }
      return Object.assign({}, state, {
        meals: action.localMeal,
        pricing: getUpdatedPricing(action.localMeal),
        count: action.cartResponse.meals.reduce(
          (a, c) => a + c.selected_quantity,
          0
        ),
        voucherMeal: action.voucherMeal,
        voucherMealCount: calculateTotalQuantity(action.voucherMeal),
        xpMealDetails:
          action.localMeal.length > 0 && xpDetails(action.localMeal[0])
      })
    case CLEAR_CART:
      newMeals = state.meals = []
      return Object.assign({}, state, { cart: newMeals, count: 0 })
    case APPLY_COUPON_CODE:
      return Object.assign({}, state, {
        couponLoader: true,
        couponDetails: null,
        couponError: null
      })
    case APPLY_COUPON_CODE_SUCCESS:
      return Object.assign({}, state, {
        couponLoader: false,
        couponDetails: action.data,
        couponError: null
      })
    case APPLY_COUPON_CODE_FAILURE:
      return Object.assign({}, state, {
        couponLoader: false,
        couponDetails: null,
        couponError: action.data
      })
    case APPLY_SERVICE_FEE_SUCCESS: {
      if (
        action.data.data === null ||
        action.data.data === undefined ||
        action.data.data.service_tax === null ||
        action.data.data.service_tax === undefined
      ) {
        return Object.assign({}, state, {
          pricing: getUpdatedPricing(state.meals)
        })
      } else {
        return Object.assign({}, state, {
          fee: {
            deliveryFee: action.data.data.delivery_fee,
            serviceFee: action.data.data.service_tax,
            localFee: action.data.data.local_tax
          }
        })
      }
    }

    case CALCULATE_PRICING_CART: {
      return Object.assign({}, state, {
        pricing: getUpdatedPricing(state.meals)
      })
    }
    case GET_DELIVERY_DATES_SUCCESS: {
      return Object.assign({}, state, {
        available_delivery_dates:
          action.data.available_delivery_dates.map(parseISO)
      })
    }
    case REMOVE_CART_DATA: {
      return Object.assign({}, state, {
        loading: true,
        available_delivery_dates: [],
        meals: [],
        rendered: false,
        pricing: {
          totalQuantity: 0,
          subtotal: 0,
          deliveryFee: 0,
          commissionFee: 0,
          localTax: 0,
          total: 0,
          cartTotal: 0
        },
        count: 0
      })
    }
    case MEAL_DETAILS:
      return {
        ...state,
        name: state.xpMealDetails.map((meal) => meal.name).join(', '),
        price: state.xpMealDetails
          .map((meal) => Number(meal.price / 100).toFixed(2))
          .join(', '),
        chefName: state.xpMealDetails.map((meal) => meal.chefName).join(', '),
        mealImg: state.xpMealDetails.map((meal) => meal.mealImg).join(', '),
        chefEmail: state.xpMealDetails.map((meal) => meal.chefEmail).join(', ')
      }
    case CART_ITEM_ERROR:
      return {
        ...state,
        cartError: action.error
      }
    case LOGOUT_HANDLER: {
      return initial
    }
    case MINI_LOADER_UPDATE: {
      return {
        ...state,
        miniLoading: action.data
      }
    }
    default:
      return state
  }
}
