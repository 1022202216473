import {
  fetchMarketPlace,
  getAllChefs,
  getAllMeals,
  getCuisines,
  getCuisinesByMarketPlace,
  getFeaturedChef,
  getFeaturedMeal,
  getPopularMeals,
  topRatedChefs,
  transformFilters,
  transformSort
} from 'hooks/services/fetchServices'

export const FETCH_MEALS_START = 'FETCH_MEALS_START'
export const FETCH_MEALS_COMPLETE = 'FETCH_MEALS_COMPLETE'
export const MEALS_LOADED = 'MEALS_LOADED'

export function fetchMeals() {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_MEALS_START
    })

    dispatch({
      type: MEALS_LOADED,
      fetching: true
    })
    const { filters, sort, marketplace } = getState()
    // get the filters and sort in a format the API expects
    const apiSort = transformSort('meals', sort)
    const apiFilters = transformFilters('meals', { ...filters, cuisine: filters.cuisine?.id })
    const apiGeometry = { market_place_id: marketplace.marketPlace }
    const mealCategories = ['popular', 'nearby', 'suggested']

    if (marketplace.marketPlace === null) {
      if (marketplace.data === false) {
        dispatch({
          type: MEALS_LOADED,
          fetching: false
        })
      }
      return
    }
    mealCategories.forEach((mealsCategory, index) => {
      let apiPromise = null
      switch (mealsCategory) {
        case 'popular':
          apiPromise = getPopularMeals({ filters: apiFilters, sort: apiSort, geometry: apiGeometry })
          break
        case 'nearby':
          apiPromise = getAllMeals({
            filters: apiFilters,
            sort: apiSort,
            geometry: { market_place_id: marketplace.marketPlace, offset: 0, limit: 12 }
          })
          break
        // case 'suggested':
        //   apiPromise = getSuggestedMeals({ filters: apiFilters, sort: apiSort, geometry: apiGeometry })
        //   break
        default:
          break
      }
      if (apiPromise !== null) {
        apiPromise
          .then((meals) => {
            dispatch({
              type: FETCH_MEALS_COMPLETE,
              meals,
              mealsCategory
            })
            if (index === mealCategories.length - 1) {
              dispatch({
                type: MEALS_LOADED,
                fetching: false
              })
            }
          })
          .catch((error) => {
            dispatch({
              type: MEALS_LOADED,
              fetching: false,
              error: error.response.data.detail
            })
          })
      }
    })
  }
}
export const CLEAR_MEALS = 'CLEAR_MEALS'
export const clearMeals = () => {
  return (dispatch) =>
    dispatch({
      type: CLEAR_MEALS,
      fetching: false
    })
}

export const CLEAR_CHEFS = 'CLEAR_CHEFS'

export const clearChefs = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CHEFS,
      fetching: false
    })
  }
}

export const FETCH_CHEFS_START = 'FETCH_CHEFS_START'
export const FETCH_CHEFS_COMPLETE = 'FETCH_CHEFS_COMPLETE'
export const CHEFS_LOADED = 'CHEFS_LOADED'
export function fetchChefs() {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_CHEFS_START
    })

    dispatch({
      type: CHEFS_LOADED,
      fetching: true
    })

    const { filters, marketplace } = getState()

    // get the filters and sort in a format the API expects
    const apiFilters = transformFilters('chefs', { ...filters, cuisine: filters.cuisine?.id })
    const apiGeometry = { market_place_id: marketplace.marketPlace, offset: 0, limit: 12 }

    // the chef categories to retrieve
    const chefCategories = ['nearby', 'topRated']
    if (marketplace.marketPlace === null) {
      if (marketplace.data === false || marketplace.data === null) {
        dispatch({
          type: CHEFS_LOADED,
          fetching: false
        })
      }
      return
    }

    chefCategories.forEach((chefsCategory) => {
      let apiPromise = null
      switch (chefsCategory) {
        case 'nearby':
          apiPromise = getAllChefs({ filters: apiFilters, geometry: apiGeometry })
          break
        default:
          break
      }
      if (apiPromise !== null) {
        apiPromise
          .then((chefs) => {
            dispatch({
              type: FETCH_CHEFS_COMPLETE,
              chefsCategory,
              chefs
            })
          })
          .catch((error) => {
            dispatch({
              type: CHEFS_LOADED,
              fetching: false,
              error: error.response.data.detail
            })
          })
      }
    })
  }
}

export const SET_FILTER = 'SET_FILTER'
export function setFilter({ filterName, filterVal }) {
  return (dispatch) => {
    dispatch({
      type: SET_FILTER,
      filterName,
      filterVal
    })
  }
}

export const SET_SORT = 'SET_SORT'
export function setSort({ sortBy, sortDirection }) {
  return {
    type: SET_SORT,
    sortBy,
    sortDirection
  }
}

export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export function clearFilters() {
  return {
    type: CLEAR_FILTERS
  }
}

export const FETCH_CUISINES_START = 'FETCH_CUISINES_START'
export const FETCH_CUISINES_COMPLETE = 'FETCH_CUISINES_COMPLETE'
export function fetchCuisines() {
  return (dispatch) => {
    dispatch({
      type: FETCH_CUISINES_START
    })

    getCuisines().then((cuisines) => {
      dispatch({
        type: FETCH_CUISINES_COMPLETE,
        cuisines
      })
    })
  }
}

export const FETCH_CUISINES_by_MARKETPLACE_START = 'FETCH_CUISINES_by_MARKETPLACE_START'
export const FETCH_CUISINES_by_MARKETPLACE_COMPLETE = 'FETCH_CUISINES_by_MARKETPLACE_COMPLETE'
export function fetchCuisinesByMarketPlace() {
  return (dispatch, getState) => {
    const { marketplace } = getState()
    if (marketplace.marketPlace === null) {
      if (marketplace.data === false || marketplace.data === null) {
        dispatch({
          type: FETCH_CUISINES_by_MARKETPLACE_COMPLETE,
          cuisines: []
        })
      }
      return
    }
    dispatch({
      type: FETCH_CUISINES_by_MARKETPLACE_START
    })
    getCuisinesByMarketPlace(marketplace.marketPlace)
      .then((cuisines) => {
        if (cuisines.data) {
          dispatch({
            type: FETCH_CUISINES_by_MARKETPLACE_COMPLETE,
            cuisines: []
          })
        }
        dispatch({
          type: FETCH_CUISINES_by_MARKETPLACE_COMPLETE,
          cuisines
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: FETCH_CUISINES_by_MARKETPLACE_COMPLETE,
          cuisines: []
        })
      })
  }
}

export const FETCH_FEATURES_START = 'FETCH_FEATURES_START'
export const FETCH_FEATURES_COMPLETE = 'FETCH_FEATURES_COMPLETE'
export const FETCH_FEATURES_CHEF_ERROR = 'FETCH_FEATURES_CHEF_ERROR'
export const FETCH_FEATURES_MEAL_ERROR = 'FETCH_FEATURES_MEAL_ERROR'
export function fetchFeatures() {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_FEATURES_START
    })

    const { features, marketplace } = getState()

    const apiGeometry = { market_place_id: marketplace.marketPlace, is_featured: 1 }
    if (typeof apiGeometry.market_place_id !== 'number' || apiGeometry.market_place_id === null) {
      return
    }
    // use the appropriate api method for each meal category we want to update
    Object.keys(features).forEach((featureCategory) => {
      switch (featureCategory) {
        case 'chef':
          getFeaturedChef({ geometry: apiGeometry })
            .then((featuredChefs) => {
              dispatch({
                type: FETCH_FEATURES_COMPLETE,
                featureCategory,
                feature: featuredChefs
              })
            })
            .catch((error) => {
              dispatch({
                type: FETCH_FEATURES_CHEF_ERROR,
                error: error.response.data.detail
              })
            })
          break
        case 'meal':
          getFeaturedMeal(marketplace.marketPlace)
            .then((featuredMeals) => {
              dispatch({
                type: FETCH_FEATURES_COMPLETE,
                featureCategory,
                feature: featuredMeals
              })
            })
            .catch((error) => {
              dispatch({
                type: FETCH_FEATURES_MEAL_ERROR,
                error: error.response.data.detail
              })
            })
          break
        default:
          break
      }
    })
  }
}

export const GET_MARKET_PLACE = 'GET_MARKET_PLACE'
export const GET_MARKET_PLACE_SUCCESS = 'GET_MARKET_PLACE_SUCCESS'
export const GET_MARKET_PLACE_FAILURE = 'GET_MARKET_PLACE_FAILURE'
export const SET_GLOBAL_MARKET_PLACE = 'SET_GLOBAL_MARKET_PLACE'
export function getMarketPlace() {
  return (dispatch, getState) => {
    dispatch({ type: GET_MARKET_PLACE })
    const { geometry } = getState()
    const apiGeometry = { lat: geometry.latitude, lng: geometry.longitude }
    if (!apiGeometry.lat || !apiGeometry.lng) {
      dispatch(globalPlace())
      return
    }
    fetchMarketPlace(apiGeometry)
      .then((response) => {
        dispatch({ type: GET_MARKET_PLACE_SUCCESS, response })
        localStorage.setItem('marketPlace', JSON.stringify(response))
        if (response.data === false) {
          dispatch(clearChefs())
          dispatch(clearMeals())
          dispatch(clearTopRatedChef())
          dispatch(clearFeatures())
          dispatch(fetchCuisinesByMarketPlace())
          return
        }
      })
      .catch((error) => {
        dispatch({ type: GET_MARKET_PLACE_FAILURE, error })
      })
  }
}

export function removeLoading() {
  return (dispatch) => {
    dispatch({
      type: CHEFS_LOADED,
      fetching: false
    })
    dispatch({
      type: MEALS_LOADED,
      fetching: false
    })
  }
}

export function globalPlace() {
  return (dispatch) => {
    const globalMarketPlace = JSON.parse(localStorage.getItem('global_marketplace'))
    dispatch({ type: SET_GLOBAL_MARKET_PLACE, globalMarketPlace })
  }
}

export function allMealPageApis() {
  return (dispatch) => {
    dispatch(fetchMeals())
    dispatch(fetchChefs())
    dispatch(fetchCuisinesByMarketPlace())
  }
}

export const LOAD_MORE_MEALS = 'LOAD_MORE_MEALS'
export const LOAD_MORE_MEALS_SUCCESS = 'LOAD_MORE_MEALS_SUCCESS'
export const LOAD_MORE_MEALS_FAILURE = 'LOAD_MORE_MEALS_FAILURE'
export function loadMoreMeals(offset, is_voucher_meal) {
  return (dispatch, getState) => {
    const { filters, sort, marketplace } = getState()
    const apiFilters = transformFilters('meals', { ...filters, cuisine: filters.cuisine?.id })
    const apiGeometry =
      is_voucher_meal === 'True'
        ? { market_place_id: marketplace.marketPlace, limit: 12, offset: offset, is_voucher_meal }
        : { market_place_id: marketplace.marketPlace, limit: 12, offset: offset }
    const apiSort = transformSort('meals', sort)
    dispatch({ type: LOAD_MORE_MEALS })
    if (apiGeometry.market_place_id === null) {
      dispatch({ type: LOAD_MORE_MEALS_FAILURE, data: 'not market place' })
      return
    }
    getAllMeals({ geometry: apiGeometry, filters: apiFilters, sort: apiSort })
      .then((res) => {
        dispatch({ type: LOAD_MORE_MEALS_SUCCESS, data: res, offset })
      })
      .catch((error) => {
        dispatch({ type: LOAD_MORE_MEALS_FAILURE, data: error })
      })
  }
}

export const LOAD_MORE_CHEFS = 'LOAD_MORE_CHEFS'
export const LOAD_MORE_CHEFS_SUCCESS = 'LOAD_MORE_CHEFS_SUCCESS'
export const LOAD_MORE_CHEFS_FAILURE = 'LOAD_MORE_CHEFS_FAILURE'
export function loadMoreChefs(offset) {
  return (dispatch, getState) => {
    const { marketplace } = getState()
    const apiGeometry = { market_place_id: marketplace.marketPlace, limit: 12, offset: offset }
    dispatch({ type: LOAD_MORE_CHEFS })
    getAllChefs({ geometry: apiGeometry })
      .then((res) => {
        dispatch({ type: LOAD_MORE_CHEFS_SUCCESS, data: res })
      })
      .catch((error) => {
        dispatch({ type: LOAD_MORE_CHEFS_FAILURE, data: error })
      })
  }
}
export const TOP_RATED_CHEFS_SUCCESS = 'TOP_RATED_CHEFS_SUCCESS'
export const TOP_RATED_CHEFS_ERROR = 'TOP_RATED_CHEFS_ERROR'
export function fetchtopReatedChef() {
  return (dispatch, getState) => {
    const { marketplace } = getState()
    const apiGeometry = { market_place_id: marketplace.marketPlace }
    if (marketplace.marketPlace === null) return
    topRatedChefs({ geometry: apiGeometry }).then((res) => {
      if (res.data === false) {
        dispatch({ type: TOP_RATED_CHEFS_ERROR, data: res })
      }
      dispatch({ type: TOP_RATED_CHEFS_SUCCESS, data: res })
    })
  }
}

export const TOP_RATED_CHEFS_CLEAR = 'TOP_RATED_CHEFS_CLEAR'
export function clearTopRatedChef() {
  return (dispatch) => {
    dispatch({ type: TOP_RATED_CHEFS_ERROR })
  }
}

export const FETCH_FEATURES_CLEAR = 'FETCH_FEATURES_CLEAR'
export function clearFeatures() {
  return (dispatch) => {
    dispatch({ type: FETCH_FEATURES_CLEAR })
  }
}
