/*eslint quotes: ["error", "single", { "allowTemplateLiterals": true }]*/
import { protectedPost } from 'clients/postClient'
import { protectedPatch, protectedPut } from 'clients/putClient'

const addInstruction = async (id, instruction) => {
  const { data: response } = await protectedPut(`/eater/cart/${id}/add_instruction/`, { instruction })
  return response
}

const updateAddress = async (id, data) => {
  const { data: response } = await protectedPut(`/eater-address/${id}/`, data)
  return response
}

const updateChef = async (data) => {
  const { data: response } = await protectedPatch(`/chef/update/`, data)
  return response
}

const updateSchedule = async (id, data) => {
  const { data: response } = await protectedPatch(`/v2/schedulemeal/${id}/`, data)
  return response
}
const scheduleMeal = async (data) => {
  const { data: response } = await protectedPost('/v2/schedulemeal/', data)
  return response
}
const updateOrgUser = async (id, data) => {
  const { data: response } = await protectedPut(`/corporate_user_list/${id}/`, data)
  return response
}
const activateUser = async (id) => {
  const { data: response } = await protectedPut(`/corporate_user_list/${id}/activate/`)
  return response
}
const updateChefKitchen = async (id, data) => {
  const { data: response } = await protectedPut(`/chef-kitchen/${id}/`, data)
  return response
}

const editMeal = async (id, data) => {
  const { data: response } = await protectedPatch(`/v2/meal/${id}/`, data)
  return response
}

const completeProfile = async (data) => {
  const { data: response } = await protectedPut(`/complete_profile/`, data)
  return response
}

const setisSelect = async (id, data) => {
  const { data: response } = await protectedPut(`/user-cuisines/${id}/`, data)
  return response
}

const updateChefBios = async (data) => {
  const { data: response } = await protectedPatch(`/chef-bio/`, data)
  return response
}

export {
  addInstruction,
  updateAddress,
  updateChef,
  updateSchedule,
  scheduleMeal,
  updateOrgUser,
  activateUser,
  updateChefKitchen,
  editMeal,
  completeProfile,
  setisSelect,
  updateChefBios
}
