import { LOGOUT_HANDLER } from 'actions/account'
import {
  CLIENT_SECRET_ERROR,
  CLIENT_SECRET_SUCCESS,
  GET_DELIVERY_DATES_SUCCESS,
  CLEAR_CART,
  SET_PAYMENT_METHOD,
  USER_WALLET_SUCCESS,
  USER_WALLET_ERROR,
  SET_WALLET_PAYMENT,
  CART_ITEM_UPDATED,
  SET_ORDER_FIELD,
  PAYMENT_INTENT_UPDATED,
  FETCH_CART_COMPLETE,
  CART_ITEM_REMOVED,
  SET_DELIVERY_ADDRESS,
  REMOVE_WALLET_PAYMENT,
  REMOVE_PAYMENT_METHOD,
  SET_VOUCHER_PAYMENT,
  REMOVE_VOUCHER_PAYMENT
} from 'actions/payment'
import parseISO from 'date-fns/parseISO'
const initial = {
  deliveryDetails: {
    deliveryDate: null,
    address_id: null
  },
  paymentMethod: {},
  meals: [],
  paymentIntent: null,
  clientSecret: null,
  cartTransaction: null,
  card: null,
  payments: [],
  xpMealDetails: [],
  clientSecretKey: '',
  clientSecretError: '',
  wallet: null,
  walletError: null,
  walletAmount: null,
  userWalletCount: 0
}

export default function order(state = initial, action) {
  const methods = state.payments.map((e) => e.method)
  let d = JSON.parse(JSON.stringify(state['deliveryDetails']))
  switch (action.type) {
    case SET_DELIVERY_ADDRESS:
      d['city'] = action.city
      d['address_id'] = action.id
      d['state'] = action.state
      d['title'] = action.title
      d['address'] = action.address
      d['postalCode'] = action.postalCode
      d['contactPerson'] = action.contactPerson
      d['extraInstructions'] = action.extraInstructions
      d['contactPersonNumber'] = action.contactPersonNumber
      d['deliveryDate'] = d.deliveryDate && parseISO(d.deliveryDate)
      return Object.assign({}, state, { deliveryDetails: d })
    case FETCH_CART_COMPLETE: {
      const { localMeal } = action
      return Object.assign({}, state, {
        deliveryDetails: d,
        meals: localMeal.map((cartItem) => ({
          guid: cartItem.meal.guid,
          selected_quantity: cartItem.selected_quantity,
          instruction: cartItem.instruction || ''
        }))
      })
    }
    case CART_ITEM_REMOVED:
      return Object.assign({}, state, {
        meals: action.localMeal
      })
    case CART_ITEM_UPDATED:
      return Object.assign({}, state, {
        meals: state.meals.map((mealData) => {
          if (mealData.guid !== action.cartItem.meal.guid) {
            return mealData
          }
          return Object.assign({}, mealData, { selected_quantity: action.newQuantity })
        })
      })
    case SET_ORDER_FIELD: {
      const { orderPart, fieldName, value } = action
      return fieldName === null
        ? { ...state, [orderPart]: value }
        : { ...state, [orderPart]: { ...state[orderPart], [fieldName]: value } }
    }
    case SET_PAYMENT_METHOD:
    case SET_WALLET_PAYMENT:
    case SET_VOUCHER_PAYMENT:
      if (action.payload.method === 'voucher') {
        state.payments = state.payments.filter((e) => e.method !== action.payload.method)
        state.payments = [...state.payments, action.payload]
        return {
          ...state
        }
      } else if (methods.includes(action.payload.method)) {
        if (action.payload.method === 'credit_card') {
          state.payments = state.payments.filter((e) => e.method !== action.payload.method)
          state.payments = [...state.payments, action.payload]
          return {
            ...state
          }
        } else {
          state.payments = state.payments.filter((e) => e.method !== action.payload.method)
          return {
            ...state
          }
        }
      } else {
        state.payments = [...state.payments, action.payload]
        return {
          ...state
        }
      }
    case REMOVE_PAYMENT_METHOD:
      state.payments = []
      state.paymentMethod = {}
      return {
        ...state
      }
    case REMOVE_WALLET_PAYMENT:
      state.payments = state.payments.filter((e) => e.method !== 'wallet')
      return {
        ...state
      }
    case REMOVE_VOUCHER_PAYMENT:
      state.payments = state.payments.filter((e) => e.method !== 'voucher')
      return {
        ...state
      }
    case PAYMENT_INTENT_UPDATED: {
      const { paymentIntentId, clientSecret, cartTransaction } = action
      return Object.assign({}, state, { paymentIntent: paymentIntentId, clientSecret, cartTransaction })
    }
    case CLEAR_CART: {
      const { cart } = action
      return Object.assign({}, state, { cart })
    }
    case USER_WALLET_SUCCESS:
      return Object.assign({}, state, { wallet: action.data, walletError: null, walletAmount: action.amount, userWalletCount: action.count })
    case USER_WALLET_ERROR:
      return Object.assign({}, state, { walletError: action.data })
    case CLIENT_SECRET_ERROR: {
      return {
        ...state,
        clientSecretError: action.data
      }
    }
    case CLIENT_SECRET_SUCCESS: {
      return {
        ...state,
        clientSecretKey: action.data
      }
    }
    case GET_DELIVERY_DATES_SUCCESS: {
      d.deliveryDate = action.data.available_delivery_dates.length > 0 &&parseISO(action.data.available_delivery_dates[0])
      return Object.assign({}, state, {
        deliveryDetails: d
      })
    }
    case LOGOUT_HANDLER: {
      return initial
    }
    default:
      return state
  }
}
