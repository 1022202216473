import { getMarketPlace } from './mealAndChefData'
import { getMarketPlaceList } from 'hooks/services/fetchServices'
export const SET_GEOMETRY = 'SET_GEOMETRY'
export const setGeometry = ({ latitude, longitude, address, zipcode }) => {
  return (dispatch) => {
    dispatch({
      type: SET_GEOMETRY,
      latitude,
      longitude,
      address,
      zipcode
    })
    window.localStorage.setItem('__dindin__coords__', JSON.stringify({ latitude, longitude, address, zipcode }))
    dispatch(getMarketPlace())
  }
}

export const UPDATE_PAGE_CAT = 'UPDATE_PAGE_CAT'
export const updatePageCategories = (payload) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PAGE_CAT,
      payload
    })
  }
}

export const FETCH_MARKETPLACE_START = 'FETCH_MARKETPLACE_START'
export const FETCH_MARKETPLACE_SUCCESS = 'FETCH_MARKETPLACE_SUCCESS'
export const FETCH_MARKETPLACE_FAILURE = 'FETCH_MARKETPLACE_FAILURE'
export function fetchMarketPlaceList() {
  return (dispatch, getState) => {
    const { geometry } = getState()
    dispatch({ type: FETCH_MARKETPLACE_START, fetching: true })
    getMarketPlaceList()
      .then((response) => {
        dispatch({ type: FETCH_MARKETPLACE_SUCCESS, response, fetching: false })
        localStorage.setItem('global_marketplace', JSON.stringify(response.filter((d) => d.is_global)))
        if (!geometry.latitude) {
          dispatch(getMarketPlace())
        }
      })

      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_MARKETPLACE_FAILURE, errors, fetching: false })
      })
  }
}

export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE'
export const ADD_MESSAGE = 'ADD_MESSAGE'
export const ALLOWED_LOCATION = 'ALLOWED_LOCATION'
export const NOT_ALLOWED_LOCATION = 'NOT_ALLOWED_LOCATION'
export const REMOVE_ALLOWED_LOCATION = 'REMOVE_ALLOWED_LOCATION'

export const addMessage = () => {
  return (dispatch) => {
    dispatch({ type: ADD_MESSAGE, payload: true })
  }
}

export const removeMessage = () => {
  return (dispatch) => {
    dispatch({ type: REMOVE_MESSAGE, payload: false })
  }
}

export const addNotAllowdMessage = () => {
  return (dispatch) => {
    dispatch({ type: ALLOWED_LOCATION, payload: false })
  }
}

export const removeNotAllowdMessage = () => {
  return (dispatch) => {
    dispatch({ type: NOT_ALLOWED_LOCATION, payload: true })
  }
}
