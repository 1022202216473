import {
  LOAD_MORE_MEALS_SUCCESS,
  LOAD_MORE_MEALS_FAILURE,
  LOAD_MORE_MEALS,
  CLEAR_MEALS,
  FETCH_MEALS_COMPLETE,
  MEALS_LOADED,
  FETCH_CUISINES_by_MARKETPLACE_START,
  FETCH_CUISINES_by_MARKETPLACE_COMPLETE
} from 'actions/mealAndChefData'

const initial = {
  // suggested: [],
  popular: [],
  nearby: [],
  nextNearby: null,
  cuisineGrouped: {},
  upcoming: [],
  historical: [],
  moreByChef: [],
  fetchingMeals: false,
  nextPageLoading: false,
  isFetched: false,
  cuisines: [],
  cuisinesLoading: false
}

export default function meals(state = initial, action = {}) {
  let data = []
  switch (action.type) {
    case FETCH_MEALS_COMPLETE:
      if (action.meals && Array.isArray(action.meals.data?.hits?.hits)) {
        data = action.meals.data?.hits?.hits
      } else if (action.meals && Array.isArray(action.meals.data)) {
        data = action.meals.data
      }
      if (action.mealsCategory === 'nearby') {
        state.nextNearby = action.meals.data?.hits?.total.value
      }
      return Object.assign({}, state, {
        [action.mealsCategory]: data,
        fetchingMeals: false,
        isFetched: true
      })
    case MEALS_LOADED:
      return Object.assign({}, state, {
        fetchingMeals: action.fetching
      })
    case CLEAR_MEALS:
      return Object.assign({}, state, {
        fetchingMeals: action.fetching,
        // suggested: [],
        popular: [],
        nearby: [],
        nextNearby: null,
        cuisineGrouped: {},
        upcoming: [],
        historical: []
      })
    case LOAD_MORE_MEALS_SUCCESS:
      return Object.assign({}, state, {
        nearby: action.offset === 0 ? action.data.data.hits.hits : [...state.nearby, ...action.data.data.hits.hits],
        nextPageLoading: false,
        nextNearby: action.data.data.hits.total.value
      })
    case LOAD_MORE_MEALS:
      return Object.assign({}, state, {
        nextPageLoading: true
      })
    case LOAD_MORE_MEALS_FAILURE:
      return Object.assign({}, state, {
        nextPageLoading: false
      })
    case FETCH_CUISINES_by_MARKETPLACE_START:
      return Object.assign({}, state, {
        cuisinesLoading: true
      })
    case FETCH_CUISINES_by_MARKETPLACE_COMPLETE:
      return Object.assign({}, state, {
        cuisines: action.cuisines || [],
        cuisinesLoading: false
      })
    default:
      return state
  }
}
