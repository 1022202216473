import { LOGOUT_HANDLER } from 'actions/account'
import { FETCH_CARDS_FAILURE, FETCH_CARDS_START, FETCH_CARDS_SUCCESS, POST_CARDS_SUCCESS } from 'actions/payment'

const intialState = {
  cards: [],
  featching: null,
  error: null
}

export default function cards(state = intialState, action) {
  switch (action.type) {
    case FETCH_CARDS_START:
      return {
        ...state,
        featching: true
      }
    case FETCH_CARDS_SUCCESS:

      return {
        ...state,
        cards: action.response,
        featching: false,
        error: null
      }
    case FETCH_CARDS_FAILURE:
      return {
        ...state,
        error: action.errors,
        featching: false
      }
    case POST_CARDS_SUCCESS:
      return {
        ...state,
        error: null,
        cards: [...state.cards, action.card],
        featching: false
      }
    case LOGOUT_HANDLER: {
      return intialState
    }
    default:
      return state
  }
}
