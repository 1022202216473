import { protectedDelete } from 'clients/deleteClient'

const deleteCartItem = async (mealId) => {
  const { data: response } = await protectedDelete('/newcart/', mealId)
  return response
}

const deleteAddress = async (id) => {
  const { data: response } = await protectedDelete(`/eater-address/${id}/`)
  return response
}

const deleteChefMeal = async (id) => {
  const { data: response } = await protectedDelete(`/v2/meal/${id}/`)
  return response
}

const deleteSchedule = async (id) => {
  const { data: response } = await protectedDelete(`/v2/schedulemeal/${id}/`)
  return response
}

const deleteOrgUser = async (id) => {
  const { data: response } = await protectedDelete(`/corporate_user_list/${id}/`)
  return response
}

const deleteCard = async (card_id) => {
  const { data: response } = await protectedDelete(`/eater-creditcard/${card_id}/`)
  return response
}

export { deleteCartItem, deleteAddress, deleteChefMeal, deleteSchedule, deleteOrgUser, deleteCard }
