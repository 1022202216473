import React from 'react'
const OrgTransaction = React.lazy(() => import('pages/OrgTransaction/OrgTransaction'))
const ChefProfileEdit = React.lazy(() => import('components/Chef/ChefProfileEdit'))
const ChooseUser = React.lazy(() => import('components/ChooseUser'))
const OrganizationSignUp = React.lazy(() => import('pages/auth/signup/organization/OrganizationSignUp'))
const CorporateAdminHoc = React.lazy(() => import('components/CorporateAdmin/CorporateAdminHoc'))
const AllOrders = React.lazy(() => import('components/CorporateAdmin/AllOrders'))
const ReportsAdmin = React.lazy(() => import('components/CorporateAdmin/ReportsAdmin'))
const CompleteProfile = React.lazy(() => import('pages/auth/signup/CompleteProfile'))
const Reset = React.lazy(() => import('pages/auth/reset/Reset'))
const ForgotPassword = React.lazy(() => import('pages/auth/reset/ForgotPassword'))
const Chef = React.lazy(() => import('pages/auth/signup/chef/Chef'))
const Dashboard = React.lazy(() => import('components/Chef/Dashboard'))
const NewOrders = React.lazy(() => import('components/Chef/NewOrders'))
const ChefMenu = React.lazy(() => import('components/Chef/ChefMenu'))
const ChefHoc = React.lazy(() => import('components/Chef/ChefHoc'))
const AddNewMeal = React.lazy(() => import('components/Chef/AddNewMeal'))
const EditMeal = React.lazy(() => import('components/Chef/EditMeal'))
const Profile = React.lazy(() => import('pages/ChefSection/Profile'))
const CookingSchedule = React.lazy(() => import('pages/ChefSection/CookingSchedule'))
const NewSchedule = React.lazy(() => import('components/Chef/Component/NewSchedule'))
const ChefProfile = React.lazy(() => import('components/Chef/Container/ChefProfile'))
const OrderDetails = React.lazy(() => import('components/Chef/OrderDetails'))
const MenuDetail = React.lazy(() => import('components/Chef/MenuDetail'))
const CustomerSignup = React.lazy(() => import('pages/auth/CustomerSignup'))
const AboutUsPage = React.lazy(() => import('pages/staticPages/AboutUsPage'))
const OurTeamPage = React.lazy(() => import('pages/staticPages/OurTeamPage'))
const FaqPage = React.lazy(() => import('pages/staticPages/FaqPage'))
const ChangePasswordUser = React.lazy(() => import('pages/auth/ChangePasswordUser'))
const ContactUs = React.lazy(() => import('pages/ContactUs/ContactUs'))
const ErrorPage = React.lazy(() => import('pages/NotFound/ErrorPage'))
const ProtectedRoute = React.lazy(() => import('./ProtectedRoute'))
const PublicRoute = React.lazy(() => import('./PublicRoute'))
const ChangePasswordChef = React.lazy(() => import('components/Chef/ChangePasswordChef'))
const PasswordReset = React.lazy(() => import('components/Subscrption/Component/PasswordReset'))
const CorporateProgram = React.lazy(() => import('pages/staticPages/CorporateProgram'))
const CuisineSolutions = React.lazy(() => import('pages/staticPages/CuisineSolutions'))
const HomeRoute = React.lazy(() => import('pages/Homapage/Homepage'))
const SignInRoute = React.lazy(() => import('pages/auth/signin'))
const AllMealsRoute = React.lazy(() => import('pages/AllMeals/AllMeals'))
const AllChefRoute = React.lazy(() => import('pages/AllChefs/AllChefs'))
const PrivacyRoute = React.lazy(() => import('pages/staticPages/PrivacyPolicy'))
const CheckoutRoute = React.lazy(() => import('pages/Checkout'))
const ProfileRoute = React.lazy(() => import('pages/Profile/Profile'))
const OrdersRoute = React.lazy(() => import('pages/Orders/Orders'))
const SelecSignupRoute = React.lazy(() => import('components/SelecSignup'))
const MealDetailRoute = React.lazy(() => import('pages/MealDetails/MealDetailsPage'))
const ChefDetailsRoute = React.lazy(() => import('pages/AllChefs/ChefDetails'))
const HowitworksRoute = React.lazy(() => import('pages/staticPages/HowitWorksPage'))
const UserDashboard = React.lazy(() => import('pages/UserDashboard/Dashboard'))
const FoodSafetyRoute = React.lazy(() => import('pages/staticPages/FoodSafety'))
const TermAndConditonsRoute = React.lazy(() => import('pages/staticPages/TermAndConditons'))
const AboutUsMobile = React.lazy(() => import('pages/staticMobilePages/AboutUsPage'))
const CorporateProgramMobile = React.lazy(() => import('pages/staticMobilePages/CorporateProgram'))
const FaqPageMobile = React.lazy(() => import('pages/staticMobilePages/FaqPage'))
const FoodSafetyMobile = React.lazy(() => import('pages/staticMobilePages/FoodSafety'))
const OurTeamPageMobile = React.lazy(() => import('pages/staticMobilePages/OurTeamPage'))
const TermAndConditonsMobile = React.lazy(() => import('pages/staticMobilePages/TermAndConditons'))
const PrivacyPolicyMobile = React.lazy(() => import('pages/staticMobilePages/PrivacyPolicy'))
const LandingPageRoute = React.lazy(() => import('pages/LandingPage/LandingPage'))

const VoucherMealPage = React.lazy(() => import('pages/VoucherMeals/VoucherMeals'))
const ManualDistribution = React.lazy(() => import('components/CorporateAdmin/ManualDistribution/ManualDistribution'))

export {
  OrgTransaction,
  ChefProfileEdit,
  ChooseUser,
  OrganizationSignUp,
  TermAndConditonsRoute,
  FaqPage,
  FoodSafetyRoute,
  ForgotPassword,
  UserDashboard,
  HomeRoute,
  HowitworksRoute,
  ChangePasswordChef,
  ChangePasswordUser,
  CheckoutRoute,
  Chef,
  ChefDetailsRoute,
  AboutUsPage,
  AddNewMeal,
  AllChefRoute,
  PasswordReset,
  PrivacyRoute,
  Profile,
  ProfileRoute,
  ProtectedRoute,
  PublicRoute,
  ReportsAdmin,
  Reset,
  SelecSignupRoute,
  SignInRoute,
  AboutUsMobile,
  CorporateProgramMobile,
  FaqPageMobile,
  FoodSafetyMobile,
  OurTeamPageMobile,
  PrivacyPolicyMobile,
  TermAndConditonsMobile,
  AllMealsRoute,
  AllOrders,
  ChefHoc,
  ChefMenu,
  ChefProfile,
  CompleteProfile,
  ContactUs,
  CookingSchedule,
  CorporateAdminHoc,
  CorporateProgram,
  CuisineSolutions,
  CustomerSignup,
  Dashboard,
  EditMeal,
  ErrorPage,
  MealDetailRoute,
  MenuDetail,
  NewOrders,
  NewSchedule,
  OrderDetails,
  OrdersRoute,
  OurTeamPage,
  LandingPageRoute,
  VoucherMealPage,
  ManualDistribution
}
