import React, { useCallback, useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter as Router } from 'react-router-dom'
import SideBarContext from 'components/context/sidebarContext'
import AllRoutes from 'pages/Redirection/AllRoutes'
import { AuthContext } from 'hooks/context/userContext'
import { getAuthHeaders } from 'constants/getHeader'
import Cookies from 'js-cookie'
import { Provider } from 'react-redux'
import Wrapper from 'components/Shared/Wrapper'
import FallbackPage from 'hoc/FallbackPage'
import TagManager from 'react-gtm-module'
import store from './store'
import { xtremepushGlobal } from 'utils/static'

const tagManagerArgs = {
  gtmId: 'GTM-KCT6JCJ'
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      retry: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false
    }
  }
})

function App() {
  const [openSidebar, setOpenSidebar] = useState(false)
  const cartCount = Number(localStorage.getItem('cart_count'))
  const [mealBtn, setMealBtn] = useState(true)
  const [isLogged, setIsLogged] = useState(false)
  const token = localStorage.getItem('dindin_key')
  const login = useCallback(() => {
    setIsLogged(true)
  }, [])

  const logout = useCallback(() => {
    setIsLogged(false)
    Cookies.remove('authtoken')
  }, [])
  const value = {
    openSidebar,
    setOpenSidebar,
    mealBtn,
    setMealBtn
  }
  useEffect(() => {
    xtremepushGlobal()
  }, [location.pathname, cartCount])

  const { xtremepush } = window

  useEffect(() => {
    token && login()
    !token && logout()
    getAuthHeaders()
  }, [token])

  const loadFunction = function () {
    // event.preventDefault()
    const cartFirstItem = JSON.parse(localStorage.getItem('cart_first_item'))
    const featuredMealData = JSON.parse(localStorage.getItem('featured_meal'))
    const featuredChefData = JSON.parse(localStorage.getItem('featured_chef'))

    const cartItemData = {
      meal_name: cartFirstItem?.name,
      meal_price: cartFirstItem?.price,
      meal_chef: cartFirstItem?.chefName,
      meal_image: cartFirstItem?.mealImg
    }
    const featuredData = {
      featured_meal_image: featuredMealData?.meal_img,
      featured_meal_name: featuredMealData?.meal_name,
      featured_chef_image: featuredChefData?.chef_img,
      featured_chef_name: featuredChefData?.chef_name
    }

    if (cartCount > 0) {
      xtremepush('event', 'cart_abandonment', cartItemData)
    } else if (cartCount === 0) {
      xtremepush('event', 'browse_abandonment', featuredData)
    }
  }

  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
    window.addEventListener('beforeunload', loadFunction)

    return () => {
      window.removeEventListener('beforeunload', loadFunction)
    }
  }, [])

  return (
    <Router>
      <Provider store={store}>
        <AuthContext.Provider value={{ isLogged, login, logout }}>
          <SideBarContext.Provider value={value}>
            <QueryClientProvider client={queryClient}>
              <>
                <Wrapper>
                  <React.Suspense fallback={<FallbackPage />}>
                    <AllRoutes />
                  </React.Suspense>
                </Wrapper>
              </>
            </QueryClientProvider>
          </SideBarContext.Provider>
        </AuthContext.Provider>
      </Provider>
    </Router>
  )
}

export default App
