import { exportAllOrders, exportCorporateUsers, forCorporate } from 'hooks/services/postService'
import { toast } from 'react-toastify'

export const SEND_CORPORATE_MESSAGE = 'SEND_CORPORATE_MESSAGE'
export const SEND_CORPORATE_MESSAGE_SUCCESS = 'SEND_CORPORATE_MESSAGE_SUCCESS'
export const SEND_CORPORATE_MESSAGE_FAILURE = 'SEND_CORPORATE_MESSAGE_FAILURE'
export function sendCorporateMessage(data) {
  return (dispatch) => {
    dispatch({ type: SEND_CORPORATE_MESSAGE })

    forCorporate(data)
      .then((res) => {
        dispatch({ type: SEND_CORPORATE_MESSAGE_SUCCESS, data: res })
        toast.success('Thank you for your message. Our Customer Service Team will get back to you as soon as possible.')
      })
      .catch((error) => {
        dispatch({ type: SEND_CORPORATE_MESSAGE_FAILURE, data: error })
        toast.error('Server error!.')
      })
  }
}

export const SEND_ORDER_DATA = 'SEND_ORDER_DATA'
export const SEND_ORDER_DATA_SUCCESS = 'SEND_ORDER_DATA_SUCCESS'
export const SEND_ORDER_DATA_FAILURE = 'SEND_ORDER_DATA_FAILURE'
export function sendOrderData() {
  return (dispatch) => {
    dispatch({ type: SEND_ORDER_DATA })
    exportAllOrders()
      .then((res) => {
        dispatch({ type: SEND_ORDER_DATA_SUCCESS, data: res })
        toast.success(res.status || 'Data sent successfully to the mail')
      })
      .catch((error) => {
        dispatch({ type: SEND_ORDER_DATA_FAILURE, data: error })
      })
  }
}

export const SEND_USER_DATA = 'SEND_USER_DATA'
export const SEND_USER_DATA_SUCCESS = 'SEND_USER_DATA_SUCCESS'
export const SEND_USER_DATA_FAILURE = 'SEND_USER_DATA_FAILURE'
export function sendUserData(data) {
  return (dispatch) => {
    dispatch({ type: SEND_USER_DATA })
    exportCorporateUsers(data)
      .then((res) => {
        dispatch({ type: SEND_USER_DATA_SUCCESS, data: res })
        toast.success(res.status)
      })
      .catch((error) => {
        dispatch({ type: SEND_USER_DATA_FAILURE, data: error })
      })
  }
}
