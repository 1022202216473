import React from 'react'

const LogoutModel = ({ logoutModelHandler, logoutHandler }) => {
  return (
    <div className="modal-dd-center dd-modal switch-user">
      <h3 className="font-bold">Sign Out</h3>
      <p>Are you sure you want to Sign Out from the DinDin?</p>
      <div className="pt-3">
        <div className="flex justify-between">
          <button className="dd-button-cta text-btn" onClick={logoutModelHandler}>
            Cancel
          </button>
          <button className="dd-button-cta dd-button-cta--primary-alt" onClick={logoutHandler}>
            Yes
          </button>
        </div>
      </div>
    </div>
  )
}

export default LogoutModel
