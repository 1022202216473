import { getAllReviews, getMealReviews } from 'hooks/services/fetchServices'

export const FETCH_CHEF_REVIEWS_START = 'FETCH_CHEF_REVIEWS_START'
export const FETCH_CHEF_REVIEWS_COMPLETE = 'FETCH_CHEF_REVIEWS_COMPLETE'
export const FETCH_CHEF_REVIEWS_ERROR = 'FETCH_CHEF_REVIEWS_ERROR'
export function fetchReviews(id) {
  return (dispatch) => {
    dispatch({ type: FETCH_CHEF_REVIEWS_START })
    getAllReviews(id)
      .then((response) => {
        dispatch({ type: FETCH_CHEF_REVIEWS_COMPLETE, response })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_CHEF_REVIEWS_ERROR, errors })
      })
  }
}

export const FETCH_MEAL_REVIEWS_START = 'FETCH_MEAL_REVIEWS_START'
export const FETCH_MEAL_REVIEWS_COMPLETE = 'FETCH_MEAL_REVIEWS_COMPLETE'
export const FETCH_MEAL_REVIEWS_ERROR = 'FETCH_MEAL_REVIEWS_ERROR'
export function fetchMealReviews(id) {
  return (dispatch) => {
    dispatch({ type: FETCH_MEAL_REVIEWS_START })
    getMealReviews(id)
      .then((response) => {
        dispatch({ type: FETCH_MEAL_REVIEWS_COMPLETE, response })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_MEAL_REVIEWS_ERROR, errors })
      })
  }
}
