import { CLEAR_FILTERS, SET_FILTER } from 'actions/mealAndChefData'

const initial = {
  zipCode: null,
  date: null,
  cuisine: { id: null, title: null },
  search: null,
  chef: null
}

export default function filters(state = initial, action) {
  switch (action.type) {
    case SET_FILTER:
      return Object.assign({}, state, {
        [action.filterName]: action.filterVal
      })
    case CLEAR_FILTERS:
      return initial
    default:
      return state
  }
}
