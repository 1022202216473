import React from 'react'
import ChefHeader from './ChefHeader'
import SideBar from './SideBar'
import '../../assets/scss/chefHoc.scss'
import MetaHoc from 'hoc/MetaHoc'

const ChefHoc = ({ children }) => {
  return (
    <MetaHoc>
      <article className="main-container bg-light-white cheff-dashboard">
        <SideBar />
        <div className="container fluid content-wrap chef">
          <ChefHeader />
          {children}
        </div>
      </article>
    </MetaHoc>
  )
}

export default React.memo(ChefHoc)
