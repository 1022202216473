import { dropAero } from 'constants/images'
import React from 'react'
import { NavLink } from 'react-router-dom'

const AboutNavLink = ({ setShowDropmenu, showDropmenu, setModal }) => {
  return (
    <li className="dd-nav__tab user-drop" style={{ zIndex: 5 }}>
      <span
        onClick={() => {
          setShowDropmenu(!showDropmenu)
        }}
      >
        About
        <span className={showDropmenu ? 'drop-aero show' : 'drop-aero'}>
          <img src={dropAero} width="24" height="24" alt="search" />
        </span>
      </span>
      <div className={showDropmenu ? 'drop-down show' : 'drop-down'}>
        <div className="hover:text-red-medium eater" onClick={setModal}>
          <NavLink to={'/about-us'} data-testid="aboutus">
            About Us
          </NavLink>
        </div>
        <div className="hover:text-red-medium eater" onClick={setModal}>
          <NavLink to={'/ourteam'} data-testid="ourlink">
            Our Team
          </NavLink>
        </div>
        <div className="hover:text-red-medium eater" onClick={setModal}>
          <NavLink to={'/our-partners'} data-testid="ourlink">
            Our Partners 
          </NavLink>
        </div>
      </div>
    </li>
  )
}

export default AboutNavLink
