/*eslint no-implicit-globals: ["error", {"lexicalBindings": true}]*/
import React, { useEffect, useState } from 'react'
import { buttonToTop } from 'constants/images'
import 'assets/scss/Wrapper.scss'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { fetchMarketPlaceList } from 'actions/geometry'
import { useLocation, useNavigationType } from 'react-router-dom'

const Wrapper = ({ children }) => {
  const count = useSelector((state) => state.cart.count, shallowEqual)
  const mealDetails = useSelector((state) => state.cart.xpMealDetails, shallowEqual)
  const location = useLocation()
  const navType = useNavigationType()
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [permission, setPermission] = useState(true)
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 1000) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }

  const onSiteEventHandle = () => {
    sessionStorage.setItem('onsiteevent', true)
    setPermission(true)
  }

  useEffect(() => {
    window.addEventListener('load', function () {
      var xpPermission = window.xtremepush('push', 'permission')
      if (xpPermission === 'default') {
        if (!sessionStorage.getItem('onsiteevent')) {
          setTimeout(() => {
            setPermission(false)
          }, 60000)
        } else {
          onSiteEventHandle()
        }
      } else {
        onSiteEventHandle()
      }
    })
  }, [location.pathname, permission])
  useEffect(() => {
    if (navType !== 'POP') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }, [location])

  const enableNotification = () => {
    var xpPermission = window.xtremepush('push', 'permission')
    if (xpPermission === 'default' || xpPermission === 'denied') {
      setPermission(true)
      sessionStorage.setItem('onsiteevent', true)
    }
    window.xtremepush('prompt')
    onSiteEventHandle()
  }

  useEffect(() => {
    dispatch(fetchMarketPlaceList())
  }, [])

  useEffect(() => {
    localStorage.setItem('cart_count', count || 0)
    if (count > 0) {
      count && window.xtremepush('tag', 'Added to Cart', count)
      localStorage.setItem('cart_first_item', JSON.stringify(mealDetails))
    } else {
      localStorage.removeItem('cart_first_item')
    }
  }, [count])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  window.addEventListener('scroll', toggleVisible)

  return (
    <div>
      {!permission && (
        <div className="subscribe-XP XP-note z-10">
          <div className="message-wrap">
            <strong>Subscribe to get early discounts and offers.</strong>
            Please enable the notification setting in your system to get the updates.
          </div>
          <div className="action-wrap">
            <button className="enable" onClick={enableNotification}>
              Subscribe
            </button>
            <button className="disable" onClick={onSiteEventHandle}>
              Cancel
            </button>
          </div>
        </div>
      )}

      {children}
      {visible && <img src={buttonToTop} onClick={scrollToTop} alt="scroll up" className="float my-float" />}
    </div>
  )
}
export default Wrapper
