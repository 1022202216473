import { LOGOUT_HANDLER } from 'actions/account'
import { FETCH_CHEF_DETAILS_START, FETCH_CHEF_DETAILS_SUCCESS, FETCH_CHEF_DETAILS_FAILURE } from 'actions/chefDetails'

const initial = {
  fetching: true,
  details: null,
  error: null
}

export default function chefDetails(state = initial, action) {
  switch (action.type) {
    case FETCH_CHEF_DETAILS_START:
      return Object.assign({}, state, {
        fetching: action.fetching
      })
    case FETCH_CHEF_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        details: action.response,
        fetching: action.fetching
      })
    case FETCH_CHEF_DETAILS_FAILURE:
      return Object.assign({}, state, {
        error: action.errors,
        fetching: action.fetching
      })
    case LOGOUT_HANDLER: {
      return initial
    }
    default:
      return state
  }
}
