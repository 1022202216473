import {
  SET_SINGLE_USER,
  GET_SINGLE_USER_WALLET_START,
  GET_SINGLE_USER_WALLET_SUCCESS,
  GET_SINGLE_USER_WALLET_ERROR,
  FETCH_ORG_BENEFIT_START,
  FETCH_ORG_BENEFIT_COMPLETE,
  FETCH_ORG_BENEFIT_ERROR,
  FETCH_ORG_DATA_START,
  FETCH_ORG_DATA_COMPLETE,
  FETCH_ORG_DATA_ERROR,
  ORGANIZATION_USER_START,
  ORGANIZATION_USER_SUCCESS,
  ORGANIZATION_USER_ERROR,
  ORGANIZATION_FILTERED_USER_ERROR,
  ORGANIZATION_FILTERED_USER_START,
  ORGANIZATION_FILTERED_USER_SUCCESS,
  ORGANIZATION_SEARCH_USER_START,
  ORGANIZATION_SEARCH_USER_SUCCESS,
  ORGANIZATION_SEARCH_USER_ERROR,
  ORGANIZATION_TOTAL_START,
  ORGANIZATION_TOTAL_SUCCESS,
  ORGANIZATION_TOTAL_ERROR,
  WALLET_HISTORY_SUCCESS,
  WALLET_HISTORY_ERROR,
  WALLET_HISTORY_START,
  ORGANIZATION_USER_ADD_SUCCESS,
  ORGANIZATION_USER_ADD_ERROR,
  ORGANIZATION_USER_ADD_START,
  ORG_USER_ADD,
  ORGANIZATION_USER_UPDATE_SUCCESS,
  ORGANIZATION_USER_UPDATE_ERROR,
  ORGANIZATION_USER_UPDATE_START,
  ORG_USER_UPDATE,
  GET_ORGANISATION_START,
  GET_ORGANISATION_SUCCESS,
  GET_ORGANISATION_ERROR,
  GET_CORP_PLAN_START,
  GET_CORP_PLAN_SUCCESS,
  GET_CORP_PLAN_ERROR,
  ORG_WALLET_HISTORY_SUCCESS,
  ORG_WALLET_HISTORY_ERROR,
  ORG_WALLET_HISTORY_START,
  ORG_WALLET_BALANCE_SUCCESS,
  ORG_WALLET_BALANCE_ERROR,
  SINGLE_WALLET_BALANCE_SUCCESS,
  SINGLE_WALLET_BALANCE_ERROR,
  RESET_PAGE,
  ORG_DISTIBUTE_MODAL
} from 'actions/organization'
import { LOGOUT_HANDLER, RESET_PASSWORD_ERROR, RESET_PASSWORD_START, RESET_PASSWORD_SUCCESS } from 'actions/account'

const intialState = {
  fetching: true,
  orgBenefits: [],
  fetchorg: true,
  orgData: [],
  orgDataError: null,
  profile: {},
  profileError: null,
  employees: [],
  totalEmployee: null,
  page: 1,
  loadingMoreEmp: false,
  loading: true,
  total: null,
  totalError: null,
  error: null,
  walletHistory: [],
  walletLoading: false,
  addUserSuccess: false,
  addUserLoading: false,
  addUserError: null,
  addOrgState: false,
  updateUserSuccess: false,
  updateUserLoading: false,
  updateUserError: null,
  updateOrgState: false,
  allOrganisation: [],
  orgWalletHistory: [],
  orgWalletLoading: false,
  orgWalletError: null,
  orgWalletHisLoadMore: false,
  address: null,
  addressLoading: false,
  addressError: null,
  singleUserWallet: [],
  singleUserWalletCount: null,
  singleUserWalletLoading: false,
  singleUserWalletError: null,
  singleUserWalletLoadMore: null,
  singleUserDetails: null,
  totalWalletPage: null,
  orgWalletHistoryCount: null,
  orgWalletBalance: null,
  singleWalletBalance: null,
  orgWalletBalanceError: null,
  singleWalletBalanceError: null,
  walletHisLoadMore: false,
  resetLoading: false,
  resetSuccess: null,
  resetError: null,
  orgDistributeState: false
}

export default function organization(state = intialState, action) {
  switch (action.type) {
    case FETCH_ORG_BENEFIT_START:
      return {
        ...state,
        fetching: true
      }
    case FETCH_ORG_BENEFIT_COMPLETE:
      return {
        ...state,
        orgBenefits: action.response,
        fetching: false,
        error: null
      }
    case FETCH_ORG_BENEFIT_ERROR:
      return {
        ...state,
        error: action.errors,
        fetching: false
      }
    case FETCH_ORG_DATA_START:
      return {
        ...state,
        fetchorg: true
      }
    case FETCH_ORG_DATA_COMPLETE:
      return {
        ...state,
        orgData: action.response,
        fetchorg: false,
        orgDataError: null
      }
    case FETCH_ORG_DATA_ERROR:
      return {
        ...state,
        orgDataError: action.errors,
        fetchorg: false
      }
    case ORGANIZATION_USER_START:
      return {
        ...state,
        loading: action.page === 1 ? true : false,
        loadingMoreEmp: action.page > 1 ? true : false,
        page: action.page === 1 ? 1 : state.page
      }
    case ORGANIZATION_USER_SUCCESS:
      return {
        ...state,
        employees: action.page === 1 ? action.data.results : [...state.employees, ...action.data.results],
        totalEmployee: action.data.count,
        page: state.page + 1,
        loading: false,
        loadingMoreEmp: false
      }
    case RESET_PAGE: {
      return {
        ...state,
        page: 1
      }
    }

    case ORGANIZATION_USER_ERROR:
      return {
        ...state,
        error: action.data,
        loading: false,
        loadingMoreEmp: false
      }
    case ORGANIZATION_FILTERED_USER_START:
      return {
        ...state,
        loading: action.page === 1 ? true : false,
        loadingMoreEmp: action.page > 1 ? true : false
      }
    case ORGANIZATION_FILTERED_USER_SUCCESS:
      return {
        ...state,
        employees: action.page === 1 ? action.data.results : [...state.employees, ...action.data.results],
        totalEmployee: action.data.count,
        page: state.page + 1,
        loading: false,
        loadingMoreEmp: false
      }
    case ORGANIZATION_FILTERED_USER_ERROR:
      return {
        ...state,
        error: action.data,
        loading: false,
        loadingMoreEmp: false
      }
    case ORGANIZATION_SEARCH_USER_START:
      return {
        ...state,
        loading: action.page === 1 ? true : false,
        loadingMoreEmp: action.page > 1 ? true : false
      }
    case ORGANIZATION_SEARCH_USER_SUCCESS:
      return {
        ...state,
        employees: action.page === 1 ? action.data.results : [...state.employees, ...action.data.results],
        totalEmployee: action.data.count,
        page: state.page + 1,
        loading: false,
        loadingMoreEmp: false
      }
    case ORGANIZATION_SEARCH_USER_ERROR:
      return {
        ...state,
        error: action.data,
        loading: false,
        loadingMoreEmp: false
      }
    case ORGANIZATION_TOTAL_START:
      return {
        ...state,
        loading: true
      }
    case ORGANIZATION_TOTAL_SUCCESS:
      return {
        ...state,
        total: action.data,
        loading: false
      }
    case ORGANIZATION_TOTAL_ERROR:
      return {
        ...state,
        totalError: action.data,
        loading: false
      }
    case WALLET_HISTORY_START:
      return {
        ...state,
        walletLoading: state.walletHistory.length ? false : true,
        walletHisLoadMore: state.walletHistory.length ? true : false
      }
    case WALLET_HISTORY_SUCCESS:
      return {
        ...state,
        walletHistory: action.currentPage === 1 ? action.data : [...state.walletHistory, ...action.data],
        walletLoading: false,
        totalWalletPage: action.page,
        walletHisLoadMore: false
      }
    case WALLET_HISTORY_ERROR:
      return {
        ...state,
        walletLoading: false,
        walletError: action.data,
        walletHisLoadMore: false
      }
    case ORGANIZATION_USER_ADD_START:
      return {
        ...state,
        addUserLoading: true,
        addUserSuccess: false,
        addUserError: null
      }
    case ORGANIZATION_USER_ADD_SUCCESS:
      return {
        ...state,
        addUserSuccess: action.data,
        addUserLoading: false,
        addUserError: null
      }
    case ORGANIZATION_USER_ADD_ERROR:
      return {
        ...state,
        addUserError: action.data,
        addUserLoading: false
      }
    case ORG_USER_ADD:
      return {
        ...state,
        addOrgState: action.data,
        addUserError: null
      }
    case ORG_DISTIBUTE_MODAL:
      return {
        ...state,
        orgDistributeState: action.data
      }

    case ORGANIZATION_USER_UPDATE_START:
      return {
        ...state,
        updateUserLoading: true,
        updateUserSuccess: false,
        updateUserError: null
      }
    case ORGANIZATION_USER_UPDATE_SUCCESS:
      return {
        ...state,
        updateUserSuccess: action.data,
        updateUserLoading: false,
        updateUserError: null
      }
    case ORGANIZATION_USER_UPDATE_ERROR:
      return {
        ...state,
        updateUserError: action.data,
        updateUserLoading: false
      }
    case ORG_USER_UPDATE:
      return {
        ...state,
        updateOrgState: action.data
      }
    case GET_ORGANISATION_START:
      return {
        ...state,
        loading: true
      }
    case GET_ORGANISATION_SUCCESS:
      return {
        ...state,
        allOrganisation: action.data,
        loading: false
      }
    case GET_ORGANISATION_ERROR:
      return {
        error: action.data,
        loading: false
      }
    case GET_CORP_PLAN_START:
      return {
        ...state,
        loading: true
      }
    case GET_CORP_PLAN_SUCCESS:
      return {
        ...state,
        allCorpplan: action.data,
        loading: false
      }
    case GET_CORP_PLAN_ERROR:
      return {
        error: action.data,
        loading: false
      }
    case ORG_WALLET_HISTORY_START:
      return {
        ...state,
        orgWalletLoading: state.orgWalletHistory.length ? false : true,
        orgWalletHisLoadMore: state.orgWalletHistory.length ? true : false,
        orgWalletError: null
      }
    case ORG_WALLET_HISTORY_SUCCESS:
      return {
        ...state,
        orgWalletHistory: [...state.orgWalletHistory, ...action.data],
        orgWalletHistoryCount: action.page,
        orgWalletHisLoadMore: false,
        orgWalletLoading: false,
        orgWalletError: null
      }
    case ORG_WALLET_HISTORY_ERROR:
      return {
        ...state,
        orgWalletLoading: false,
        orgWalletHisLoadMore: false,
        orgWalletError: action.data
      }
    case GET_SINGLE_USER_WALLET_START:
      return {
        ...state,
        singleUserWalletLoading: Number(action.currentPage) === 1 ? true : false,
        singleUserWalletLoadMore: state.singleUserWallet.length > 0 ? true : false,
        singleUserWalletError: null
      }
    case GET_SINGLE_USER_WALLET_SUCCESS:
      return {
        ...state,
        singleUserWallet: Number(action.currentPage) === 1 ? action.data.results : [...state.singleUserWallet, ...action.data.results],
        singleUserWalletCount: action.page,
        singleUserWalletLoading: false,
        singleUserWalletLoadMore: false,
        singleUserWalletError: null
      }
    case GET_SINGLE_USER_WALLET_ERROR:
      return {
        ...state,
        singleUserWalletLoading: false,
        singleUserWalletError: action.data,
        singleUserWalletLoadMore: false
      }
    case SET_SINGLE_USER:
      return {
        ...state,
        singleUser: action.data
      }
    case SINGLE_WALLET_BALANCE_SUCCESS:
      return {
        ...state,
        singleWalletBalance: action.data,
        singleWalletBalanceError: null
      }
    case SINGLE_WALLET_BALANCE_ERROR:
      return {
        ...state,
        singleWalletBalanceError: action.data
      }
    case ORG_WALLET_BALANCE_SUCCESS:
      return {
        ...state,
        orgWalletBalance: action.data,
        orgWalletBalanceError: null
      }
    case ORG_WALLET_BALANCE_ERROR:
      return {
        ...state,
        orgWalletBalanceError: action.data
      }
    case RESET_PASSWORD_START:
      return {
        ...state,
        resetLoading: true
      }
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetLoading: false,
        resetSuccess: action.data
      }
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetLoading: false,
        resetError: action.data
      }
    case LOGOUT_HANDLER: {
      return intialState
    }
    default:
      return state
  }
}
