const defaultTitle = 'Eat Din Din'
export const homepageMeta = {
  pageName: 'homepage',
  url: 'https://www.eatdindin.com/',
  title: `Chef Prepared Meals Delivered to Your Door | ${defaultTitle}`,
  description:
    'Save time by having delicious home-cooked meals delivered straight to your door! With Din Din you can easily order meals online to suit your taste!',
  ogDescription: `I highly recommend you to check out this Chef Sharing app called 'Din Din' for meals and items prepared by local chefs. Use promo code Friend to get 10% off your first order. 100% of the meal price goes to local chefs!`
}
export const aboutUsMeta = {
  pageName: 'about-us',
  url: 'https://www.eatdindin.com/about-us/',
  title: `How Din Din is Helping Businesses Attract & Retain Employees | ${defaultTitle}`,
  description:
    'Discover Din Din - an online home-cooked meal delivery platform connecting you to local cooks ready to serve up inspiring dishes right to your door!'
}

export const ourTeamMeta = {
  pageName: 'ourteam',
  url: '',
  title: `Attract & Retain Employees with Home Delivered Meals | ${defaultTitle}`,
  description: `Explore Din Din's employee meal benefit solution! Retain, attract & engage employees while supporting the local community - provide a homemade meal!`
}

export const createKitchenMeta = {
  pageName: 'create-kitchen',
  url: 'https://www.eatdindin.com/create-kitchen/',
  title: `Become One of Our Local Chefs | ${defaultTitle}`,
  description:
    'Have a passion for food & an appreciation for good cooking? Join the Din Din family and become a favorite local chef by following a few simple steps!'
}

export const loginMeta = {
  pageName: 'login',
  url: 'https://www.eatdindin.com/login/',
  title: `Sign In to Order Your Favorite Dishes | ${defaultTitle}`,
  description:
    'Sign In to try different recipes & discover new tastes every day! Din Din will deliver to your door and provide you with healthy meals, saving you time!'
}

export const contactUsMeta = {
  pageName: 'contact us',
  url: 'https://www.eatdindin.com/contact-us/',
  title: `Get In Touch | ${defaultTitle}`,
  description:
    'What sets us apart from the rest? We offer you unique home-cooked meals made by local artisans! Contact us for your queries & impressions!'
}

export const howItWorksMeta = {
  pageName: 'how it works',
  url: 'https://www.eatdindin.com/how-it-works/',
  title: `4 Easy Steps to Getting Cooked Meals Delivered to Your Door | ${defaultTitle}`,
  description:
    'Din Din is a new way to build a community of different local talents & home cooks who create delicious meals delivered to your door! Learn how it works!'
}

export const faqMeta = {
  pageName: 'faq',
  url: 'https://www.eatdindin.com/faq/',
  title: `Frequently Asked Questions | ${defaultTitle}`,
  description:
    'How does it work? What are the benefits? Explore FAQ about Din Din & learn how we bring a delicious home-cooked meal to you, straight from the source.'
}

export const mealMeta = {
  pageName: 'meals',
  url: 'https://www.eatdindin.com/meals/',
  title: `Find Chefs and Specialty Meals Near You | ${defaultTitle}`,
  description:
    'At Din Din you can search chefs by specialization and location! Whether you crave pizza, curry, or ramen, just search the map, pick a location & order!'
}

export const termsMeta = {
  pageName: 'terms and conditions',
  url: 'https://www.eatdindin.com/terms-and-conditions/',
  title: `Terms & Conditions | ${defaultTitle}`,
  description:
    'Please read our Terms and Conditions that govern your use of the Din Din website. By using this website, you accept and agree to these Terms of Use.'
}

export const corporateMeta = {
  pageName: 'corporate',
  url: 'https://www.eatdindin.com/corporate',
  title: `Attract & Retain Employees with Home Delivered Meals | ${defaultTitle}`,
  description: `Explore Din Din's employee meal benefit solution! Retain, attract & engage employees while supporting the local community - provide a homemade meal!`
}

export const cuisineSolutionsMeta = {
  pageName: 'cuisine solutions',
  url: 'https://www.eatdindin.com/corporate',
  title: `Cuisine Solutions | ${defaultTitle}`,
  description: 'Cuisine Solutions'
}

export const foodSafetyMeta = {
  pageName: 'food safety',
  url: 'https://www.eatdindin.com/food-safety/',
  title: `Food Safety Policies & Practices | ${defaultTitle}`,
  description: `At Din Din, we understand your focus on food safety - we have it too! Learn how we work to ensure that you receive food prepared with your safety in mind.`
}

export const privacyMeta = {
  pageName: 'privacy and policy',
  url: 'https://www.eatdindin.com/privacy-policy/',
  title: `Privacy Policy | ${defaultTitle}`,
  description: `All the info you need about what data we're collecting, why, & how that helps your experience with us. We encourage you to read Din Din's privacy policy!`
}

export const chefsMeta = {
  pageName: 'chefs',
  url: 'https://www.eatdindin.com/chefs/',
  title: `Find Local Chefs Near You | ${defaultTitle}`,
  description: `With Din Din, search chefs by location or specialization & order delivery from your favorite local kitchen. Order a home-cooked meal via our location finder!`
}

export const corpotateSignUpMeta = {
  pageName: 'corporate sign up',
  url: 'https://www.eatdindin.com/corporate/signup/',
  title: `Sign Up for Our Corporate Meals Benefit Program | ${defaultTitle}`,
  description: `Sign up for Din Din's employee meal benefit solution! Retain, attract & support your employees while supporting the local community!`
}

export const signUpMeta = {
  pageName: 'sign up',
  url: 'https://www.eatdindin.com/signup/',
  title: `Sign Up as a New Customer, Chef or Organization Today | ${defaultTitle}`,
  description: `Sign up to discover top local foods at great prices delivered to your door today! Din Din is a unique prepared meal delivery service that helps a community!`
}

export const setLocationStoreAction = () => {
  if (localStorage.getItem('location_allowed')) {
    return
  }
  return localStorage.setItem('location_state', true)
}

export const getLocationStoreAction = () => {
  return localStorage.getItem('location_state')
}

export const removeLoactionStoreAction = () => {
  return localStorage.removeItem('location_state')
}

export const landingPageMeta = {
  pageName: 'homepage',
  url: 'https://www.eatdindin.com/landing-page/',
  title: `Corporate Program  | ${defaultTitle}`,
  description:
    'Save time with chef-prepared heat & serve meals delivered to your door! Order tasty meals online, attract & retain employees with our corporate program.',
  ogDescription: `Save time with chef-prepared heat & serve meals delivered to your door! Order tasty meals online, attract & retain employees with our corporate program.`
}
