import { useQuery } from 'react-query'
import { HTTP_CLIENT } from 'clients/httpClient'
import { toQueryString } from 'utils/http'
import { getAuthHeaders } from 'constants/getHeader'

async function protectedFetch(endpoint, params = {}) {
  const { token } = params
  delete params['token']
  const queryParams = toQueryString(params)

  const response = await HTTP_CLIENT.get(`${endpoint}?${queryParams}`, {
    headers: getAuthHeaders(token)
  })

  return response
}

function useProtectedFetch(endpoint, accessToken = null, params = {}, options = {}) {
  const queryKey = [endpoint, accessToken, params]

  return useQuery(
    queryKey,
    async () => {
      const response = await protectedFetch(endpoint, accessToken)

      return response
    },
    options
  )
}

async function publicFetch(endpoint, geometry, filters, sort) {
  let query = {}

  const isNullish =
    filters &&
    Object.keys(filters).every((key) => {
      if (filters[key] === null || undefined) {
        return true
      }
      for (let [key, value] of Object.entries(filters)) {
        if (value === null || undefined) {
          delete filters[key]
        }
      }
      return false
    })
  if (!isNullish) {
    filters && filters.kitchenName && encodeURIComponent(filters.kitchenName)
    query = Object.assign({}, query, filters)
  }
  if (sort && sort.key) {
    query = Object.assign({}, query, { order: sort.direction })
  }

  if (geometry) {
    query = Object.assign({}, query, geometry)
  }

  const queryParams = toQueryString(query)

  const response = await HTTP_CLIENT.get(`${endpoint}?${queryParams}`)
  return response
}

export { protectedFetch, useProtectedFetch, publicFetch }
