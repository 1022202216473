import { createStore, applyMiddleware } from 'redux'
import middleware from './middleware'
import rootReducer from '../reducers'

const initialState = {
  cuisines: [],
  reviews: [],
  paymentMethods: []
}

const store = createStore(rootReducer, initialState, applyMiddleware(...middleware))

export default store
