import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import Loader from 'components/Loader.jsx'
import 'css/cardstyles.css'
import { addCreditCard } from 'hooks/services/postService'
import { toast } from 'react-toastify'

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#bebf55',
      color: '#333',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#fce883' },
      '::placeholder': { color: '#ddd' }
    },
    invalid: {
      iconColor: '#de3530',
      color: '#de3530'
    }
  }
}

const CreditCardAdd = ({ clientSecret, onSuccess }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)

  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements || isSubmitting) {
      return
    }
    if (!clientSecret) {
      toast.error('Similar user exists in live mode, but a test mode key was used to make this request.')
      return
    }
    setIsSubmitting(true)

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement)
      }
    })

    if (result.error) {
      setError(result.error.message)
    } else {
      const newCard = await addCreditCard(result.setupIntent.payment_method)
      onSuccess(newCard)
    }

    setIsSubmitting(false)
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <fieldset className="FormGroup">
          <div className="FormRow">
            <CardElement options={CARD_OPTIONS} />
          </div>
        </fieldset>

        {error ? <div className="pt-4 text-red-light">{error}</div> : <span />}

        {isSubmitting ? (
          <Loader />
        ) : (
          <div className="pt-3">
            <div className="text-right">
              <button disabled={!stripe} className="dd-button-cta dd-button-cta--primary-alt">
                Save Card
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  )
}

CreditCardAdd.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  defaultFormOpen: PropTypes.bool
}

export default CreditCardAdd
