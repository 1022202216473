import {
  fetchChefCuisines,
  fetchKitchenAddress,
  fetchSchedulemeal,
  getChefCuisines,
  getCheforderDetail,
  getChefAllMeals,
  getChefDashboard,
  getChefOrders,
  getChefPastOrders,
  getChefCurrentOrders,
  getTotalRevenue,
  getChefUpcomingMeals,
  getChefUpcomingOrders,
  getChefMealReview,
  getBankConnect,
  getChefMealDetails,
  getChefBios
} from 'hooks/services/fetchServices'
import { chefCuisineUpdate } from 'hooks/services/postService'
import { deleteChefMeal, deleteSchedule } from 'hooks/services/deleteService'
import { editMeal, updateChefKitchen, updateChef, updateSchedule, updateChefBios } from 'hooks/services/putService'
import { toast } from 'react-toastify'
import { fetchProfileData } from './account'

export const FETCH_CHEF_CUISNES_SUCCESS = 'FETCH_CHEF_CUISNES_SUCCESS'
export const FETCH_CHEF_CUISNES_ERROR = 'FETCH_CHEF_CUISNES_ERROR'
export function getChefCuisnes() {
  return (dispatch) => {
    getChefCuisines().then((res) => {
      if (res.data === false) {
        dispatch({ type: FETCH_CHEF_CUISNES_ERROR, data: res })
      }
      dispatch({ type: FETCH_CHEF_CUISNES_SUCCESS, data: res })
    })
  }
}

export const FETCH_CHEF_KITCHEN_START = 'FETCH_CHEF_KITCHEN_START'
export const FETCH_CHEF_KITCHEN_FAILURE = 'FETCH_CHEF_KITCHEN_FAILURE'
export const FETCH_CHEF_KITCHEN_SUCCESS = 'FETCH_CHEF_KITCHEN_SUCCESS'
export function fetchChefKitchenAddress() {
  return (dispatch) => {
    dispatch({ type: FETCH_CHEF_KITCHEN_START, fetching: true })
    fetchKitchenAddress()
      .then((response) => {
        dispatch({ type: FETCH_CHEF_KITCHEN_SUCCESS, response, fetching: false })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_CHEF_KITCHEN_FAILURE, errors, fetching: false })
      })
  }
}

export const FETCH_CHEF_CUISINES_START = 'FETCH_CHEF_CUISINES_START'
export const FETCH_CHEF_CUISINES_FAILURE = 'FETCH_CHEF_CUISINES_FAILURE'
export const FETCH_CHEF_CUISINES_SUCCESS = 'FETCH_CHEF_CUISINES_SUCCESS'
export function fetchKitchenCuisines() {
  return (dispatch) => {
    dispatch({ type: FETCH_CHEF_CUISINES_START, fetching: true })
    fetchChefCuisines()
      .then((response) => {
        dispatch({ type: FETCH_CHEF_CUISINES_SUCCESS, response, fetching: false })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_CHEF_CUISINES_FAILURE, errors, fetching: false })
      })
  }
}

export const UPDATE_CHEF_CUISINES_START = 'UPDATE_CHEF_CUISINES_START'
export const UPDATE_CHEF_CUISINES_FAILURE = 'UPDATE_CHEF_CUISINES_FAILURE'
export const UPDATE_CHEF_CUISINES_SUCCESS = 'UPDATE_CHEF_CUISINES_SUCCESS'
export function updateChefCuisine(data) {
  return (dispatch) => {
    dispatch({ type: UPDATE_CHEF_CUISINES_START, fetching: true })
    try {
      const response = chefCuisineUpdate(data)
      dispatch({ type: UPDATE_CHEF_CUISINES_SUCCESS, response, fetching: false })
    } catch (error) {
      const errors = error.response.data
      dispatch({ type: UPDATE_CHEF_CUISINES_FAILURE, errors, fetching: false })
    }
  }
}

export const UPDATE_CHEF_KITCHEN_START = 'UPDATE_CHEF_KITCHEN_START'
export const UPDATE_CHEF_KITCHEN_FAILURE = 'UPDATE_CHEF_KITCHEN_FAILURE'
export const UPDATE_CHEF_KITCHEN_SUCCESS = 'UPDATE_CHEF_KITCHEN_SUCCESS'
export function updateChefKitchenAddress(id, data) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_CHEF_KITCHEN_START, fetching: true })
    try {
      const response = await updateChefKitchen(id, data)
      dispatch({ type: UPDATE_CHEF_KITCHEN_SUCCESS, response, fetching: false })
      toast.success('Updated successfully')
    } catch (error) {
      const errors = error.response.data
      dispatch({ type: UPDATE_CHEF_KITCHEN_FAILURE, errors, fetching: false })
    }
  }
}

export const UPDATE_CHEF_MEAL_START = 'UPDATE_CHEF_MEAL_START'
export const UPDATE_CHEF_MEAL_SUCCESS = 'UPDATE_CHEF_MEAL_SUCCESS'
export const UPDATE_CHEF_MEAL_FAILURE = 'UPDATE_CHEF_MEAL_FAILURE'
export function updateChefMeal(id, data) {
  return (dispatch) => {
    dispatch({ type: UPDATE_CHEF_MEAL_START, fetching: true })
    try {
      const response = editMeal(id, data)
      dispatch({ type: UPDATE_CHEF_MEAL_SUCCESS, response, fetching: false })
      dispatch(fetchChefAllMeals())
    } catch (error) {
      const errors = error.response.data
      dispatch({ type: UPDATE_CHEF_MEAL_FAILURE, errors, fetching: false })
    }
  }
}

export const FETCH_CHEF_SCHEDULMEAL_START = 'FETCH_CHEF_SCHEDULMEAL_START'
export const FETCH_CHEF_SCHEDULMEAL_SUCCESS = 'FETCH_CHEF_SCHEDULMEAL_SUCCESS'
export const FETCH_CHEF_SCHEDULMEAL_FAILURE = 'FETCH_CHEF_SCHEDULMEAL_FAILURE'
export function fetchChefSchedulemeal(id) {
  return (dispatch) => {
    dispatch({ type: FETCH_CHEF_SCHEDULMEAL_START, fetching: true })
    if (id === undefined) {
      dispatch({ type: FETCH_CHEF_SCHEDULMEAL_FAILURE, fetching: false })
      return
    }
    fetchSchedulemeal(id)
      .then((response) => {
        dispatch({ type: FETCH_CHEF_SCHEDULMEAL_SUCCESS, response, fetching: false })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_CHEF_SCHEDULMEAL_FAILURE, errors, fetching: false })
      })
  }
}
export const FETCH_CHEF_ORDER_DETAIL_START = 'FETCH_CHEF_ORDER_DETAIL_START'
export const FETCH_CHEF_ORDER_DETAIL_SUCCESS = 'FETCH_CHEF_ORDER_DETAIL_SUCCESS'
export const FETCH_CHEF_ORDER_DETAIL_ERROR = 'FETCH_CHEF_ORDER_DETAIL_ERROR'
export function fetchCheforderDetail(id) {
  return (dispatch) => {
    dispatch({ type: FETCH_CHEF_ORDER_DETAIL_START })
    getCheforderDetail(id)
      .then((response) => {
        dispatch({ type: FETCH_CHEF_ORDER_DETAIL_SUCCESS, response })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_CHEF_ORDER_DETAIL_ERROR, errors })
      })
  }
}

export const FETCH_CHEF_MEALS_START = 'FETCH_CHEF_MEALS_START'
export const FETCH_CHEF_MEALS_SUCCESS = 'FETCH_CHEF_MEALS_SUCCESS'
export const FETCH_CHEF_MEALS_ERROR = 'FETCH_CHEF_MEALS_ERROR'
export function fetchChefAllMeals(page = 1, limit = 10) {
  return (dispatch) => {
    dispatch({ type: FETCH_CHEF_MEALS_START, page })
    getChefAllMeals(page, limit)
      .then((response) => {
        dispatch({ type: FETCH_CHEF_MEALS_SUCCESS, response, page })
      })
      .catch((error) => {
        const errors = error.response
        dispatch({ type: FETCH_CHEF_MEALS_ERROR, errors })
      })
  }
}

export const FETCH_CHEF_DASHBOARD_START = 'FETCH_CHEF_DASHBOARD_START'
export const FETCH_CHEF_DASHBOARD_SUCCESS = 'FETCH_CHEF_DASHBOARD_SUCCESS'
export const FETCH_CHEF_DASHBOARD_ERROR = 'FETCH_CHEF_DASHBOARD_ERROR'
export function fetchChefDashboard() {
  return (dispatch) => {
    dispatch({ type: FETCH_CHEF_DASHBOARD_START })
    getChefDashboard()
      .then((response) => {
        dispatch({ type: FETCH_CHEF_DASHBOARD_SUCCESS, response })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_CHEF_DASHBOARD_ERROR, errors })
      })
  }
}

export const FETCH_CHEF_ORDERS_START = 'FETCH_CHEF_ORDERS_START'
export const FETCH_CHEF_ORDERS_SUCCESS = 'FETCH_CHEF_ORDERS_SUCCESS'
export const FETCH_CHEF_ORDERS_ERROR = 'FETCH_CHEF_ORDERS_ERROR'
export function fetchChefOrders(page = 1, limit = 10) {
  return (dispatch) => {
    dispatch({ type: FETCH_CHEF_ORDERS_START, page })
    getChefOrders(page, limit)
      .then((response) => {
        dispatch({ type: FETCH_CHEF_ORDERS_SUCCESS, response, page })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_CHEF_ORDERS_ERROR, errors })
      })
  }
}

export const FETCH_CHEF_PAST_ORDER_START = 'FETCH_CHEF_PAST_ORDER_START'
export const FETCH_CHEF_PAST_ORDER_SUCCESS = 'FETCH_CHEF_PAST_ORDER_SUCCESS'
export const FETCH_CHEF_PAST_ORDER_ERROR = 'FETCH_CHEF_PAST_ORDER_ERROR'
export function fetchChefPastOrder(page, limit) {
  return (dispatch) => {
    dispatch({ type: FETCH_CHEF_PAST_ORDER_START })
    getChefPastOrders(page, limit)
      .then((response) => {
        dispatch({ type: FETCH_CHEF_PAST_ORDER_SUCCESS, response, page })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_CHEF_PAST_ORDER_ERROR, errors })
      })
  }
}

export const FETCH_CHEF_PAST_MEALS_START = 'FETCH_CHEF_PAST_MEALS_START'
export const FETCH_CHEF_PAST_MEALS_SUCCESS = 'FETCH_CHEF_PAST_MEALS_SUCCESS'
export const FETCH_CHEF_PAST_MEALS_ERROR = 'FETCH_CHEF_PAST_MEALS_ERROR'
export function fetchChefCurrentOrders(page, limit) {
  return (dispatch) => {
    dispatch({ type: FETCH_CHEF_PAST_MEALS_START })
    getChefCurrentOrders(page, limit)
      .then((response) => {
        dispatch({ type: FETCH_CHEF_PAST_MEALS_SUCCESS, response, page: page })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_CHEF_PAST_MEALS_ERROR, errors })
      })
  }
}

export const FETCH_CHEF_UPCOMING_MEALS_START = 'FETCH_CHEF_UPCOMING_MEALS_START'
export const FETCH_CHEF_UPCOMING_MEALS_SUCCESS = 'FETCH_CHEF_UPCOMING_MEALS_SUCCESS'
export const FETCH_CHEF_UPCOMING_MEALS_ERROR = 'FETCH_CHEF_UPCOMING_MEALS_ERROR'
export function fetchChefUpcomingMeals(page = 1, limit = 10) {
  return (dispatch) => {
    dispatch({ type: FETCH_CHEF_UPCOMING_MEALS_START, page })
    getChefUpcomingMeals(page, limit)
      .then((response) => {
        dispatch({ type: FETCH_CHEF_UPCOMING_MEALS_SUCCESS, response, page })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_CHEF_UPCOMING_MEALS_ERROR, errors })
      })
  }
}

export const FETCH_CHEF_UPCOMING_ORDERS_START = 'FETCH_CHEF_UPCOMING_ORDERS_START'
export const FETCH_CHEF_UPCOMING_ORDERS_SUCCESS = 'FETCH_CHEF_UPCOMING_ORDERS_SUCCESS'
export const FETCH_CHEF_UPCOMING_ORDERS_ERROR = 'FETCH_CHEF_UPCOMING_ORDERS_ERROR'
export function fetchChefUpcomingOrders(page = 1, limit = 10) {
  return (dispatch) => {
    dispatch({ type: FETCH_CHEF_UPCOMING_ORDERS_START, page })
    getChefUpcomingOrders(page, limit)
      .then((response) => {
        dispatch({ type: FETCH_CHEF_UPCOMING_ORDERS_SUCCESS, response, page })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_CHEF_UPCOMING_ORDERS_ERROR, errors })
      })
  }
}

export const FETCH_TOTAL_REVENUE_START = 'FETCH_TOTAL_REVENUE_START'
export const FETCH_TOTAL_REVENUE_SUCCESS = 'FETCH_TOTAL_REVENUE_SUCCESS'
export const FETCH_TOTAL_REVENUE_ERROR = 'FETCH_TOTAL_REVENUE_ERROR'
export function fetchTotalRevenue(data) {
  return (dispatch) => {
    dispatch({ type: FETCH_TOTAL_REVENUE_START })

    getTotalRevenue(data)
      .then((response) => {
        dispatch({ type: FETCH_TOTAL_REVENUE_SUCCESS, response })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_TOTAL_REVENUE_ERROR, errors })
      })
  }
}
export const FETCH_CHEF_MEALS_REVIEW_START = 'FETCH_CHEF_MEALS_REVIEW_START'
export const FETCH_CHEF_MEALS_REVIEW_SUCCESS = 'FETCH_CHEF_MEALS_REVIEW_SUCCESS'
export const FETCH_CHEF_MEALS_REVIEW_ERROR = 'FETCH_CHEF_MEALS_REVIEW_ERROR'
export function fetchChefMealsReview(mealId) {
  return (dispatch) => {
    dispatch({ type: FETCH_CHEF_MEALS_REVIEW_START })
    getChefMealReview(mealId)
      .then((response) => {
        dispatch({ type: FETCH_CHEF_MEALS_REVIEW_SUCCESS, response })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_CHEF_MEALS_REVIEW_ERROR, errors })
      })
  }
}

export const FETCH_CHEF_BANK_CONNECT_START = 'FETCH_CHEF_BANK_CONNECT_START'
export const FETCH_CHEF_BANK_CONNECT_SUCCESS = 'FETCH_CHEF_BANK_CONNECT_SUCCESS'
export const FETCH_CHEF_BANK_CONNECT_ERROR = 'FETCH_CHEF_BANK_CONNECT_ERROR'
export function fetchChefBankConnect(data) {
  return (dispatch) => {
    dispatch({ type: FETCH_CHEF_BANK_CONNECT_START })
    getBankConnect(data)
      .then((response) => {
        dispatch({ type: FETCH_CHEF_BANK_CONNECT_SUCCESS, response })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_CHEF_BANK_CONNECT_ERROR, errors })
      })
  }
}

export const FETCH_CHEF_MEAL_DETAILS_START = 'FETCH_CHEF_MEAL_DETAILS_START'
export const FETCH_CHEF_MEAL_DETAILS_SUCCESS = 'FETCH_CHEF_MEAL_DETAILS_SUCCESS'
export const FETCH_CHEF_MEAL_DETAILS_ERROR = 'FETCH_CHEF_MEAL_DETAILS_ERROR'
export function fetchChefMealDetails(id) {
  return (dispatch) => {
    dispatch({ type: FETCH_CHEF_MEAL_DETAILS_START })
    getChefMealDetails(id)
      .then((response) => {
        dispatch({ type: FETCH_CHEF_MEAL_DETAILS_SUCCESS, response })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_CHEF_MEAL_DETAILS_ERROR, errors })
      })
  }
}

export const DELETE_MEAL_SCHEDULE_START = 'DELETE_MEAL_SCHEDULE_START'
export const DELETE_MEAL_SCHEDULE_SUCCESS = 'DELETE_MEAL_SCHEDULE_SUCCESS'
export const DELETE_MEAL_SCHEDULE_ERROR = 'DELETE_MEAL_SCHEDULE_ERROR'
export function deleteMealSchedule(id) {
  return (dispatch) => {
    dispatch({ type: DELETE_MEAL_SCHEDULE_START })
    deleteSchedule(id)
      .then((res) => {
        dispatch({ type: DELETE_MEAL_SCHEDULE_SUCCESS, data: res })
        toast.success('Scheduled meal deleted Successfully.')
        dispatch(fetchChefUpcomingMeals())
      })
      .catch((err) => {
        const errors = err.response.data
        toast.error('server error.')
        dispatch({ type: DELETE_MEAL_SCHEDULE_ERROR, data: errors })
      })
  }
}

export const UPDATE_CHEF_PROFILE_START = 'UPDATE_CHEF_PROFILE_START'
export const UPDATE_CHEF_PROFILE_SUCCESS = 'UPDATE_CHEF_PROFILE_SUCCESS'
export const UPDATE_CHEF_PROFILE_ERROR = 'UPDATE_CHEF_PROFILE_ERROR'
export function updateChefProfile(data) {
  return (dispatch) => {
    dispatch({ type: UPDATE_CHEF_PROFILE_START })
    updateChef(data)
      .then((res) => {
        dispatch({ type: UPDATE_CHEF_PROFILE_SUCCESS, data: res })
        dispatch(fetchProfileData())
        toast.success('profile updated successfully')
      })
      .catch((err) => {
        const errors = err.response.data
        dispatch({ type: UPDATE_CHEF_PROFILE_ERROR, data: errors })
        toast.error(errors)
      })
  }
}

export const GET_CHEF_BIO_START = 'GET_CHEF_BIO_START'
export const GET_CHEF_BIO_SUCCESS = 'GET_CHEF_BIO_SUCCESS'
export const GET_CHEF_BIO_ERROR = 'GET_CHEF_BIO_ERROR'
export function getChefBio() {
  return (dispatch) => {
    dispatch({ type: GET_CHEF_BIO_START })
    getChefBios()
      .then((res) => {
        dispatch({ type: GET_CHEF_BIO_SUCCESS, data: res })
      })
      .catch((err) => {
        const errors = err.response.data
        dispatch({ type: GET_CHEF_BIO_ERROR, data: errors })
      })
  }
}

export const UPDATE_CHEF_BIO_START = 'UPDATE_CHEF_BIO_START'
export const UPDATE_CHEF_BIO_SUCCESS = 'UPDATE_CHEF_BIO_SUCCESS'
export const UPDATE_CHEF_BIO_ERROR = 'UPDATE_CHEF_BIO_ERROR'
export function updateChefBio(data) {
  return (dispatch) => {
    dispatch({ type: UPDATE_CHEF_BIO_START })
    updateChefBios(data)
      .then((res) => {
        dispatch({ type: UPDATE_CHEF_BIO_SUCCESS, data: res })
        dispatch(getChefBio())
      })
      .catch((err) => {
        const errors = err.response.data
        dispatch({ type: UPDATE_CHEF_BIO_ERROR, data: errors })
      })
  }
}

export const DELETE_CHEF_MEAL_START = 'DELETE_CHEF_MEAL_START'
export const DELETE_CHEF_MEAL_FAILURE = 'DELETE_CHEF_MEAL_FAILURE'
export const DELETE_CHEF_MEAL_SUCCESS = 'DELETE_CHEF_MEAL_SUCCESS'
export function deleteChefMenuMeal(id) {
  return (dispatch) => {
    dispatch({ type: DELETE_CHEF_MEAL_START, fetching: true })
    deleteChefMeal(id)
      .then((response) => {
        dispatch({ type: DELETE_CHEF_MEAL_SUCCESS, response, fetching: false })
        dispatch(fetchChefAllMeals())
        toast.success('Meal deleted successfully')
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: DELETE_CHEF_MEAL_FAILURE, errors, fetching: false })
        toast.error(errors && errors.details ? errors.details : 'Meal not deleted')
      })
  }
}
export const UPDATE_MEAL_SCHEDULE_START = 'UPDATE_MEAL_SCHEDULE_START'
export const UPDATE_MEAL_SCHEDULE_SUCCESS = 'UPDATE_MEAL_SCHEDULE_SUCCESS'
export const UPDATE_MEAL_SCHEDULE_ERROR = 'UPDATE_MEAL_SCHEDULE_ERROR'
export function updateMealSchedule(id, data) {
  return (dispatch) => {
    dispatch({ type: UPDATE_MEAL_SCHEDULE_START, fetching: true })
    try {
      updateSchedule(id, data)
        .then((response) => {
          dispatch({ type: UPDATE_MEAL_SCHEDULE_SUCCESS, response, fetching: false })
          toast.success(response.message || 'Meal scheduled updated successfully.')
          dispatch(fetchChefUpcomingMeals(1, 5))
        })
        .catch((error) => {
          toast.error(error.response.data.error.non_field_errors[0] || 'server error!')
        })
    } catch (error) {
      const errors = error.response.data
      dispatch({ type: UPDATE_MEAL_SCHEDULE_ERROR, errors, fetching: false })
    }
  }
}

export const SET_SCHEDULE_EDIT = 'SET_SCHEDULE_EDIT'
export function editSchedule(data) {
  return (dispatch) => {
    dispatch({ type: SET_SCHEDULE_EDIT, data })
  }
}
