import {
  FETCH_MEAL_DETAILS_START,
  FETCH_MEAL_DETAILS_SUCCESS,
  FETCH_MEAL_DETAILS_FAILURE,
  FETCH_MORE_BY_CHEF_MEALS_START,
  FETCH_MORE_BY_CHEF_MEALS_SUCCESS,
  FETCH_MORE_BY_CHEF_MEALS_FAILURE,
  FETCH_SIMILLAR_MEALS_START,
  FETCH_SIMILLAR_MEALS_SUCCESS,
  FETCH_SIMILLAR_MEALS_FAILURE
} from 'actions/mealDetails'

const initial = {
  fetching: true,
  details: null,
  error: null,
  moreByChef: [],
  fetchingMoreByChef: true,
  errorsMoreByChef: null,
  simillarMeals: [],
  fetchingSimillarMeals: true,
  errorsSimillarMeals: null
}

export default function mealDetails(state = initial, action) {
  switch (action.type) {
    case FETCH_MEAL_DETAILS_START:
      return Object.assign({}, state, {
        fetching: action.fetching
      })
    case FETCH_MEAL_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        details: action.response,
        fetching: action.fetching
      })
    case FETCH_MEAL_DETAILS_FAILURE:
      return Object.assign({}, state, {
        error: action.errors,
        fetching: action.fetching
      })
    case FETCH_MORE_BY_CHEF_MEALS_START:
      return Object.assign({}, state, {
        fetchingMoreByChef: action.fetching
      })
    case FETCH_MORE_BY_CHEF_MEALS_SUCCESS:
      return Object.assign({}, state, {
        moreByChef: action.response.results,
        fetchingMoreByChef: action.fetching
      })
    case FETCH_MORE_BY_CHEF_MEALS_FAILURE:
      return Object.assign({}, state, {
        errorsMoreByChef: action.errors,
        fetchingMoreByChef: action.fetching
      })
    case FETCH_SIMILLAR_MEALS_START:
      return Object.assign({}, state, {
        fetchingSimillarMeals: action.fetching
      })
    case FETCH_SIMILLAR_MEALS_SUCCESS:
      return Object.assign({}, state, {
        simillarMeals: action.response.results,
        fetchingSimillarMeals: action.fetching
      })
    case FETCH_SIMILLAR_MEALS_FAILURE:
      return Object.assign({}, state, {
        errorsSimillarMeals: action.errors,
        fetchingSimillarMeals: action.fetching
      })
    default:
      return state
  }
}
