export function getStatic(path) {
  return window.DD.STATIC_URL + path
}

export function formatCurrency(value, isCents = true) {
  if (isCents) {
    value = value / 100
  }

  return `$${value.toFixed(2)}`
}

export const xtremepushGlobal = (...args) => {
  return window.xtremepush(args)
}

export const globalScrollToTop = () => {
  return window.scrollTo
}
