import { SET_SORT } from 'actions/mealAndChefData'

const initial = {
  key: null,
  direction: null
}

export default function sort(state = initial, action) {
  switch (action.type) {
    case SET_SORT:
      return Object.assign({}, state, {
        key: action.sortBy,
        direction: action.sortDirection
      })
    default:
      return state
  }
}
