import React, { useEffect, useState, useContext } from 'react'
import Modal from 'react-modal'

import { cartSvg, logoSvg, defaultUser, dropAero, profileSvg, orderSvg, changePasswordSvg, logoutSvg, barMenu } from '../../constants/images'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { removeCartData, removeProfileData } from 'actions'
import SwitchModel from 'components/SwitchModel'
import { AuthContext } from 'hooks/context/userContext'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'
import LogoutModel from 'components/Shared/LogoutModel'
import SocialShare from 'components/Shared/SocialShare'
import NavLinks from 'components/Shared/NavLinks'
import AboutNavLink from 'components/Shared/AboutNavLink'
import { logoutStateHandle } from 'actions/account'

const PrivateRoute = () => {
  const [switchAccount, setSwitchAccount] = useState(false)
  const [logout, setLogout] = useState(false)
  const dispatch = useDispatch()
  const useAuthContext = useContext(AuthContext)
  const count = useSelector((state) => state.cart.count)
  const profile = useSelector((state) => state.profile.profile)
  const profileFetching = useSelector((state) => state.profile.profileFetching)
  const navigate = useNavigate()
  const accountType = localStorage.getItem('account_type')
  const [showMenu, setShowMenu] = useState(false)
  const current = window.location.href
  const meal = current.split('/')
  const [showDrop, setShowDrop] = useState(false)
  const [showDropmenu, setShowDropmenu] = useState(false)
  const modelOpen = 'modal-open'
  const setModal = () => {
    if (window.innerWidth < 992) {
      const body = document.querySelector('body')
      if (body.classList.contains(modelOpen)) {
        body.classList.remove(modelOpen)
      } else {
        body.classList.add(modelOpen)
      }
    }
  }
  const logoutHandler = () => {
    dispatch(logoutStateHandle())
    dispatch(removeProfileData())
    dispatch(removeCartData())
    localStorage.removeItem('profile_data')
    localStorage.removeItem('dindin_key')
    localStorage.removeItem('account_type')
    Cookies.remove('authtoken')
    navigate('/')
    useAuthContext.logout()
  }

  const autoLogOut = () => {
    if (profile?.profile?.is_rejected && accountType === 'organization') {
      logoutHandler()
      toast.error('Your membership plan is rejected. Please login again!')
    }
  }

  const logoutModelHandler = () => {
    setLogout(!logout)
  }

  useEffect(() => {
    autoLogOut()
  }, [])

  const switchAccountHandler = () => {
    setSwitchAccount(!switchAccount)
  }
  const EaterLinks = () => {
    return (
      <>
        <NavLinks showDropmenu={showDropmenu} setShowDropmenu={setShowDropmenu} setModal={setModal} />

        <li className="dd-nav__tab last">
          <img src={logoutSvg} alt="logout" className="pr-2" />
          <span
            className="font-semibold cursor-pointer"
            onClick={() => {
              logoutModelHandler()
            }}
          >
            Sign Out
          </span>
        </li>
      </>
    )
  }

  const OrgnizationLinks = () => {
    return (
      <>
        <AboutNavLink setShowDropmenu={setShowDropmenu} showDropmenu={showDropmenu} setModal={setModal} />

        <li className="dd-nav__tab " onClick={setModal}>
          <NavLink to={'/how-it-works'}>How It Works</NavLink>
        </li>
        <li className="dd-nav__tab last">
          <img src={logoutSvg} alt="logout" className="pr-2" />
          <span
            className="font-semibold cursor-pointer"
            // to={'/'}
            onClick={() => {
              logoutModelHandler()
            }}
          >
            Sign Out
          </span>
        </li>
      </>
    )
  }
  return (
    <div className="relative header">
      {(meal[3] === 'meals' && meal[4]?.length) || (meal[3] === 'chefs' && meal[4]?.length) ? <></> : <SocialShare />}

      <div className="container sm-navbar">
        <NavLink className="site-logo" to={profile && accountType === 'organization' ? '/account' : '/'}>
          <img src={logoSvg} alt="logo" width="71" height="58" />
        </NavLink>
        <nav className="dd-nav dd-nav--lime">
          <div className="flex mobile">
            <NavLink className="dd-nav__tab dd-nav__tab--selected mr-6 relative" to={'/checkout'}>
              <img src={cartSvg} alt="cart" className="w-6 mx-1" width={24} height={24} />
              {count > 0 ? (
                <span className="bg-red-light text-white absolute cart-postion w-4 h-4 text-xs rounded-full text-center ml-2 mb-2">{count}</span>
              ) : (
                <></>
              )}
            </NavLink>
          </div>
          <button
            type="button"
            className="toggle-btn sss"
            onClick={() => {
              setShowMenu(!showMenu)
              setModal()
            }}
            aria-label="toggle button"
          >
            <img src={barMenu} />
          </button>

          <div
            className={
              showMenu
                ? 'container flex items-center sm-wp-container-nav pr-0 active after-login'
                : 'container flex items-center sm-wp-container-nav pr-0 after-login'
            }
          >
            <button
              type="button"
              className="toggle-btn close"
              onClick={() => {
                setShowMenu(!showMenu)
                setModal()
              }}
              aria-label="toggle button"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
            <div id="navMenu">
              <ul className="flex">{profile && accountType === 'organization' ? <OrgnizationLinks /> : <EaterLinks />}</ul>
            </div>
            <div className="flex items-center justify-between" id="navMenu">
              {profile && accountType !== 'organization' && (
                <ul className="flex items-center desktop">
                  <li id="navigation-menu">
                    {' '}
                    <div className="flex cart-btn">
                      <NavLink className="dd-nav__tab dd-nav__tab--selected mr-6 relative" to={'/checkout'}>
                        <img src={cartSvg} alt="cart" width={24} height={24} className="w-6 mx-1" />
                        {count > 0 ? (
                          <span className="bg-red-light text-white absolute cart-postion w-4 h-4 text-xs rounded-full text-center ml-2 mb-2">
                            {count}
                          </span>
                        ) : (
                          <></>
                        )}
                      </NavLink>
                    </div>
                  </li>
                </ul>
              )}

              <div
                className="flex user-drop items-center ml-5"
                //  onMouseOver='activateBtn()'
              >
                <span
                  className={
                    showDrop ? 'block hover:text-red-medium truncate cursor-pointer show' : 'block hover:text-red-medium truncate cursor-pointer'
                  }
                  onClick={() => {
                    setShowDrop(!showDrop)
                  }}
                >
                  <img
                    src={profile && profile.profile?.dp ? profile.profile?.dp : defaultUser}
                    alt="user"
                    className="inline-block mr-2 rounded-md w-12 h-12 object-cover object-center"
                  />
                  {profileFetching === false && profile ? (
                    <span className="font-semibold">
                      {profile.first_name} {profile.last_name}
                    </span>
                  ) : (
                    <></>
                  )}
                  <span className="drop-aero">
                    <img src={dropAero} width="24" height="24" alt="search" />
                  </span>
                </span>
                <div className={showDrop ? 'flex drop-down flex-col p-4 z-50 show' : 'flex drop-down flex-col p-4 z-50'}>
                  <div className="hover:text-red-medium eater">
                    <img src={profileSvg} alt="profile" className="pr-2 inline-block" />
                    <NavLink className="font-semibold" to={'/account'}>
                      My Profile
                    </NavLink>
                  </div>
                  <div className="hover:text-red-medium eater">
                    <img src={orderSvg} alt="order" className="pr-2 inline-block" />
                    {profile && accountType === 'organization' ? (
                      <NavLink className="font-semibold" to={'/dashboard'}>
                        Dashboard
                      </NavLink>
                    ) : (
                      <NavLink className="font-semibold" to={'/my-orders'}>
                        My Orders
                      </NavLink>
                    )}
                  </div>
                  {profile?.eater?.signup_method === 'facebook' || profile?.eater?.signup_method === 'google' ? null : (
                    <div className="hover:text-red-medium">
                      <img src={changePasswordSvg} alt="password" className="pr-2 inline-block" />
                      <NavLink className="font-semibold" to={'/change-password'}>
                        Change Password
                      </NavLink>
                    </div>
                  )}

                  <div className="hover:text-red-medium last">
                    <img src={logoutSvg} alt="logout" className="pr-2 inline-block" />
                    <span
                      className="font-semibold cursor-pointer"
                      // to={'/'}
                      onClick={() => {
                        logoutModelHandler()
                      }}
                    >
                      Sign Out
                    </span>
                  </div>
                  {accountType && (
                    <div className="hover:text-red-medium">
                      <button onClick={switchAccountHandler} className="inline-block dd-button-cta--primary-alt btn">
                        Switch To {accountType === 'organization' ? 'Diner' : 'Organization'}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <Modal
        isOpen={switchAccount}
        onRequestClose={() => null}
        shouldCloseOnEsc={false}
        contentLabel="Checkout Complete"
        className="dd-modal modal-dd-center border-2"
        overlayClassName="dd-modal-overlay"
        ariaHideApp={false}
      >
        <SwitchModel switchAccountHandler={switchAccountHandler} />
      </Modal>
      <Modal
        isOpen={logout}
        onRequestClose={() => null}
        shouldCloseOnEsc={false}
        contentLabel="Checkout Complete"
        className="dd-modal modal-dd-center border-2"
        overlayClassName="dd-modal-overlay"
        ariaHideApp={false}
      >
        <LogoutModel logoutModelHandler={logoutModelHandler} logoutHandler={logoutHandler} />
      </Modal>
    </div>
  )
}

export default PrivateRoute
