import { FETCH_CUISINES_COMPLETE } from 'actions/mealAndChefData'

export default function meals(state = [], action) {
  switch (action.type) {
    case FETCH_CUISINES_COMPLETE:
      return action.cuisines
    default:
      return state
  }
}
