import { LOGOUT_HANDLER } from 'actions/account'
import {
  FETCH_PREPAID_AMOUNT,
  FETCH_PREPAID_AMOUNT_FAILURE,
  FETCH_PREPAID_AMOUNT_SUCCESS,
  FETCH_CORP_USER,
  FETCH_CORP_USER_FAILURE,
  FETCH_CORP_USER_SUCCESS,
  FETCH_CORP_USER_DISTRIBUTE,
  FETCH_CORP_USER_DISTRIBUTE_FAILURE,
  FETCH_CORP_USER_DISTRIBUTE_SUCCESS,
  FETCH_USER_REWARDS,
  FETCH_USER_REWARDS_FAILURE,
  FETCH_USER_REWARDS_SUCCESS
} from 'actions/manulDistribution'

const initial = {
  price: [],
  priceFetching: false,
  priceError: null,
  user: {},
  userError: null,
  userFetching: false,
  list: [],
  listFetching: false,
  listError: null,
  page: 1,
  nextList: 1,
  loadingMoreEmp: false,
  listPage: 1,
  listTotal: null,
  rewards: [],
  rewardsFetching: true,
  rewardError: null,
  loadingMoreRewards: false,
  rewardPage: 1,
  rewardTotal: null
}

export default function manulDistribution(state = initial, action) {
  switch (action.type) {
    case LOGOUT_HANDLER: {
      return initial
    }
    case FETCH_PREPAID_AMOUNT: {
      return {
        ...state,
        priceFetching: action.fetching
      }
    }
    case FETCH_PREPAID_AMOUNT_SUCCESS: {
      return {
        ...state,
        priceFetching: action.fetching,
        price: action.data
      }
    }
    case FETCH_PREPAID_AMOUNT_FAILURE: {
      return {
        ...state,
        priceFetching: action.fetching,
        price: [],
        priceError: action.data
      }
    }
    case FETCH_CORP_USER: {
      return {
        ...state,
        userFetching: action.fetching
      }
    }
    case FETCH_CORP_USER_SUCCESS: {
      return {
        ...state,
        userFetching: action.fetching,
        user: action.data
      }
    }
    case FETCH_CORP_USER_FAILURE: {
      return {
        ...state,
        userFetching: action.fetching,
        user: {},
        userError: action.data
      }
    }
    case FETCH_CORP_USER_DISTRIBUTE: {
      return {
        ...state,
        listFetching: action.fetching,
        loadingMoreEmp: action.loadingMoreEmp
      }
    }
    case FETCH_CORP_USER_DISTRIBUTE_FAILURE: {
      return {
        ...state,
        listFetching: action.fetching,
        list: state.list.length > 1 ? state.list : [],
        listError: action.error,
        loadingMoreEmp: action.loadingMoreEmp
      }
    }
    case FETCH_CORP_USER_DISTRIBUTE_SUCCESS: {
      return {
        ...state,
        listFetching: action.fetching,
        list: action.page === 1 ? action.list : [...state.list, ...action.list],
        page: action.total,
        nextList: action.next,
        listPage: action.page,
        loadingMoreEmp: action.loadingMoreEmp,
        listTotal: action.total
      }
    }
    case FETCH_USER_REWARDS: {
      return {
        ...state,
        rewardsFetching: action.fetching,
        loadingMoreRewards: action.loadingMoreRewards
      }
    }
    case FETCH_USER_REWARDS_SUCCESS: {
      return {
        ...state,
        rewardsFetching: action.fetching,
        rewards: action.page === 1 ? action.data : [...state.rewards, ...action.data],
        loadingMoreRewards: action.loadingMoreRewards,
        rewardPage: action.page,
        rewardTotal: action.total
      }
    }
    case FETCH_USER_REWARDS_FAILURE: {
      return {
        ...state,
        rewardsFetching: action.fetching,
        rewards: state.rewards.length > 1 ? state.rewards : [],
        rewardError: action.data,
        loadingMoreRewards: action.loadingMoreRewards
      }
    }
    default:
      return {
        ...state
      }
  }
}
