import React, { Component } from 'react'
import errorInfo from './assets/images/icons/error-info.svg'


class ReactErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state to indicate that an error has occurred
    console.log('error', error)
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error or perform other error handling tasks here
    console.error('Error caught by ReactErrorBoundary:', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI when an error occurs
      return (
        <div>
          <div className="error-info-sec">
            <div className="error-logo-img"><img src={errorInfo} alt="Error Info" /></div>
            <div className="error-heading">Oops!</div>
            <div className="error-subheading">Something went wrong.</div>
            <div className="error-btn">
              <button className="dd-button-cta--primary-alt">Back to Home</button>
            </div>
          </div>
          {/* You can customize the error message or UI as needed */}
        </div>
      )
    }

    // Render the children components as usual
    return this.props.children
  }
}

export default ReactErrorBoundary
