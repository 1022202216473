import { LOGOUT_HANDLER } from 'actions/account'
import { FETCH_PAYMENT_METHODS_COMPLETE, PAYMENT_METHOD_ADDED } from 'actions/payment'

export default function paymentMethods(state = [], action = {}) {
  switch (action.type) {
    case FETCH_PAYMENT_METHODS_COMPLETE:
      return action.paymentMethods
    case PAYMENT_METHOD_ADDED:
      return state.concat([action.card])
    case LOGOUT_HANDLER: {
      return state
    }
    default:
      return state
  }
}
