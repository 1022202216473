export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']

export const validationConst = {
  FILE_SIZE: 512000,
  passwordRegex: /^(?=.*\d)(?=.*[@#$%])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
  nameRegex: /^[A-Za-z0-9_@!£$%*./#&+-]+$/,
  socialIdRegex: /^((?!www|http|https|.com).)*$/,
  userNameRegex: /^[a-zA-Z0-9._]{2,24}\d*$/,
  websiteRegex: /^([a-zA-Z0-9]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
  numberRegex: /^[\d]*$/,
  emailRegex: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  corpEmailRegex:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phoneNumberRegex: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
  serviceWorkerRegex: '/[^/?]+\\.[^/]+$'
}
export const aboutUs = [
  { value: '', label: 'Select' },
  { value: '2', label: 'Google Ads' },
  { value: '3', label: 'Radio' },
  { value: '4', label: 'Friend/Family' },
  { value: '1', label: 'Facebook' },
  { value: '5', label: 'Instagram' },
  { value: '6', label: 'LinkedIn' },
  { value: '7', label: 'Print Ads' },
  { value: '9', label: 'Newspaper/Magazine Article' },
  { value: '11', label: 'Capitol Concierge' },
  { value: '12', label: 'Television' },
  { value: '13', label: 'Direct Mail' },
  { value: '8', label: 'Other' }
]

export const countryCode = [{ value: 1, label: 'United States' }]

export const accountType = () => localStorage.getItem('account_type')
export const defaultValues = {
  zerodollor: '1 Voucher'
}

export const countryCodeValue = '+1'
