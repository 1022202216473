export function formatCurrency(value = 0, isCents = true) {
  if (isCents) {
    value = value / 100
  }

  return `$${value?.toFixed(2)}`
}

export function unFormatCurrency(value) {
  return value ? `${value}`.replaceAll('$', '') : ''
}

export function calculateTotal(subtotal, commPct, ...additionalFees) {
  return subtotal + commPct + additionalFees.reduce((x, acc) => x + acc, 0)
}

export function calculateTotal2(priceEach, quantity, commPct, ...additionalFees) {
  return calculateTotal(priceEach * quantity, commPct, ...additionalFees)
}
