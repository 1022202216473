import React, { useContext, useState } from 'react'
import { defaultUser, logoutSvg, changePasswordSvg, profileSvg, dropAero, logoSvg, barMenu } from '../../constants/images'
import { removeProfileData } from 'actions'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import SideBarContext from 'components/context/sidebarContext'
import { bodyscrollHide } from 'utils/helper'
import Cookies from 'js-cookie'
import Modal from 'react-modal'
import LogoutModel from 'components/Shared/LogoutModel'
import { logoutStateHandle } from 'actions/account'

const ChefHeader = ({ error = false }) => {
  const dispatch = useDispatch()
  const profile = useSelector((state) => state.profile.profile)
  const navigate = useNavigate()
  const [logout, setLogout] = useState(false)
  const { openSidebar, setOpenSidebar } = useContext(SideBarContext)

  const logoutHandler = () => {
    dispatch(removeProfileData())
    dispatch(logoutStateHandle())
    localStorage.removeItem('profile_data')
    localStorage.removeItem('dindin_key')
    Cookies.remove('authtoken')
    navigate('/')
  }

  const logoutModelHandler = () => {
    setLogout(!logout)
  }

  return (
    <>
      {error && (
        <Link to={'/chef/dashboard'}>
          <img src={logoSvg} alt="logo" className="main-logo" />
        </Link>
      )}

      <header className="chef-header">
        <span className="mobile-logo">
          <Link to={'/chef/dashboard'}>
            <img src={logoSvg} alt="logo" height={70} width={70} className="main-logo" />
          </Link>
        </span>
        <div className="flex items-center justify-between">
          {/* {mealBtn && ( */}
          <div className="header-add-button">
            <Link to="/chef/menu/add/">
              <button className="dd-button dd-button-cta dd-button-cta--primary-alt">Add New Meal</button>
            </Link>
          </div>
          {/* )} */}
          <div className="flex user-drop items-center ml-5 chef-view">
            <span className="block hover:text-red-medium truncate">
              <img
                src={profile?.profile?.chef_profile_image || defaultUser}
                alt="user"
                className="inline-block mr-2 rounded-md w-12 h-12 object-cover object-center"
              />
              <span className="font-semibold">
                {profile?.first_name} {profile?.last_name}
              </span>
              <span className="drop-aero">
                <img src={dropAero} width="24" height="24" alt="search" />
              </span>
            </span>

            <div className="flex drop-down flex-col p-4 z-50">
              <div className="hover:text-red-medium">
                <img src={profileSvg} alt="profile" className="pr-2 inline-block" />
                <NavLink className="font-semibold" to={'/chef/profile'}>
                  My Profile
                </NavLink>
              </div>
              <div className="hover:text-red-medium">
                <img src={changePasswordSvg} alt="password" className="pr-2 inline-block" />
                <NavLink className="font-semibold" to={'/chef/change-password'}>
                  Change Password
                </NavLink>
              </div>
              <div className="hover:text-red-medium">
                <img src={logoutSvg} alt="logout" className="pr-2 inline-block" />
                <span className="font-semibold cursor-pointer" onClick={logoutModelHandler}>
                  Sign Out
                </span>
              </div>
            </div>
          </div>
          <span
            className="bar-menu"
            onClick={() => {
              setOpenSidebar(!openSidebar)
              bodyscrollHide()
            }}
          >
            <img src={barMenu} width={20} height={18} alt="bar menu" />
          </span>
        </div>
      </header>
      <Modal
        isOpen={logout}
        onRequestClose={() => null}
        shouldCloseOnEsc={false}
        contentLabel="Checkout Complete"
        className="dd-modal modal-dd-center border-2"
        overlayClassName="dd-modal-overlay"
        ariaHideApp={false}
      >
        <LogoutModel logoutModelHandler={logoutModelHandler} logoutHandler={logoutHandler} />
      </Modal>
    </>
  )
}

export default React.memo(ChefHeader)
