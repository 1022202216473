import { getClientSecret, postCard } from 'actions'
import CreditCardAdd from 'components/CreditCardAdd'
import StripeWrapper from 'components/StripeWrapper'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const AddCreditCard = () => {
  const [isFormOpen, setFormOpen] = useState(false)
  const clientSecrectKey = useSelector((state) => state.order.clientSecretKey)
  const dispatch = useDispatch()
  const cancleCreditForm = () => {
    setFormOpen(!isFormOpen)
  }

  useEffect(() => {
    dispatch(getClientSecret())
  }, [])
  return (
    <StripeWrapper>
      <CreditCardAdd
        clientSecret={clientSecrectKey?.intent_data?.client_secret}
        onSuccess={(card) => {
          cancleCreditForm()
          dispatch(postCard(card))
          dispatch(getClientSecret())
        }}
        formOpen={cancleCreditForm}
      />
    </StripeWrapper>
  )
}

export default AddCreditCard
