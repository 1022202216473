import { HTTP_CLIENT } from 'clients/httpClient'
import { getAuthHeaders } from 'constants/getHeader'
import { toQueryString } from 'utils/http'

async function protectedDelete(endpoint, params = {}) {
  const queryParams = toQueryString(params)
  const response = await HTTP_CLIENT.delete(`${endpoint}?${queryParams}`, {
    headers: getAuthHeaders()
  })

  return response
}

export { protectedDelete }
