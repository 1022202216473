import { FETCH_FEATURES_CHEF_ERROR, FETCH_FEATURES_CLEAR, FETCH_FEATURES_COMPLETE, FETCH_FEATURES_MEAL_ERROR } from 'actions/mealAndChefData'

const initial = {
  meal: null,
  chef: null,
  chefError: null,
  mealError: null
}

export default function features(state = initial, action) {
  switch (action.type) {
    case FETCH_FEATURES_COMPLETE: {
      const { featureCategory, feature } = action
      return Object.assign({}, state, {
        [featureCategory]: feature
      })
    }
    case FETCH_FEATURES_CHEF_ERROR: {
      return Object.assign({}, state, {
        chefError: action.error
      })
    }
    case FETCH_FEATURES_MEAL_ERROR: {
      return Object.assign({}, state, {
        mealError: action.error
      })
    }
    case FETCH_FEATURES_CLEAR: {
      return Object.assign({}, state, {
        meal: null,
        chef: null
      })
    }
    default:
      return state
  }
}
