import React, { useEffect } from 'react'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import 'assets/scss/Navigation.scss'
import { useDispatch, useSelector } from 'react-redux'
import ChefHeader from 'components/Chef/ChefHeader'
import { fetchCart } from 'actions'
import { accountType } from 'constants/appConstants'

const index = () => {
  const token = localStorage.getItem('dindin_key')
  const dispatch = useDispatch()
  const profile = useSelector((state) => state.profile.profile)
  const count = useSelector((state) => state.cart.count)
  useEffect(() => {
    token && !profile?.is_chef && accountType !== 'organization' && !count && dispatch(fetchCart())
  }, [token])

  const IsChef = () => <>{profile?.is_chef ? <ChefHeader /> : <PrivateRoute />}</>
  return <>{token ? <IsChef /> : <PublicRoute />}</>
}

export default index
