import { getMealDetails, getMoreMealByChef, getSimillarMeals } from 'hooks/services/fetchServices'

export const FETCH_MORE_BY_CHEF_MEALS_START = 'FETCH_MORE_BY_CHEF_MEALS_START'
export const FETCH_MORE_BY_CHEF_MEALS_SUCCESS = 'FETCH_MORE_BY_CHEF_MEALS_SUCCESS'
export const FETCH_MORE_BY_CHEF_MEALS_FAILURE = 'FETCH_MORE_BY_CHEF_MEALS_FAILURE'
export function fetchMoreByChefMeals(chefId) {
  return (dispatch) => {
    dispatch({ type: FETCH_MORE_BY_CHEF_MEALS_START, fetching: true })
    getMoreMealByChef(chefId)
      .then((response) => {
        dispatch({ type: FETCH_MORE_BY_CHEF_MEALS_SUCCESS, response, fetching: false })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_MORE_BY_CHEF_MEALS_FAILURE, errors, fetching: false })
      })
  }
}

export const FETCH_SIMILLAR_MEALS_START = 'FETCH_SIMILLAR_MEALS_START'
export const FETCH_SIMILLAR_MEALS_SUCCESS = 'FETCH_SIMILLAR_MEALS_SUCCESS'
export const FETCH_SIMILLAR_MEALS_FAILURE = 'FETCH_SIMILLAR_MEALS_FAILURE'
export function fetchSimillarMeals(mealId, geometry) {
  return (dispatch) => {
    if (geometry.market_place_id === null) return
    dispatch({ type: FETCH_SIMILLAR_MEALS_START, fetching: true })
    getSimillarMeals(mealId, geometry)
      .then((response) => {
        dispatch({ type: FETCH_SIMILLAR_MEALS_SUCCESS, response, fetching: false })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_SIMILLAR_MEALS_FAILURE, errors, fetching: false })
      })
  }
}

export const FETCH_MEAL_DETAILS_START = 'FETCH_MEAL_DETAILS_START'
export const FETCH_MEAL_DETAILS_SUCCESS = 'FETCH_MEAL_DETAILS_SUCCESS'
export const FETCH_MEAL_DETAILS_FAILURE = 'FETCH_MEAL_DETAILS_FAILURE'
export function fetchMealDetails(mealId) {
  const marketPlace = JSON.parse(localStorage.getItem('global_marketplace'))
  const localMarketPlace = JSON.parse(localStorage.getItem('marketPlace'))

  return (dispatch) => {
    dispatch({ type: FETCH_MEAL_DETAILS_START, fetching: true })
    getMealDetails(mealId)
      .then((response) => {
        if (response === 'Schedule Meal is Expired') {
          window.location.href = '/error'
        }
        dispatch({ type: FETCH_MEAL_DETAILS_SUCCESS, response, fetching: false })
        // Impliment this once it will review will start showing
        // dispatch(fetchMealReviews(response.id))
        dispatch(fetchSimillarMeals(response.id, { market_place_id: localMarketPlace.market_place_id || marketPlace?.[0]?.id }))
      })
      .catch((error) => {
        const errors = error.response.data
        window.location.href = '/error'
        dispatch({ type: FETCH_MEAL_DETAILS_FAILURE, errors, fetching: false })
      })
  }
}
