import React from 'react'
import { email, linkedin, twitter, facebook } from '../../constants/images'
import { fbWebShare, mailWebDetails, twWebShare } from 'constants/socialShareLink'

const SocialShare = () => {
  const currentPageUrl = window.location.origin
  return (
    <div className="icon-bar z-20 ">
      <a className="btn mb-px mx-1 inline-block facebook" href={fbWebShare(currentPageUrl)} target="_blank" rel="noreferrer" data-testid="fb_link">
        <img width={24} height={24} src={facebook} alt="facebook" />
      </a>
      <a
        className="btn mb-px mx-1 inline-block linkedin "
        target="_blank"
        rel="noreferrer"
        href={`https://www.linkedin.com/sharing/share-offsite/?url=${currentPageUrl}`}
        data-testid="linkedin_link"
      >
        <img width={24} height={24} src={linkedin} alt="linkedin" />
      </a>
      <a className="btn mb-px mx-1 inline-block twitter " target="_blank" rel="noreferrer" href={twWebShare(currentPageUrl)} data-testid="twitter">
        <img width={24} height={24} src={twitter} alt="twitter_link" />
      </a>
      <a
        className="btn mb-px mx-1 inline-block google "
        target="_blank"
        rel="noreferrer"
        href={mailWebDetails(currentPageUrl)}
        data-testid="google_link"
      >
        <img width={24} height={24} src={email} alt="Email" />
      </a>
    </div>
  )
}

export default React.memo(SocialShare)
