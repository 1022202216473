export const validationMsg = {
  firstNameRequired: 'Please enter your First Name.',
  firstNameMin: 'First name should not be less than 2 characters',
  firstNameMax: 'First name should not be more than 20 characters',
  lastNameRequired: 'Please enter your Last Name.',
  lastNameMin: 'Last name should not be less than 2 characters',
  lastNameMax: 'Last name should not be more than 20 characters',
  email: 'Please enter your Email.',
  emailPattern: 'Enter a valid email address.',
  passwordRequired: 'Please enter Password.',
  passwordPattern: 'Password must be alphanumeric with one capital letter and a special charater @#$% and length should atleast 8 characters.',
  newPassword: 'Please enter a new password',
  reNewPassword: 'Please re-enter a new password',
  newPasswordMatch: 'Both password need to be the same',
  countryCodeRequired: 'Country code is required!.',
  mobileNumberRequired: 'Please enter a valid 10 digit Phone number.',
  mobileNumberLength: 'Phone should not be less or more than 10 character',
  numberMatch: 'Enter a valid number.',
  aboutRequired: 'Please select an item in the list.',
  usernameRequired: 'Please enter your valid username.',
  postalCodeRequired: 'Please enter your valid postal code.',
  personNameRequired: 'Please enter your valid person name.',
  stateRequired: 'Please enter your valid state.',
  addressRequired: 'Please enter your valid address.',
  cityRequired: 'Please enter your valid city.',
  contactNumberRequired: 'Please enter your valid contact number.',
  nameRequired: 'Please enter your valid name.',
  preferredRequired: 'Please select your preferred address.',
  kitchenNameRequired: 'Please enter your valid kitchen name.',
  kitchenTypeRequired: 'Please select your kitchen type.',
  primaryCuisineRequired: 'Please select your primary cuisine.',
  bioRequired: 'Please enter your valid bio.',
  kitchenName: 'Please enter your valid kitchen name.',
  kitchenType: 'Please select your kitchen type.',
  primaryCuisine: 'Please select your primary cuisine.',
  bio: 'Please enter your valid bio.',
  mealName: 'Please enter a valid meal name.',
  mealDescription: 'Please enter a valid meal discription.',
  mealPrice: 'Please enter meal price.',
  mealTag: 'Please enter meal tags.',
  mealCuisine: 'Please select a meal cuisine.',
  mealTags: 'Tags is required.',
  mealIngredients: 'Please mention meal ingredients.',
  heating_instruction: 'Please mention meal heating instructions.',
  sku: 'Please mention meal sku no.',
  scheduleMeal: 'Please select a Meal.',
  startDate: 'Please choose start date in future.',
  endData: 'Please Select End Date.',
  count: 'Count cannot be Empty.',
  title: 'Title is required.',
  address: 'Address is required.',
  city: 'City is required.',
  postalCode: 'Postal code is required.',
  name: 'Organization name is required',
  ein: 'Ein number is required',
  zip_code: 'Zip code is required and length should be 5 digits',
  termsCheck: 'Please select terms of services.',
  content: 'Please enter your content',
  marketplaceid: 'Please Choose Your Market Place'
}

export const WebText = {
  inputError: 'input-error',
  months: 'months',
  dateFormat: 'YYYY-MM-DD',
  modalOpen: 'modal-open',
  mealImage: 'MEAL image',
  sameOrigin: 'same-origin',
  applicationJson: 'application/json',
  accountCart: '/account/cart/'
}

export const downLoadLinks = {
  apple: 'https://apps.apple.com/us/app/din-din-eat/id1542704636',
  store: 'https://play.google.com/store/apps/details?id=com.eatdindin.eaters&pli=1'
}

export const warningMessagePhone = '*By entering your mobile number, you agree to receive text messages at the number provided'
