import { WebText } from 'constants/stringConstants'
import { deleteAddress } from 'hooks/services/deleteService'
import { fetchAddress, fetchProfile, getCorporateUserData, getEaterBenefits, getHowDidYouHearAboutUs } from 'hooks/services/fetchServices'
import {
  addAddress,
  postContactForm,
  resetCorpUserPassword,
  resetPasswordPost,
  updateProfile,
  uploadChefImg,
  uploadProfileImg
} from 'hooks/services/postService'
import { updateAddress } from 'hooks/services/putService'
import Cookies from 'js-cookie'
import moment from 'moment'
import { toast } from 'react-toastify'

export const FETCH_PROFILE_DATA_START = 'FETCH_PROFILE_DATA_START'
export const FETCH_PROFILE_DATA_SUCCESS = 'FETCH_PROFILE_DATA_SUCCESS'
export const FETCH_PROFILE_DATA_FAILURE = 'FETCH_PROFILE_DATA_FAILURE'
export function fetchProfileData() {
  return (dispatch) => {
    dispatch({
      type: FETCH_PROFILE_DATA_START,
      fetching: true
    })
    fetchProfile()
      .then((response) => {
        dispatch({ type: FETCH_PROFILE_DATA_SUCCESS, response, fetching: false })
      })
      .catch((error) => {
        dispatch({ type: FETCH_PROFILE_DATA_FAILURE, error, fetching: false })
      })
  }
}

export const UPDATE_PROFILE_DATA_START = 'UPDATE_PROFILE_DATA_START'
export const UPDATE_PROFILE_DATA_SUCCESS = 'UPDATE_PROFILE_DATA_SUCCESS'
export const UPDATE_PROFILE_DATA_FAILURE = 'UPDATE_PROFILE_DATA_FAILURE'
export function updateProfileData(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PROFILE_DATA_START,
      fetching: true
    })
    updateProfile(data)
      .then((response) => {
        dispatch({ type: UPDATE_PROFILE_DATA_SUCCESS, response, fetching: false })
        dispatch(fetchProfileData())
        toast.success('Profile Updated Successfully')
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: UPDATE_PROFILE_DATA_FAILURE, errors, fetching: false })
        toast.error(errors && errors.details ? errors.details : 'Profile Update Failed')
      })
  }
}

export const LOGIN_DATA = 'LOGIN_DATA'
export function loginData(response) {
  return (dispatch) => {
    dispatch({ type: LOGIN_DATA, data: response })
    dispatch(fetchProfileData())
  }
}

export const UPDATE_PROFILE_IMAGE_START = 'UPDATE_PROFILE_IMAGE_START'
export const UPDATE_PROFILE_IMAGE_SUCCESS = 'UPDATE_PROFILE_IMAGE_SUCCESS'
export const UPDATE_PROFILE_IMAGE_FAILURE = 'UPDATE_PROFILE_IMAGE_FAILURE'
export function updateProfileImage(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PROFILE_IMAGE_START,
      fetching: true
    })
    uploadProfileImg(data)
      .then((response) => {
        dispatch({ type: UPDATE_PROFILE_IMAGE_SUCCESS, response, fetching: false })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: UPDATE_PROFILE_IMAGE_FAILURE, errors, fetching: false })
      })
  }
}

export function updateChefImage(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PROFILE_IMAGE_START,
      fetching: true
    })
    uploadChefImg(data)
      .then((response) => {
        dispatch({ type: UPDATE_PROFILE_IMAGE_SUCCESS, response, fetching: false })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: UPDATE_PROFILE_IMAGE_FAILURE, errors, fetching: false })
      })
  }
}

export const FETCH_USER_ADDRESS_START = 'FETCH_USER_ADDRESS_START'
export const FETCH_USER_ADDRESS_FAILURE = 'FETCH_USER_ADDRESS_FAILURE'
export const FETCH_USER_ADDRESS_SUCCESS = 'FETCH_USER_ADDRESS_SUCCESS'
export function fetchUserAddress() {
  return (dispatch) => {
    dispatch({ type: FETCH_USER_ADDRESS_START, fetching: true })
    fetchAddress()
      .then((response) => {
        dispatch({ type: FETCH_USER_ADDRESS_SUCCESS, response, fetching: false })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_USER_ADDRESS_FAILURE, errors, fetching: false })
      })
  }
}

export const DELETE_USER_ADDRESS_START = 'DELETE_USER_ADDRESS_START'
export const DELETE_USER_ADDRESS_FAILURE = 'DELETE_USER_ADDRESS_FAILURE'
export const DELETE_USER_ADDRESS_SUCCESS = 'DELETE_USER_ADDRESS_SUCCESS'
export function deleteUserAddress(id) {
  return (dispatch) => {
    dispatch({ type: DELETE_USER_ADDRESS_START, fetching: true })
    deleteAddress(id)
      .then((response) => {
        dispatch({ type: DELETE_USER_ADDRESS_SUCCESS, response, fetching: false })
        dispatch(fetchUserAddress())
        toast.success('Address deleted successfully')
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: DELETE_USER_ADDRESS_FAILURE, errors, fetching: false })
        toast.error(errors && errors.details ? errors.details : 'Address not deleted')
      })
  }
}

export const UPDATE_USER_ADDRESS_START = 'UPDATE_USER_ADDRESS_START'
export const UPDATE_USER_ADDRESS_FAILURE = 'UPDATE_USER_ADDRESS_FAILURE'
export const UPDATE_USER_ADDRESS_SUCCESS = 'UPDATE_USER_ADDRESS_SUCCESS'
export function updateUserAddress(id, data) {
  return (dispatch) => {
    dispatch({ type: UPDATE_USER_ADDRESS_START, fetching: true })
    updateAddress(id, data)
      .then((response) => {
        dispatch({ type: UPDATE_USER_ADDRESS_SUCCESS, response, fetching: false })
        dispatch(fetchUserAddress())
        toast.success('Address updated successfully')
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: UPDATE_USER_ADDRESS_FAILURE, errors, fetching: false })
        toast.error(errors && errors.details ? errors.details : 'Address not updated')
      })
  }
}

export const ADD_USER_ADDRESS_START = 'ADD_USER_ADDRESS_START'
export const ADD_USER_ADDRESS_FAILURE = 'ADD_USER_ADDRESS_FAILURE'
export const ADD_USER_ADDRESS_SUCCESS = 'ADD_USER_ADDRESS_SUCCESS'
export function addUserAddress(data) {
  return (dispatch) => {
    dispatch({ type: ADD_USER_ADDRESS_START, fetching: true })
    addAddress(data)
      .then((response) => {
        dispatch({ type: ADD_USER_ADDRESS_SUCCESS, response, fetching: false })
        toast.success('Address added successfully')
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: ADD_USER_ADDRESS_FAILURE, errors, fetching: false })
        toast.error(errors && errors.details ? errors.details : 'Address not added')
      })
  }
}

export const FETCH_EATER_BENEFITS_START = 'FETCH_EATER_BENEFITS_START'
export const FETCH_EATER_BENEFITS_SUCCESS = 'FETCH_EATER_BENEFITS_SUCCESS'
export const FETCH_EATER_BENEFITS_FAILURE = 'FETCH_EATER_BENEFITS_FAILURE'
export function fetchEaterBenefits() {
  return (dispatch) => {
    dispatch({ type: FETCH_EATER_BENEFITS_START, fetching: true })
    getEaterBenefits()
      .then((response) => {
        dispatch({ type: FETCH_EATER_BENEFITS_SUCCESS, response, fetching: false })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_EATER_BENEFITS_FAILURE, errors, fetching: false })
      })
  }
}

export const REMOVE_PROFILE_DATA = 'REMOVE_PROFILE_DATA'
export function removeProfileData() {
  return (dispatch) => {
    dispatch({
      type: REMOVE_PROFILE_DATA
    })
  }
}

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'
export function resetPassword(data) {
  return (dispatch) => {
    dispatch({ type: RESET_PASSWORD_START })
    resetPasswordPost(data)
      .then((response) => {
        dispatch({ type: RESET_PASSWORD_SUCCESS, data: response })
        toast.success(response.message)
        if (response.detail) {
          toast.error(response.detail)
        }
        if (response.message) {
          setTimeout(() => {
            window.location.href = '/'
          }, 2000)
        }
      })
      .catch((error) => {
        dispatch({ type: RESET_PASSWORD_ERROR, data: error })
        toast.error(error.message)
      })
  }
}

export const RESET_CORP_USER_PASSWORD_START = 'RESET_CORP_USER_PASSWORD_START'
export const RESET_CORP_USER_PASSWORD_SUCCESS = 'RESET_CORP_USER_PASSWORD_SUCCESS'
export const RESET_CORP_USER_PASSWORD_ERROR = 'RESET_CORP_USER_PASSWORD_ERROR'
export function resetCorpUserPasswordAction(data) {
  return (dispatch) => {
    dispatch({ type: RESET_CORP_USER_PASSWORD_START })
    resetCorpUserPassword(data)
      .then((response) => {
        dispatch({ type: RESET_CORP_USER_PASSWORD_SUCCESS, data: response })
        toast.success(response.message)
        window.location.href = '/account'
        if (response.detail) {
          toast.error(response.detail)
        }
        if (response.message) {
          setTimeout(() => {
            window.location.href = '/'
          }, 2000)
        }
      })
      .catch((error) => {
        dispatch({ type: RESET_CORP_USER_PASSWORD_ERROR, data: error })
        toast.error(error.message)
      })
  }
}

export const CONTACT_FORM_START = 'CONTACT_FORM_START'
export const CONTACT_FORM_SUCCESS = 'CONTACT_FORM_SUCCESS'
export const CONTACT_FORM_ERROR = 'CONTACT_FORM_ERROR'
export function sendContactFormData(data) {
  return (dispatch) => {
    dispatch({ type: CONTACT_FORM_START })
    postContactForm(data)
      .then((response) => {
        dispatch({ type: CONTACT_FORM_SUCCESS, data: response })
      })
      .catch((error) => {
        dispatch({ type: CONTACT_FORM_ERROR, error: error })
      })
  }
}

export const HEAR_ABOUT_US_SUCCESS = 'HEAR_ABOUT_US_SUCCESS'
export const HEAR_ABOUT_US_ERROR = 'HEAR_ABOUT_US_ERROR'
export function fetchHowDidYourHearAboutUs() {
  return (dispatch) => {
    getHowDidYouHearAboutUs()
      .then((response) => {
        dispatch({ type: HEAR_ABOUT_US_SUCCESS, data: response })
      })
      .catch((error) => {
        dispatch({ type: HEAR_ABOUT_US_ERROR, error: error })
      })
  }
}

export const LOGOUT_HANDLER = 'LOGOUT_HANDLER'
export function logoutStateHandle() {
  return (dispatch) => {
    dispatch({ type: LOGOUT_HANDLER })
    dispatch(removeProfileData())
    localStorage.removeItem('profile_data')
    localStorage.removeItem('dindin_key')
    Cookies.remove('authtoken')
  }
}


export const XP_CORPORATE_START = 'XP_CORPORATE_START'
export const XP_CORPORATE_SUCCESS = 'XP_CORPORATE_SUCCESS'
export const XP_CORPORATE_ERROR = 'XP_CORPORATE_ERROR'
export function sendXpCorprateData(data) {
  return (dispatch) => {
    dispatch({ type: XP_CORPORATE_START })
    getCorporateUserData(data)
      .then((response) => {
        dispatch({ type: XP_CORPORATE_SUCCESS, data: response })
        let voucherDate = moment(response?.reset_dollar_date).format(WebText.dateFormat)
        let dollarDate = moment(response?.reset_voucher_date).format(WebText.dateFormat)
        window.xtremepush('tag', 'dinner_name', response.dinner_name)
        window.xtremepush('tag', 'corporate_id', response.corporate_id)
        window.xtremepush('tag', 'corporate_code', response.corporate_id)
        window.xtremepush('tag', 'dinner_email', response.dinner_email)
        window.xtremepush('tag', 'remaining_dollar', response.remaining_dollar)
        window.xtremepush('tag', 'current_wallet', response.remaining_dollar)
        window.xtremepush('tag', 'remaining_voucher', response.remaining_voucher)
        window.xtremepush('tag', 'reset_voucher_date', voucherDate)
        window.xtremepush('tag', 'reset_dollar_date', dollarDate)
        window.xtremepush('tag', 'expiration_date', dollarDate)
        setTimeout(() => {
          if (response?.remaining_dollar > 0) {
            // console.log('reset_dollar')
            window.xtremepush('event', 'reset_dollar')
          }
          if (response?.remaining_voucher > 0) {
            // console.log('reset_voucher')
            window.xtremepush('event', 'reset_voucher')
          }
        }, 1000)
      })
      .catch((error) => {
        dispatch({ type: XP_CORPORATE_ERROR, error: error })
      })
  }
}


export const LANDING_PAGE = 'LANDING_PAGE'
export function landingPageAction(data) {
  return (dispatch) => {
    dispatch({ type: LANDING_PAGE, data })
  }
}
