import thunk from 'redux-thunk'
import logger from 'redux-logger'

const middleware = [thunk]

if (process.env.NODE_ENV !== 'production') {
  middleware.push(logger)
}

export default middleware
