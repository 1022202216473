import { fetchProfileData } from 'actions'
import AddCreditCard from 'components/Shared/payment/AddCreditCard'
import StripeWrapper from 'components/StripeWrapper'
import MetaHoc from 'hoc/MetaHoc'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import {
  OrgTransaction,
  ChefProfileEdit,
  ChooseUser,
  ProtectedRoute,
  PublicRoute,
  ReportsAdmin,
  Reset,
  SelecSignupRoute,
  SignInRoute,
  AboutUsMobile,
  OrganizationSignUp,
  HomeRoute,
  HowitworksRoute,
  ChangePasswordChef,
  ChangePasswordUser,
  CheckoutRoute,
  Chef,
  TermAndConditonsRoute,
  FaqPage,
  FoodSafetyRoute,
  ForgotPassword,
  UserDashboard,
  AboutUsPage,
  AddNewMeal,
  AllMealsRoute,
  AllOrders,
  ChefHoc,
  ChefMenu,
  ChefProfile,
  CompleteProfile,
  NewOrders,
  NewSchedule,
  OrderDetails,
  OrdersRoute,
  OurTeamPage,
  PasswordReset,
  PrivacyRoute,
  Profile,
  ProfileRoute,
  CorporateProgramMobile,
  FaqPageMobile,
  FoodSafetyMobile,
  OurTeamPageMobile,
  PrivacyPolicyMobile,
  TermAndConditonsMobile,
  ContactUs,
  CookingSchedule,
  CorporateAdminHoc,
  CorporateProgram,
  CuisineSolutions,
  CustomerSignup,
  Dashboard,
  EditMeal,
  ErrorPage,
  MealDetailRoute,
  MenuDetail,
  LandingPageRoute,
  VoucherMealPage,
  ManualDistribution
} from './pagesImport'

const AllRoutes = () => {
  const accountType = localStorage.getItem('account_type')
  const profileData = useSelector((state) => state?.profile?.profile)
  const token = localStorage.getItem('dindin_key')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const navigateToResetPage = () => {
    if (
      profileData?.profile?.corporate_user_source !== 'referral' &&
      profileData?.profile?.is_corporate_user &&
      profileData?.profile?.is_password_changed === false
    ) {
      navigate('/corporate_user/password_reset')
    }
  }

  useEffect(() => {
    profileData && navigateToResetPage()
  }, [profileData])

  useEffect(() => {
    token && dispatch(fetchProfileData())
  }, [token])

  return (
    <>
      <Routes>
        {accountType === 'organization' && <Route path="/" element={<Navigate to="/account" replace />} />}
        {profileData && <Route path="/:referalcode" element={<Navigate to="/account" replace />} />}
        <Route element={<PublicRoute />}>
          <Route exact path="/" element={<HomeRoute />} />
          <Route exact path="/landing-page" element={<LandingPageRoute />} />
          <Route path="/corporate/signup/" element={<OrganizationSignUp />} />
          <Route exact path="/signup" element={<CustomerSignup />} />
          <Route exact path="/create-kitchen" element={<Chef />} />
          <Route
            exact
            path="/select-signup"
            element={
              <MetaHoc>
                <SelecSignupRoute />
              </MetaHoc>
            }
          />

          <Route path="/:referalcode" element={<CustomerSignup />} />
          <Route
            path="/reset/:id"
            element={
              <MetaHoc>
                <Reset />
              </MetaHoc>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <MetaHoc>
                <ForgotPassword />
              </MetaHoc>
            }
          />
          <Route exact path="/login" element={<SignInRoute />} />
          <Route exact path="/terms-and-conditions" element={<TermAndConditonsRoute />} />
          <Route exact path="/privacy-policy" element={<PrivacyRoute />} />
          <Route exact path="/how-it-works" element={<HowitworksRoute />} />
          <Route exact path="/about-us" element={<AboutUsPage />} />
          <Route exact path="/ourteam" element={<OurTeamPage />} />
          <Route exact path={'/corporate'} element={<CorporateProgram />} />
          <Route exact path={'/our-partners'} element={<CuisineSolutions />} />
          <Route exact path="/food-safety" element={<FoodSafetyRoute />} />
          <Route exact path="/faq" element={<FaqPage />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/meals" element={<AllMealsRoute />} />
          <Route exact path="/meals/:cusinesName" element={<AllMealsRoute />} />

          <Route exact path="/meals/:chefname/:mealname" element={<MealDetailRoute />} />

          <Route exact path="/voucher/meals/:chefname/:mealname" element={<MealDetailRoute />} />
          <Route
            path="*"
            element={
              <MetaHoc>
                <ErrorPage />
              </MetaHoc>
            }
          />
          <Route
            path="/error"
            element={
              <MetaHoc>
                <ErrorPage />
              </MetaHoc>
            }
          />
          <Route
            path="/corporate_user/password_reset"
            element={
              <MetaHoc>
                <PasswordReset />
              </MetaHoc>
            }
          />
        </Route>
        <Route element={<ProtectedRoute allowedRoles={'is_chef'} />}>
          <Route
            path="/chef/dashboard"
            element={
              <ChefHoc>
                <Dashboard />
              </ChefHoc>
            }
          />
          <Route
            path="/chef/change-password"
            element={
              <ChefHoc>
                <ChangePasswordChef />
              </ChefHoc>
            }
          />
          <Route
            path="/chef/menu/add"
            element={
              <ChefHoc>
                <AddNewMeal menu={false} />
              </ChefHoc>
            }
          />

          <Route
            path="/chef/profile"
            element={
              <ChefHoc>
                <ChefProfile />
              </ChefHoc>
            }
          />
          <Route
            path="/chef/edit-profile"
            element={
              <ChefHoc>
                <ChefProfileEdit />
              </ChefHoc>
            }
          />
          <Route
            path="/chef/neworders/:id"
            element={
              <ChefHoc>
                <OrderDetails />
              </ChefHoc>
            }
          />
          <Route
            path="/chef/neworders"
            element={
              <ChefHoc>
                <NewOrders />
              </ChefHoc>
            }
          />
          <Route
            path="/chef/menu"
            element={
              <ChefHoc>
                <ChefMenu />
              </ChefHoc>
            }
          />
          <Route
            path="/chef/menu/:id"
            element={
              <ChefHoc>
                <MenuDetail />
              </ChefHoc>
            }
          />
          <Route
            path="/chef/menu/edit/:mealId"
            element={
              <ChefHoc>
                <EditMeal />
              </ChefHoc>
            }
          />
          <Route
            path="/chef/cooking-schedule/:scheduleid"
            element={
              <ChefHoc>
                <CookingSchedule />
              </ChefHoc>
            }
          />
          <Route
            path="/chef/cooking-schedule/new"
            element={
              <ChefHoc>
                <NewSchedule />
              </ChefHoc>
            }
          />
          <Route
            path="/chef/profile/edit"
            element={
              <ChefHoc>
                <ChefProfileEdit />
              </ChefHoc>
            }
          />

          <Route
            path="/chef/profile"
            element={
              <ChefHoc>
                <Profile />
              </ChefHoc>
            }
          />
          <Route
            path="/chef/cooking-schedule"
            element={
              <ChefHoc>
                <CookingSchedule />
              </ChefHoc>
            }
          />
        </Route>
        <Route element={<ProtectedRoute allowedRoles={'is_superuser'} />}>
          <Route
            path="/corporate-admin/orders"
            element={
              <MetaHoc>
                <CorporateAdminHoc>
                  <AllOrders />
                </CorporateAdminHoc>
              </MetaHoc>
            }
          />
          <Route
            path="/corporate-admin/users/"
            element={
              <MetaHoc>
                <CorporateAdminHoc>
                  <ReportsAdmin />
                </CorporateAdminHoc>
              </MetaHoc>
            }
          />
        </Route>
        <Route path="/native/add-card/" element={<AddCreditCard />} />
        {/* Dinner route */}
        <Route element={<ProtectedRoute allowedRoles={'is_active'} />}>
          <Route exact path="/completeprofile" element={<CompleteProfile />} />
          <Route path="/select-account" element={<ChooseUser />} />
          <Route exact path="/change-password" element={<ChangePasswordUser />} />
          <Route exact path="/corporate/wallet-transaction/" element={<OrgTransaction />} />
          <Route exact path="/account" element={<ProfileRoute />} />
          <Route exact path="/voucher-meals" element={<VoucherMealPage />} />
          <Route
            exact
            path="/checkout"
            element={
              <StripeWrapper>
                <CheckoutRoute />
              </StripeWrapper>
            }
          />
          <Route
            exact
            path="/corporate/:corpId/manual-distribute/:userId"
            element={
              <StripeWrapper>
                <ManualDistribution />
              </StripeWrapper>
            }
          />
          <Route
            exact
            path="/corporate/:corpId/manual-distribute"
            element={
              <StripeWrapper>
                <ManualDistribution />
              </StripeWrapper>
            }
          />
          <Route exact path="/my-orders" element={<OrdersRoute />} />
          <Route
            exact
            path="/dashboard"
            element={
              <MetaHoc>
                <UserDashboard />
              </MetaHoc>
            }
          />
        </Route>
        {/* Mobile WebView Routes */}
        <Route path="/about-us-mobile/" element={<AboutUsMobile />} />
        <Route path="/our_team_mobile/" element={<OurTeamPageMobile />} />
        <Route path="/food-safety-mobile/" element={<FoodSafetyMobile />} />
        <Route path="/faq-mobile/" element={<FaqPageMobile />} />
        <Route path="/corp-about-us-mobile/" element={<CorporateProgramMobile />} />
        <Route path="/term-condition-us-mobile/" element={<TermAndConditonsMobile />} />
        <Route path="/privacy-policy-mobile/" element={<PrivacyPolicyMobile />} />
      </Routes>
    </>
  )
}

export default AllRoutes
