import { deleteGiftCards, getGiftCards } from 'hooks/services/fetchServices'
import { addGiftCards } from 'hooks/services/postService'
import { toast } from 'react-toastify'

export const DELETE_GIFT_CARDS_START = 'DELETE_GIFT_CARDS_START'
export const DELETE_GIFT_CARDS_SUCCESS = 'DELETE_GIFT_CARDS_SUCCESS'
export const DELETE_GIFT_CARDS_FAILURE = 'DELETE_GIFT_CARDS_FAILURE'
export function deleteGiftCard(code) {
  return (dispatch) => {
    dispatch({ type: DELETE_GIFT_CARDS_START, fetching: true })
    deleteGiftCards(code)
      .then((response) => {
        dispatch({ type: DELETE_GIFT_CARDS_SUCCESS, response, fetching: false })
        dispatch(fetchGiftCards())
        toast.success('Gift card deleted successfully')
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: DELETE_GIFT_CARDS_FAILURE, errors, fetching: false })
        toast.error('Gift card could not be deleted')
      })
  }
}

export const ADD_GIFT_CARDS_START = 'ADD_GIFT_CARDS_START'
export const ADD_GIFT_CARDS_SUCCESS = 'ADD_GIFT_CARDS_SUCCESS'
export const ADD_GIFT_CARDS_FAILURE = 'ADD_GIFT_CARDS_FAILURE'
export function addGiftCard(data) {
  return (dispatch) => {
    dispatch({ type: ADD_GIFT_CARDS_START, fetching: true })
    addGiftCards(data)
      .then((response) => {
        dispatch({ type: ADD_GIFT_CARDS_SUCCESS, response, fetching: false })
        dispatch(fetchGiftCards())
        toast.success('Gift card added successfully')
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: ADD_GIFT_CARDS_FAILURE, errors, fetching: false })
        toast.error(errors && errors.code ? errors.code : 'Something went wrong')
      })
  }
}

export const FETCH_GIFT_CARDS_START = 'FETCH_GIFT_CARDS_START'
export const FETCH_GIFT_CARDS_SUCCESS = 'FETCH_GIFT_CARDS_SUCCESS'
export const FETCH_GIFT_CARDS_FAILURE = 'FETCH_GIFT_CARDS_FAILURE'
export function fetchGiftCards() {
  return (dispatch) => {
    dispatch({ type: FETCH_GIFT_CARDS_START, fetching: true })
    getGiftCards()
      .then((response) => {
        dispatch({ type: FETCH_GIFT_CARDS_SUCCESS, response, fetching: false })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_GIFT_CARDS_FAILURE, errors, fetching: false })
      })
  }
}
