import { HTTP_CLIENT } from 'clients/httpClient'
import { getAuthHeaders } from 'constants/getHeader'

async function protectedPut(endpoint, params = {}) {
  const response = await HTTP_CLIENT.put(endpoint, params, {
    headers: getAuthHeaders()
  })

  return response || []
}

async function protectedPatch(endpoint, params = {}) {
  const response = await HTTP_CLIENT.patch(endpoint, params, {
    headers: getAuthHeaders()
  })

  return response || []
}

export { protectedPut, protectedPatch }
