import { LOGOUT_HANDLER } from 'actions/account'
import { FETCH_TOTAL_REVENUE_ERROR, FETCH_TOTAL_REVENUE_START, FETCH_TOTAL_REVENUE_SUCCESS } from 'actions/chefSection'

const intialState = {
  totalReevnue: 0.0,
  featching: null,
  error: null
}

export default function totalChefRevenue(state = intialState, action) {
  switch (action.type) {
    case FETCH_TOTAL_REVENUE_START:
      return {
        ...state,
        featching: true
      }
    case FETCH_TOTAL_REVENUE_SUCCESS:
      return {
        ...state,
        totalReevnue: action.response,
        featching: false,
        error: null
      }
    case FETCH_TOTAL_REVENUE_ERROR:
      return {
        ...state,
        error: action.errors,
        featching: false
      }
    case LOGOUT_HANDLER: {
      return intialState
    }
    default:
      return state
  }
}
