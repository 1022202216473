import { LOGOUT_HANDLER } from 'actions/account'
import {
  FETCH_GIFT_CARDS_FAILURE,
  FETCH_GIFT_CARDS_START,
  FETCH_GIFT_CARDS_SUCCESS,
  ADD_GIFT_CARDS_START,
  ADD_GIFT_CARDS_SUCCESS,
  ADD_GIFT_CARDS_FAILURE,
  DELETE_GIFT_CARDS_FAILURE,
  DELETE_GIFT_CARDS_START,
  DELETE_GIFT_CARDS_SUCCESS
} from 'actions/giftCards'

const intialState = {
  giftCards: [],
  fetching: null,
  error: null,
  deleteSuccessMessage: null
}

export default function giftCards(state = intialState, action) {
  switch (action.type) {
    case FETCH_GIFT_CARDS_START:
      return {
        ...state,
        fetching: true
      }
    case FETCH_GIFT_CARDS_SUCCESS:
      return {
        ...state,
        giftCards: action.response,
        fetching: false,
        error: null
      }
    case FETCH_GIFT_CARDS_FAILURE:
      return {
        ...state,
        error: action.error,
        fetching: false
      }
    case ADD_GIFT_CARDS_START:
      return {
        ...state,
        fetching: true
      }
    case ADD_GIFT_CARDS_SUCCESS:
      return {
        ...state,
        giftCards: action.response,
        error: null
      }
    case ADD_GIFT_CARDS_FAILURE:
      return {
        ...state,
        error: action.errors,
        fetching: false
      }
    case DELETE_GIFT_CARDS_FAILURE:
      return {
        ...state,
        error: action.errors,
        fetching: false
      }
    case DELETE_GIFT_CARDS_START:
      return {
        ...state,
        fetching: true
      }
    case DELETE_GIFT_CARDS_SUCCESS:
      return {
        ...state,
        deleteSuccessMessage: action.response.data.message,
        fetching: true,
        error: null
      }
    case LOGOUT_HANDLER: {
      return intialState
    }
    default:
      return state
  }
}
