import { combineReducers } from 'redux'
import filters from './filters'
import sort from './sort'
import meals from './meals'
import cuisines from './cuisines'
import chefs from './chefs'
import features from './features'
import reviews from './reviews'
import order from './order'
import paymentMethods from './paymentMethods'
import cart from './cart'
import geometry from './geometry'
import pageCategory from './pageCategory'
import profile from './profile'
import cards from './cards'
import giftCards from './giftCards'
import mealDetails from './mealDetails'
import chefDetails from './chefDetails'
import organization from './organization'
import kitchen from './kitchen'
import marketplace from './marketplace'
import totalChefRevenue from './totalRevenue'
import orderReport from './orderReport'
import contactMessages from './corporateProgram'
import manulDistribution from './manulDistribution'

const app = combineReducers({
  filters,
  sort,
  meals,
  cuisines,
  chefs,
  features,
  reviews,
  order,
  paymentMethods,
  cart,
  geometry,
  pageCategories: pageCategory,
  profile,
  cards,
  giftCards,
  mealDetails,
  chefDetails,
  organization,
  kitchen,
  marketplace,
  totalChefRevenue,
  orderReport,
  contactMessages,
  manulDistribution
})

export default app
