import { LOGOUT_HANDLER } from 'actions/account'
import { SEND_CORPORATE_MESSAGE_FAILURE, SEND_CORPORATE_MESSAGE_SUCCESS, SEND_CORPORATE_MESSAGE } from 'actions/corporateProgram'

const initial = {
  contactMessage: null,
  loading: false
}

export default function contactMessages(state = initial, action) {
  switch (action.type) {
    case SEND_CORPORATE_MESSAGE:
      return {
        ...state,
        contactMessage: null,
        loading: true
      }
    case SEND_CORPORATE_MESSAGE_SUCCESS:
      return {
        ...state,
        contactMessage: action.data || null,
        loading: false
      }
    case SEND_CORPORATE_MESSAGE_FAILURE:
      return {
        ...state,
        loading: false,
        contactMessage: null,
        contactMessageError: action.error
      }
    case LOGOUT_HANDLER: {
      return initial
    }
    default:
      return state
  }
}
