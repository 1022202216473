import { deleteOrgUser } from 'hooks/services/deleteService'
import {
  applyFilter,
  fetchOrgWalletHistory,
  fetchSingleUserWalletHistory,
  getAllcorpplan,
  getAllorganisation,
  getAllUser,
  getOrgBenefits,
  getOrgProfile,
  getOrgWalletAmount,
  getSingleUserWalletAmount,
  getWalletHistory,
  searchUsers,
  totalUsers
} from 'hooks/services/fetchServices'
import { postOrgUser } from 'hooks/services/postService'
import { activateUser, updateOrgUser } from 'hooks/services/putService'
import { toast } from 'react-toastify'

export const SET_SINGLE_USER = 'SET_SINGLE_USER'
export function setSingleUser(user) {
  return {
    type: SET_SINGLE_USER,
    data: user
  }
}

export const GET_SINGLE_USER_WALLET_START = 'GET_SINGLE_USER_WALLET_START'
export const GET_SINGLE_USER_WALLET_SUCCESS = 'GET_SINGLE_USER_WALLET_SUCCESS'
export const GET_SINGLE_USER_WALLET_ERROR = 'GET_SINGLE_USER_WALLET_ERROR'
export function getSingleUserWalletHistory(userId, page) {
  return (dispatch) => {
    dispatch({ type: GET_SINGLE_USER_WALLET_START, currentPage: page })
    fetchSingleUserWalletHistory(userId, page)
      .then((response) => {
        dispatch({ type: GET_SINGLE_USER_WALLET_SUCCESS, data: response, page: response.count, currentPage: page })
      })
      .catch((error) => {
        dispatch({ type: GET_SINGLE_USER_WALLET_ERROR, data: error })
      })
  }
}

export const SINGLE_WALLET_BALANCE_START = 'SINGLE_WALLET_BALANCE_START'
export const SINGLE_WALLET_BALANCE_SUCCESS = 'SINGLE_WALLET_BALANCE_SUCCESS'
export const SINGLE_WALLET_BALANCE_ERROR = 'SINGLE_WALLET_BALANCE_ERROR'
export function getSingleUserWalletBalance(userId) {
  return (dispatch) => {
    dispatch({ type: SINGLE_WALLET_BALANCE_START })
    getSingleUserWalletAmount(userId)
      .then((response) => {
        dispatch({ type: SINGLE_WALLET_BALANCE_SUCCESS, data: response })
      })
      .catch((error) => {
        dispatch({ type: SINGLE_WALLET_BALANCE_ERROR, data: error })
      })
  }
}

export const FETCH_ORG_BENEFIT_START = 'FETCH_ORG_BENEFIT_START'
export const FETCH_ORG_BENEFIT_COMPLETE = 'FETCH_ORG_BENEFIT_COMPLETE'
export const FETCH_ORG_BENEFIT_ERROR = 'FETCH_ORG_BENEFIT_ERROR'
export function fetchOrgBenefits() {
  return (dispatch) => {
    dispatch({ type: FETCH_ORG_BENEFIT_START })
    getOrgBenefits()
      .then((response) => {
        dispatch({ type: FETCH_ORG_BENEFIT_COMPLETE, response })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_ORG_BENEFIT_ERROR, errors })
      })
  }
}

export const FETCH_ORG_DATA_START = 'FETCH_ORG_DATA_START'
export const FETCH_ORG_DATA_COMPLETE = 'FETCH_ORG_DATA_COMPLETE'
export const FETCH_ORG_DATA_ERROR = 'FETCH_ORG_DATA_ERROR'
export function fetchOrgData() {
  return (dispatch) => {
    dispatch({ type: FETCH_ORG_DATA_START })
    getOrgProfile()
      .then((response) => {
        dispatch({ type: FETCH_ORG_DATA_COMPLETE, response })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_ORG_DATA_ERROR, errors })
      })
  }
}

// For Organization
export const ORGANIZATION_USER_START = 'ORGANIZATION_USER_START'
export const ORGANIZATION_USER_SUCCESS = 'ORGANIZATION_USER_SUCCESS'
export const ORGANIZATION_USER_ERROR = 'ORGANIZATION_USER_ERROR'
export function getOrganizationUser(page) {
  return (dispatch) => {
    dispatch({ type: ORGANIZATION_USER_START, page })
    getAllUser(page)
      .then((response) => {
        dispatch({ type: ORGANIZATION_USER_SUCCESS, data: response, page })
      })
      .catch((error) => {
        dispatch({ type: ORGANIZATION_USER_ERROR, data: error })
      })
  }
}

export const ORGANIZATION_FILTERED_USER_START = 'ORGANIZATION_FILTERED_USER_START'
export const ORGANIZATION_FILTERED_USER_SUCCESS = 'ORGANIZATION_FILTERED_USER_SUCCESS'
export const ORGANIZATION_FILTERED_USER_ERROR = 'ORGANIZATION_FILTERED_USER_ERROR'
export function getOrganizationFilterUser(dates, page) {
  return (dispatch) => {
    dispatch({ type: ORGANIZATION_FILTERED_USER_START })
    applyFilter(dates, page)
      .then((response) => {
        dispatch({ type: ORGANIZATION_FILTERED_USER_SUCCESS, data: response, page: page })
      })
      .catch((error) => {
        dispatch({ type: ORGANIZATION_FILTERED_USER_ERROR, data: error })
      })
  }
}

export const ORGANIZATION_USER_ADD_START = 'ORGANIZATION_USER_ADD_START'
export const ORGANIZATION_USER_ADD_SUCCESS = 'ORGANIZATION_USER_ADD_SUCCESS'
export const ORGANIZATION_USER_ADD_ERROR = 'ORGANIZATION_USER_ADD_ERROR'
export function addOrganizationUser(user) {
  return (dispatch) => {
    dispatch({ type: ORGANIZATION_USER_ADD_START })
    postOrgUser(user)
      .then((response) => {
        dispatch(addOrgUserState(false))
        dispatch({ type: ORGANIZATION_USER_ADD_SUCCESS, data: response })
        toast.success(`${response.first_name} ${response.last_name} added succesfully`)
        dispatch(getOrganizationUser(1))
        dispatch(getOrgTotal())
      })
      .catch((error) => {
        dispatch({ type: ORGANIZATION_USER_ADD_ERROR, data: error.response.data.msg })
      })
  }
}

export const ORG_USER_ADD = 'ORG_USER_ADD'
export function addOrgUserState(data) {
  return (dispatch) => {
    dispatch({ type: ORG_USER_ADD, data })
  }
}

export const ORG_DISTIBUTE_MODAL = 'ORG_DISTIBUTE_MODAL'
export function orgDistributeModal(data) {
  return (dispatch) => {
    dispatch({ type: ORG_DISTIBUTE_MODAL, data })
  }
}

export const ORGANIZATION_USER_UPDATE_START = 'ORGANIZATION_USER_UPDATE_START'
export const ORGANIZATION_USER_UPDATE_SUCCESS = 'ORGANIZATION_USER_UPDATE_SUCCESS'
export const ORGANIZATION_USER_UPDATE_ERROR = 'ORGANIZATION_USER_UPDATE_ERROR'
export function updateOrganizationUser(userId, userData) {
  return (dispatch) => {
    dispatch({ type: ORGANIZATION_USER_UPDATE_START })
    updateOrgUser(userId, userData)
      .then((response) => {
        dispatch(updateOrgUserState(false))
        dispatch({ type: ORGANIZATION_USER_UPDATE_SUCCESS, data: response })
        toast.success(`${response.first_name} ${response.last_name} Updated succesfully`)
        dispatch(getOrganizationUser(1))
      })
      .catch((error) => {
        dispatch({ type: ORGANIZATION_USER_UPDATE_ERROR, data: error })
        toast.error(error.response.data.msg)
      })
  }
}

export const ORG_USER_UPDATE = 'ORG_USER_UPDATE'
export function updateOrgUserState(data) {
  return (dispatch) => {
    dispatch({ type: ORG_USER_UPDATE, data })
  }
}

export const ORGANIZATION_USER_DELETE_START = 'ORGANIZATION_USER_DELETE_START'
export const ORGANIZATION_USER_DELETE_SUCCESS = 'ORGANIZATION_USER_DELETE_SUCCESS'
export const ORGANIZATION_USER_DELETE_ERROR = 'ORGANIZATION_USER_DELETE_ERROR'
export function deleteOrganizationUser(id) {
  return (dispatch) => {
    dispatch({ type: ORGANIZATION_USER_DELETE_START })
    deleteOrgUser(id)
      .then((response) => {
        dispatch({ type: ORGANIZATION_USER_DELETE_SUCCESS, data: response })
        dispatch(getOrganizationUser(1))
        dispatch(getOrgTotal())
      })
      .catch((error) => {
        dispatch({ type: ORGANIZATION_USER_DELETE_ERROR, data: error })
      })
  }
}

export const ORGANIZATION_USER_ACTIVATE_START = 'ORGANIZATION_USER_ACTIVATE_START'
export const ORGANIZATION_USER_ACTIVATE_SUCCESS = 'ORGANIZATION_USER_ACTIVATE_SUCCESS'
export const ORGANIZATION_USER_ACTIVATE_ERROR = 'ORGANIZATION_USER_ACTIVATE_ERROR'
export function activateOrganizationUser(id) {
  return (dispatch) => {
    dispatch({ type: ORGANIZATION_USER_ACTIVATE_START })
    activateUser(id)
      .then((response) => {
        dispatch({ type: ORGANIZATION_USER_ACTIVATE_SUCCESS, data: response })
        dispatch(getOrganizationUser(1))
        dispatch(getOrgTotal())
      })
      .catch((error) => {
        dispatch({ type: ORGANIZATION_USER_ACTIVATE_ERROR, data: error })
      })
  }
}

// activateUser

export const ORGANIZATION_SEARCH_USER_START = 'ORGANIZATION_SEARCH_USER_START'
export const ORGANIZATION_SEARCH_USER_SUCCESS = 'ORGANIZATION_SEARCH_USER_SUCCESS'
export const ORGANIZATION_SEARCH_USER_ERROR = 'ORGANIZATION_SEARCH_USER_ERROR'
export function searchOrganizationUser(value, page) {
  return (dispatch) => {
    dispatch({ type: ORGANIZATION_SEARCH_USER_START })
    searchUsers(value, page)
      .then((response) => {
        dispatch({ type: ORGANIZATION_SEARCH_USER_SUCCESS, data: response, page: page })
      })
      .catch((error) => {
        dispatch({ type: ORGANIZATION_SEARCH_USER_ERROR, data: error })
      })
  }
}

export const ORGANIZATION_TOTAL_START = 'ORGANIZATION_TOTAL_START'
export const ORGANIZATION_TOTAL_SUCCESS = 'ORGANIZATION_TOTAL_SUCCESS'
export const ORGANIZATION_TOTAL_ERROR = 'ORGANIZATION_TOTAL_ERROR'
export function getOrgTotal() {
  return (dispatch) => {
    dispatch({ type: ORGANIZATION_TOTAL_START })
    totalUsers()
      .then((response) => {
        dispatch({ type: ORGANIZATION_TOTAL_SUCCESS, data: response })
      })
      .catch((error) => {
        dispatch({ type: ORGANIZATION_TOTAL_ERROR, data: error })
      })
  }
}

export const WALLET_HISTORY_START = 'WALLET_HISTORY_START'
export const WALLET_HISTORY_SUCCESS = 'WALLET_HISTORY_SUCCESS'
export const WALLET_HISTORY_ERROR = 'WALLET_HISTORY_ERROR'
export function fetchWalletHistory(page) {
  return (dispatch) => {
    dispatch({ type: WALLET_HISTORY_START, currentPage: page })
    getWalletHistory(page)
      .then((response) => {
        dispatch({ type: WALLET_HISTORY_SUCCESS, data: response.results, page: response.count, currentPage: page })
      })
      .catch((error) => {
        dispatch({ type: WALLET_HISTORY_ERROR, data: error })
      })
  }
}

export const DETAIL_REPORT_SUCCESS = 'DETAIL_REPORT_SUCCESS'
export function getOrderReportDetail(data) {
  return (dispatch) => {
    dispatch({ type: DETAIL_REPORT_SUCCESS, data: data })
  }
}

export const GET_ORGANISATION_START = 'GET_ORGANISATION_START'
export const GET_ORGANISATION_SUCCESS = 'GET_ORGANISATION_SUCCESS'
export const GET_ORGANISATION_ERROR = 'GET_ORGANISATION_ERROR'
export function getOrganisation() {
  return (dispatch) => {
    dispatch({ type: GET_ORGANISATION_START })
    getAllorganisation()
      .then((response) => {
        dispatch({ type: GET_ORGANISATION_SUCCESS, data: response })
      })
      .catch((error) => {
        dispatch({ type: GET_ORGANISATION_ERROR, data: error })
      })
  }
}
export const ORG_WALLET_BALANCE_START = 'ORG_WALLET_BALANCE_START'
export const ORG_WALLET_BALANCE_SUCCESS = 'ORG_WALLET_BALANCE_SUCCESS'
export const ORG_WALLET_BALANCE_ERROR = 'ORG_WALLET_BALANCE_ERROR'
export function getOrgWalletBalance() {
  return (dispatch) => {
    dispatch({ type: ORG_WALLET_BALANCE_START })
    getOrgWalletAmount()
      .then((response) => {
        dispatch({ type: ORG_WALLET_BALANCE_SUCCESS, data: response })
      })
      .catch((error) => {
        dispatch({ type: ORG_WALLET_BALANCE_ERROR, data: error })
      })
  }
}

export const ORG_WALLET_HISTORY_START = 'ORG_WALLET_HISTORY_START'
export const ORG_WALLET_HISTORY_SUCCESS = 'ORG_WALLET_HISTORY_SUCCESS'
export const ORG_WALLET_HISTORY_ERROR = 'ORG_WALLET_HISTORY_ERROR'
export function getOrgWalletHistory(page) {
  return (dispatch) => {
    dispatch({ type: ORG_WALLET_HISTORY_START })
    fetchOrgWalletHistory(page)
      .then((response) => {
        dispatch({ type: ORG_WALLET_HISTORY_SUCCESS, data: response.results, page: response.count })
      })
      .catch((error) => {
        dispatch({ type: ORG_WALLET_HISTORY_ERROR, data: error })
      })
  }
}

export const GET_CORP_PLAN_START = 'GET_CORP_PLAN_START'
export const GET_CORP_PLAN_SUCCESS = 'GET_CORP_PLAN_SUCCESS'
export const GET_CORP_PLAN_ERROR = 'GET_CORP_PLAN_ERROR'
export function getCorpplan() {
  return (dispatch) => {
    dispatch({ type: GET_CORP_PLAN_START })
    getAllcorpplan()
      .then((response) => {
        dispatch({ type: GET_CORP_PLAN_SUCCESS, data: response })
      })
      .catch((error) => {
        dispatch({ type: GET_CORP_PLAN_ERROR, data: error })
      })
  }
}

export const RESET_PAGE = 'RESET_PAGE'
export const resetPage = () => {
  return (dispatch) => {
    dispatch({ type: RESET_PAGE })
  }
}

export const DETAIL_REPORT_REMOVE = 'DETAIL_REPORT_REMOVE'
export function removeOrderReportDetail() {
  return (dispatch) => {
    dispatch({ type: DETAIL_REPORT_REMOVE })
  }
}
