/*eslint quotes: ["error", "single", { "allowTemplateLiterals": true }]*/
import { protectedFormDataPost, protectedPost, publicPost } from 'clients/postClient'
import { protectedPatch, protectedPut } from 'clients/putClient'
import { RemoveEmpty } from 'utils/helper'

const createIntent = async (orderData) => {
  const { data: response } = await protectedPost(`v1/shop/intent/create/`, orderData)
  return response
}

const mealAddToCart = async (mealId) => {
  const { data: response } = await protectedPost('/newcart/', mealId)
  return response
}

const addReviews = async ({ order_id, rating, written_content }) => {
  const { data: response } = await protectedPost(`/reviews/`, { order_id, rating, written_content })
  return response
}

const updateCartMealQuantity = async (mealId, change_type, type) => {
  const { data: response } = await protectedPost('/account/change-item-quantity/', { meal_id: mealId, change_type, meal_type: type })
  return response
}

const applyCouponCodeToCart = async (code) => {
  const payload = {
    coupon_code: code
  }
  const { data: response } = await protectedPost('/coupon/', payload, { is_r: 1 })
  return response
}

const addGiftCards = async (data) => {
  const { data: response } = await protectedPost('/gift-card/gift-card-add/', data)
  return response
}

const addAddress = async (data) => {
  const { data: response } = await protectedPost('/eater-address/', data)
  return response
}

const updateProfile = async (data) => {
  const { data: response } = await protectedPost('/eater/profile/change/', RemoveEmpty(data))
  return response
}

const updateCuisinesProfile = async (data) => {
  const { data: response } = await protectedPost('/eater/profile/change/', RemoveEmpty(data))
  return response
}
const uploadProfileImg = async (data) => {
  const { data: response } = await protectedPost('/accounts/upload-dp/', data)
  return response
}

const uploadChefImg = async (data) => {
  const { data: response } = await protectedPut('/chef-profile-image/', data)
  return response
}

const changePassowrd = async (data) => {
  const { data: response } = await protectedPatch('/change-password/', data)
  return response
}

const forgotPassword = async (data) => {
  const { data: response } = await publicPost('/account/password_reset/', data)
  return response
}

const verifyPassword = async (data) => {
  const { data: response } = await publicPost('/verify-forgot-password/', data)
  return response
}

const resetPassword = async (data) => {
  const { data: response } = await publicPost('/reset-password/', data)
  return response
}

const addCreditCard = async (paymentMethodId) => {
  const { data: response } = await protectedPost('/account/credit-cards/', { stripe_payment_method_id: paymentMethodId })
  return response
}

const createOrder = async (orderData) => {
  const { data: response } = await protectedPost('/v1/shop/order/create/', orderData)
  return response
}

const placeVoucherOrder = async (orderData) => {
  const { data: response } = await protectedPost('/v1/shop/voucher/meal/order/', orderData)
  return response
}

const confirmPayment = async (data) => {
  const { data: response } = await protectedPost(`/v1/payment/transaction/confirm-payment/`, data)
  return response
}

const applyCouponCodeAtPayment = async (code, id) => {
  const payload = {
    coupon: code
  }
  const { data: response } = await protectedPost(`/v1/shop/coupon/${id}/apply/`, payload)
  return response
}
const removeCouponCodeAtPayment = async (code, id) => {
  const payload = {
    coupon: code
  }
  const { data: response } = await protectedPost(`/v1/shop/coupon/${id}/remove/`, payload)
  return response
}

const sendPhoneOtp = async (country_code, phone) => {
  if (phone) {
    const { data: response } = await protectedPost('/phone-verification/send-otp/', { country_code, phone })
    return response
  }
  return null
}

const verifyEmailOtp = async (id, otp) => {
  const { data: response } = await protectedPost(`/email-verification/${id}/verify-otp/`, { otp })
  return response
}

const verifyPhoneOtp = async (id, otp) => {
  const { data: response } = await protectedPost(`/phone-verification/${id}/verify-otp/`, { otp })
  return response
}
const sendEmailOtp = async (email) => {
  const { data: response } = await protectedPost('/email-verification/send-otp/', { email })
  return response
}

const completeProfile = async (data, socialSign) => {
  if (socialSign === 'google') {
    const { data: response } = await protectedPost('/get_google_user_info/', data)
    return response
  } else if (socialSign === 'fb') {
    const { data: response } = await protectedPost('/signup_with_facebook/', data)
    return response
  }
}

const resetPasswordPost = async (data) => {
  const { data: response } = await protectedPost('/account/reset-password/', data)
  return response
}

const addNewMeal = async (data) => {
  const { data: response } = await protectedFormDataPost('/v2/meal/', data)
  return response
}

const scheduleMeal = async (data) => {
  const { data: response } = await protectedPost('/v2/schedulemeal/', data)
  return response
}
const uploadCsv = async (data) => {
  const { data: response } = await protectedPost(`/upload/`, data)
  //  postImage
  return response
}
const corpResetPassword = async (data) => {
  const { data: response } = await protectedPost('/accounts/corpuser_change_password/', data)
  return response
}

const forCorporate = async (data) => {
  const { data: response } = await protectedPost('/account/corporate_contact_form/', data)
  return response
}

const postOrgUser = async (data) => {
  const { data: response } = await protectedPost(`/upload_detail_list/`, data)
  return response
}

const chefCuisineUpdate = async (data) => {
  const { data: response } = await protectedPost(`/chef-cuisines/`, data)
  return response
}

const fbSignup = async (data) => {
  const { data: response } = await protectedPost(`/signup_with_facebook/`, data)
  return response
}

const exportAllOrders = async () => {
  const { data: response } = await protectedPost(`/export-corporate-users/export-all-orders/`)
  return response
}

const exportCorporateUsers = async (data) => {
  const { data: response } = await protectedPost(`/export-corporate-users/`, data)
  return response
}
const resetCorpUserPassword = async (data) => {
  const { data: response } = await protectedPost('/accounts/corpuser_change_password/', data)
  return response
}
const postContactForm = async (data) => {
  const { data: response } = await publicPost('/account/contact_form/', data)
  return response
}

const manualPaymentApi = async (data) => {
  const { data: response } = await publicPost('/v1/payment/distribution-amount/', data)
  return response
}
export {
  createIntent,
  mealAddToCart,
  updateCartMealQuantity,
  applyCouponCodeToCart,
  addGiftCards,
  addAddress,
  updateProfile,
  uploadProfileImg,
  addReviews,
  changePassowrd,
  addCreditCard,
  createOrder,
  confirmPayment,
  applyCouponCodeAtPayment,
  removeCouponCodeAtPayment,
  sendPhoneOtp,
  verifyEmailOtp,
  verifyPhoneOtp,
  sendEmailOtp,
  completeProfile,
  resetPasswordPost,
  addNewMeal,
  scheduleMeal,
  forCorporate,
  uploadCsv,
  corpResetPassword,
  postOrgUser,
  chefCuisineUpdate,
  fbSignup,
  forgotPassword,
  verifyPassword,
  resetPassword,
  exportAllOrders,
  exportCorporateUsers,
  updateCuisinesProfile,
  resetCorpUserPassword,
  uploadChefImg,
  postContactForm,
  placeVoucherOrder,
  manualPaymentApi
}
