import { HTTP_CLIENT, FORM_DATA_CLIENT } from 'clients/httpClient'
import { getAuthHeaders } from 'constants/getHeader'

async function protectedPost(endpoint, params = {}) {
  const response = await HTTP_CLIENT.post(endpoint, params, {
    headers: getAuthHeaders()
  })

  return response
}

async function publicPost(endpoint, params = {}) {
  const response = await HTTP_CLIENT.post(endpoint, params)

  return response
}

async function protectedFormDataPost(endpoint, params = {}) {
  const response = await FORM_DATA_CLIENT.post(endpoint, params, {
    headers: getAuthHeaders()
  })

  return response
}

export { protectedPost, publicPost, protectedFormDataPost }
