/*eslint quotes: ["error", "single", { "allowTemplateLiterals": true }]*/
import { protectedFetch, publicFetch } from 'clients/fetchClient'

// Eaters Apis
const getFeaturedChef = async ({ geometry }) => {
  const { data: response } = await publicFetch(`/local-chef/`, geometry)
  return response
}

const getFeaturedMeal = async (geometry) => {
  // privios api
  // const { data: response } = await publicFetch(`/local-meals/`, geometry)
  const { data: response } = await publicFetch(`/meal/featured-meal/`, { market_place_id: geometry })
  return response
}

const getAllMeals = async ({ geometry, filters, sort }) => {
  const { data: response } = await publicFetch(`/local-meals/`, geometry, filters, sort)
  return response
}

const getAllChefs = async ({ geometry, filters, sort, offset, limit }) => {
  const { data: response } = await publicFetch(`/local-chef/`, geometry, filters, sort, offset, limit)
  return response
}

const topRatedChefs = async ({ geometry }) => {
  const { data: response } = await publicFetch('/chef/topratedchefs/', geometry)
  return response
}

const getPopularMeals = async ({ geometry, filters, sort, offset, limit }) => {
  const { data: response } = await publicFetch(`/popular-this-week/`, geometry, filters, sort, offset, limit)
  return response
}

const getSuggestedMeals = async ({ geometry, filters, sort, offset, limit }) => {
  const { data: response } = await protectedFetch(`/meal/suggestedmeals/`, geometry, filters, sort, offset, limit)
  return response
}

const getCuisines = async () => {
  const { data: response } = await publicFetch(`/cuisines/`)
  return response
}

const getCuisinesByMarketPlace = async (id) => {
  const { data: response } = await publicFetch(`/cuisines-list/`, { market_id: id })
  return response
}

const getOrderHistory = async () => {
  const { data: response } = await protectedFetch(`/eater/success_orders/`)
  return response
}

const transformFilters = (apiResource, storeFilters) => {
  const filterMappings = [apiResource].filterMappings
  const filterFormatters = [apiResource].filterFormatters
  if (filterMappings || filterFormatters) {
    const updatedFilters = {}
    Object.entries(storeFilters).forEach(([storeKey, storeValue]) => {
      if (storeValue === null || storeValue === undefined) {
        return
      }

      let newStoreKey = filterMappings[storeKey]
      let filterFormatter = filterFormatters ? filterFormatters[storeKey] : undefined

      if (newStoreKey === undefined) {
        newStoreKey = storeKey
      }

      updatedFilters[newStoreKey] = filterFormatter !== undefined ? filterFormatter(storeValue) : storeValue
    })

    return updatedFilters
  }

  return storeFilters
}

const transformSort = (apiResource, storeSort) => {
  const sortMappings = [apiResource].sortMappings

  if (sortMappings) {
    const sortKey = storeSort.key
    return Object.assign({}, storeSort, { key: sortMappings[sortKey] })
  }

  return storeSort
}

const getAllReviews = async (id) => {
  const { data: response } = await publicFetch(`/reviews/`, { chef: id })
  return response
}

const getCreditCards = async () => {
  const { data: response } = await protectedFetch('/account/credit-cards/')
  return response
}

const getSingleMeal = (mealId) => {
  const { data: response } = publicFetch(`/meal/${mealId}/`)
  return response
}

const getCart = async () => {
  const { data: response } = await protectedFetch(`/newcart/`)
  return response
}

const fetchServiceFee = async (state, id) => {
  const { data: response } = await protectedFetch('/fee/', { state: state, eater_address_id: id })
  return response
}

const fetchProfile = async (token) => {
  const { data: response } = await protectedFetch('/accounts/profile/', token)
  return response
}

const fetchAddress = async () => {
  const { data: response } = await protectedFetch('/eater-address/')
  return response
}

const getGiftCards = async () => {
  const { data: response } = await protectedFetch('/gift-card/gift-card-list/')
  return response
}

const getEaterBenefits = async () => {
  const { data: response } = await protectedFetch('/eater-benefit/')
  return response
}

const deleteGiftCards = async (code) => {
  const { data: response } = await protectedFetch(`/gift-card/${code}/gift-card-delete/`)
  return response
}

const getMealDetails = async (mealId) => {
  const { data: response } = await publicFetch(`/meal/${mealId}/meal_details_by_slug/`)
  return response
}

const getChefDetails = async (mealId, market) => {
  const { data: response } = await publicFetch(`/chefs-slug/${mealId}/`, { market_place_id: market })
  return response
}

const getHowDidYouHearAboutUs = async () => {
  const { data: response } = await publicFetch('/hearaboutapi/')
  return response
}

const getChefReviews = async (mealId) => {
  const { data: response } = await publicFetch(`/chef/${mealId}/chefreviews/`)
  return response
}

// meal/99/meal_details/
const getMoreMealByChef = async (id) => {
  const { data: response } = await publicFetch(`/chef/${id}/chefmeals/`)
  return response
}

const getSimillarMeals = async (id, geometry) => {
  const { data: response } = await publicFetch(`/meal/${id}/same-meals/`, geometry)
  return response
}

const getMealReviews = async (id = 1) => {
  const { data: response } = await publicFetch(`/meal/${id}/meal_reviews/`)
  return response
}

const checkMealsAvailableOnAddress = async (id) => {
  const { data: response } = await protectedFetch(`/eater-address/${id}/check-delivery-address/`)
  return response
}

// Organization Api
const getOrgProfile = async () => {
  const { data: response } = await protectedFetch(`/corporate/profile/`)
  return response
}
const getOrgBenefits = async () => {
  const { data: response } = await protectedFetch(`/corporate/my-offers/`)
  return response
}

const fetchMarketPlace = async (value) => {
  const { data: response } = await publicFetch(`/get-market-place/`, value)
  return response
}

const getAllcorpplan = async () => {
  const { data: response } = await protectedFetch(`/corporate-plan/`)
  return response
}

// Chef apis
const getChefAllMeals = async (page, limit) => {
  const { data: response } = await protectedFetch('/chef-dashboard/allmeals/', { page, limit })
  return response
}

const getChefDashboard = async () => {
  const { data: response } = await protectedFetch('/chef-dashboard/')
  return response
}

const getChefOrders = async (page, limit) => {
  const { data: response } = await protectedFetch('/chef-dashboard/chef-orders/', { page, limit })
  return response
}

const getChefPastOrders = async (page, limit) => {
  const { data: response } = await protectedFetch('/chef-dashboard/past-orders/', { page, limit })
  return response
}

const getChefCurrentOrders = async (page, limit) => {
  const { data: response } = await protectedFetch('/chef-dashboard/upcoming-orders/', { page, limit })
  return response
}

const getTotalRevenue = async (value) => {
  const { data: response } = await protectedFetch(`/chef-dashboard/total-revenue/`, value)
  return response
}

const getChefUpcomingMeals = async (page, limit) => {
  const { data: response } = await protectedFetch('/v2/schedulemeal/', { page, limit })
  return response
}
const getChefUpcomingOrders = async (page, limit) => {
  const { data: response } = await protectedFetch('/chef-dashboard/upcoming-orders/', { page, limit })
  return response
}
const getChefMealReview = async (mealId) => {
  const { data: response } = await protectedFetch(`/chef-dashboard/${mealId}/meal-reviews/`)
  return response
}
const getBankConnect = async (data) => {
  const { data: response } = await protectedFetch(`/chef-dashboard/connect-bank${data ? data : '/'}`)
  return response
}
const getChefMealDetails = async (id) => {
  const { data: response } = await protectedFetch(`/v2/meal/${id}/`)
  return response
}

const getAllOrders = async (data) => {
  const { data: response } = await protectedFetch('/all-orders/', data)
  return response
}

const getAllUser = async (page) => {
  const { data: response } = await protectedFetch('/corporate/my-users/', {
    page
  })
  return response
}
const searchUsers = async (value, page) => {
  const { data: response } = await protectedFetch(`/corporate/my-users/`, {
    search: value,
    page
  })
  return response
}
const applyFilter = async (dates, page) => {
  const { data: response } = await protectedFetch(`/corporate/my-users/`, {
    start_date: dates.start_date,
    end_date: dates.end_date,
    page
  })
  return response
}

const getAllCsvUsers = async (status) => {
  const { data: response } = await protectedFetch(`/upload_detail_list/`, {
    status: status
  })
  return response
}
const totalUsers = async () => {
  const { data: response } = await protectedFetch(`/corporate_user_list/`)
  return response
}
const getWalletHistory = async (page = 1) => {
  const { data: response } = await protectedFetch(`/v1/payment/user-wallet-transactions/`, {
    page
  })
  return response
}
const fetchSingleUserWalletHistory = async (id, page = 1) => {
  const { data: response } = await protectedFetch(`/v1/payment/org-user-wallet-transactions/`, { eater_user_id: id, page })
  return response
}
// User Wallet Amount
const getSingleUserWalletAmount = async (id) => {
  const { data: response } = await protectedFetch(`/v1/payment/org-user-balance/`, { eater_user_id: id })
  return response
}
const getOrgWalletAmount = async () => {
  const { data: response } = await protectedFetch(`/v1/payment/org-balance/`)
  return response
}
const getAllCorporateUser = async (data) => {
  const { data: response } = await protectedFetch(`/corporate-admin/`, data)
  return response
}
const getAllorganisation = async () => {
  const { data: response } = await protectedFetch(`/organization-admin/`)
  return response
}
const fetchOrgWalletHistory = async (page = 1) => {
  const { data: response } = await protectedFetch(`/v1/payment/org-wallet-transactions/`, {
    page
  })
  return response
}

const fetchUserWallet = async () => {
  const { data: response } = await protectedFetch('/v1/payment/user-wallet/')
  return response
}

const fetchDeliveryDates = async () => {
  const { data: response } = await protectedFetch('/cart-dates/')
  return response
}

const fetchKitchenAddress = async () => {
  const { data: response } = await protectedFetch('/chef-kitchen/')
  return response
}

const fetchSchedulemeal = async (id) => {
  const { data: response } = await protectedFetch(`/v2/schedulemeal/${id}/`)
  return response
}

const fetchChefCuisines = async () => {
  const { data: response } = await protectedFetch('/chef-cuisines/')
  return response
}

const fetchClientSecret = async () => {
  const { data: response } = await protectedFetch('/v1/payment/setup_intent/')
  return response
}

const isSelect = async () => {
  const { data: response } = await protectedFetch('/user-cuisines/')
  return response
}

const getChefCuisines = async () => {
  const { data: response } = await publicFetch(`/cuisinelistapi/`)
  return response
}

const getCheforderDetail = async (id) => {
  const { data: response } = await protectedFetch(`/chef-dashboard/${id}/order-detail/`)
  return response
}

const getChefBios = async () => {
  const { data: response } = await protectedFetch(`/chef-bio/`)
  return response
}

const getMarketPlaceList = async () => {
  const { data: response } = await protectedFetch('/marketplacelist/')
  return response
}

const getCorporateUserData = async () => {
  const { data: response } = await protectedFetch('/corporate-dinner/')
  return response
}

const fetchPrepaidAmountApi = async (id) => {
  const { data: response } = await protectedFetch('/v1/payment/prepaid-amount/', { 'corporate-id': id })
  return response
}

const fetchCorpUserDetails = async (id) => {
  const { data: response } = await protectedFetch('v1/payment/user-details/', { user_id: id })
  return response
}

const fetchCorpUserDistribute = async (data) => {
  const { data: response } = await protectedFetch('/corporate/my-users/', data)
  return response
}

const fetchCorpUserRewards = async (page) => {
  const { data: response } = await protectedFetch('/rewards/', { page })
  return response
}

export {
  getFeaturedChef,
  getAllMeals,
  getAllChefs,
  getPopularMeals,
  getCuisines,
  transformFilters,
  transformSort,
  getFeaturedMeal,
  getAllReviews,
  getCreditCards,
  getSingleMeal,
  getCart,
  fetchServiceFee,
  getSuggestedMeals,
  getOrderHistory,
  fetchProfile,
  fetchAddress,
  getGiftCards,
  getEaterBenefits,
  deleteGiftCards,
  topRatedChefs,
  getMealDetails,
  getChefDetails,
  getChefReviews,
  getMoreMealByChef,
  getSimillarMeals,
  getMealReviews,
  checkMealsAvailableOnAddress,
  getOrgBenefits,
  getAllUser,
  getOrgProfile,
  searchUsers,
  applyFilter,
  getChefAllMeals,
  getChefDashboard,
  getChefOrders,
  getChefPastOrders,
  getChefCurrentOrders,
  fetchMarketPlace,
  getTotalRevenue,
  getChefUpcomingMeals,
  getChefUpcomingOrders,
  getChefMealReview,
  getBankConnect,
  getChefMealDetails,
  getAllOrders,
  getAllorganisation,
  getAllcorpplan,
  getAllCorporateUser,
  getAllCsvUsers,
  getOrgWalletAmount,
  fetchOrgWalletHistory,
  getSingleUserWalletAmount,
  fetchSingleUserWalletHistory,
  getWalletHistory,
  totalUsers,
  fetchUserWallet,
  fetchDeliveryDates,
  fetchKitchenAddress,
  fetchSchedulemeal,
  fetchChefCuisines,
  fetchClientSecret,
  isSelect,
  getChefCuisines,
  getCheforderDetail,
  getChefBios,
  getCuisinesByMarketPlace,
  getHowDidYouHearAboutUs,
  getMarketPlaceList,
  getCorporateUserData,
  fetchPrepaidAmountApi,
  fetchCorpUserDetails,
  fetchCorpUserDistribute,
  fetchCorpUserRewards
}
