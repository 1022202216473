import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

const StripeWrapper = ({ children }) => {
  return <Elements stripe={stripePromise}>{children}</Elements>
}

export default StripeWrapper
