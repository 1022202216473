import React, { useEffect, useState } from 'react'
import SideBar from './SideBar'
import './CorporateAdminHoc.scss'
import { useDispatch } from 'react-redux'
import { fetchProfileData } from 'actions'
import { logoSvg } from 'constants/images'
import MetaHoc from 'hoc/MetaHoc'

const CorporateAdminHoc = ({ children }) => {
  const dispatch = useDispatch()
  const [showToggle, setShowToggle] = useState(false)
  useEffect(() => {
    dispatch(fetchProfileData())
  }, [])
  return (
    <MetaHoc>
      <article className="tabs-section reports_dashboard">
        <SideBar showToggle={showToggle} setShowToggle={setShowToggle} />
        <div className="relative header mobile-header">
          <a className="site-logo">
            <img src={logoSvg} alt="logo" width="71" height="58" />
          </a>
          <button
            type="button"
            className="toggle-btn"
            onClick={() => {
              setShowToggle(!showToggle)
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        <div className="flex-grow">
          <div className="container fluid content-wrap chef">{children}</div>
        </div>
      </article>
    </MetaHoc>
  )
}

export default React.memo(CorporateAdminHoc)
