import { LOGOUT_HANDLER } from 'actions/account'
import {
  FETCH_CHEF_CUISNES_ERROR,
  FETCH_CHEF_CUISNES_SUCCESS,
  FETCH_CHEF_KITCHEN_START,
  FETCH_CHEF_KITCHEN_FAILURE,
  FETCH_CHEF_KITCHEN_SUCCESS,
  UPDATE_CHEF_MEAL_START,
  UPDATE_CHEF_MEAL_SUCCESS,
  UPDATE_CHEF_MEAL_FAILURE,
  FETCH_CHEF_CUISINES_START,
  FETCH_CHEF_CUISINES_FAILURE,
  FETCH_CHEF_CUISINES_SUCCESS,
  FETCH_CHEF_ORDER_DETAIL_START,
  FETCH_CHEF_ORDER_DETAIL_ERROR,
  FETCH_CHEF_ORDER_DETAIL_SUCCESS,
  UPDATE_CHEF_KITCHEN_START,
  UPDATE_CHEF_KITCHEN_FAILURE,
  UPDATE_CHEF_KITCHEN_SUCCESS,
  FETCH_CHEF_MEALS_ERROR,
  FETCH_CHEF_MEALS_START,
  FETCH_CHEF_MEALS_SUCCESS,
  FETCH_CHEF_DASHBOARD_ERROR,
  FETCH_CHEF_DASHBOARD_START,
  FETCH_CHEF_DASHBOARD_SUCCESS,
  FETCH_CHEF_ORDERS_ERROR,
  FETCH_CHEF_ORDERS_START,
  FETCH_CHEF_ORDERS_SUCCESS,
  FETCH_CHEF_PAST_ORDER_ERROR,
  FETCH_CHEF_PAST_ORDER_START,
  FETCH_CHEF_PAST_ORDER_SUCCESS,
  FETCH_CHEF_PAST_MEALS_ERROR,
  FETCH_CHEF_PAST_MEALS_START,
  FETCH_CHEF_PAST_MEALS_SUCCESS,
  FETCH_CHEF_MEALS_REVIEW_ERROR,
  FETCH_CHEF_MEALS_REVIEW_START,
  FETCH_CHEF_MEALS_REVIEW_SUCCESS,
  FETCH_CHEF_BANK_CONNECT_ERROR,
  FETCH_CHEF_BANK_CONNECT_SUCCESS,
  FETCH_CHEF_BANK_CONNECT_START,
  FETCH_CHEF_UPCOMING_ORDERS_ERROR,
  FETCH_CHEF_UPCOMING_ORDERS_START,
  FETCH_CHEF_UPCOMING_ORDERS_SUCCESS,
  FETCH_CHEF_UPCOMING_MEALS_ERROR,
  FETCH_CHEF_UPCOMING_MEALS_START,
  FETCH_CHEF_UPCOMING_MEALS_SUCCESS,
  UPDATE_CHEF_PROFILE_ERROR,
  UPDATE_CHEF_PROFILE_START,
  UPDATE_CHEF_PROFILE_SUCCESS,
  FETCH_CHEF_MEAL_DETAILS_START,
  FETCH_CHEF_MEAL_DETAILS_SUCCESS,
  FETCH_CHEF_MEAL_DETAILS_ERROR,
  SET_SCHEDULE_EDIT,
  FETCH_CHEF_SCHEDULMEAL_START,
  FETCH_CHEF_SCHEDULMEAL_SUCCESS,
  FETCH_CHEF_SCHEDULMEAL_FAILURE,
  UPDATE_MEAL_SCHEDULE_START,
  UPDATE_MEAL_SCHEDULE_SUCCESS,
  UPDATE_MEAL_SCHEDULE_ERROR,
  GET_CHEF_BIO_START,
  GET_CHEF_BIO_SUCCESS,
  GET_CHEF_BIO_ERROR,
  UPDATE_CHEF_BIO_START,
  UPDATE_CHEF_BIO_SUCCESS,
  UPDATE_CHEF_BIO_ERROR
} from 'actions/chefSection'

const initialState = {
  page: 1,
  nextPage: 1,
  hasNextPage: false,
  mealLoading: false,
  loadMore: false,
  mealError: null,
  allMeals: [],
  totalMeals: null,
  dashboard: null,
  dashboardLoading: false,
  dashboardError: null,
  orders: [],
  ordersLoading: false,
  ordersError: null,
  pastOrders: [],
  pastOrdersLoading: false,
  pastOrdersError: null,
  pastMeals: [],
  pastMealsLoading: false,
  pastMealsError: null,
  bankurl: null,
  bankurlError: null,
  bankUrlLoading: false,
  chefMealReview: null,
  chefMealReviewLoading: false,
  chefMealReviewError: null,
  chefUpcomingOrders: [],
  chefUpcomingOrdersLoading: false,
  chefUpcomingOrdersError: null,
  chefUpcomingMeals: [],
  chefUpcomingMealsLoading: false,
  chefUpcomingMealsError: null,
  scheduleMeal: null,
  updateProfileSuccess: null,
  updateProfileError: null,
  updateProfileLoading: false,
  chefMealDetails: null,
  kitchenAddress: null,
  scheduleMealObj: null,
  chefCuisine: null,
  hasNextOrderPage: false,
  hasNextCurrentOrderPage: false,
  nextPastPage: 1,
  chefCuisineList: null,
  nextPastCurrentPage: 1,
  orderDetailError: null,
  orderDetail: [],
  orderDetailLoading: false,
  getChefLoading: false,
  chefBio: ''
}

export default function kitchen(state = initialState, action) {
  switch (action.type) {
    case FETCH_CHEF_CUISINES_START:
    case FETCH_CHEF_SCHEDULMEAL_START:
    case UPDATE_CHEF_MEAL_START:
    case UPDATE_CHEF_KITCHEN_START:
    case FETCH_CHEF_KITCHEN_START:
    case FETCH_CHEF_MEAL_DETAILS_START:
      return {
        ...state,
        mealLoading: true
      }
    case FETCH_CHEF_CUISINES_SUCCESS:
      return {
        ...state,
        mealLoading: false,
        chefCuisine: action.response
      }
    case FETCH_CHEF_CUISINES_FAILURE:
    case UPDATE_CHEF_MEAL_FAILURE:
    case UPDATE_MEAL_SCHEDULE_ERROR:
    case UPDATE_CHEF_KITCHEN_FAILURE:
    case FETCH_CHEF_KITCHEN_FAILURE:
    case FETCH_CHEF_MEAL_DETAILS_ERROR:
      return {
        ...state,
        mealLoading: false,
        mealError: action.errors
      }
    case UPDATE_CHEF_MEAL_SUCCESS:
    case UPDATE_MEAL_SCHEDULE_START:
    case UPDATE_MEAL_SCHEDULE_SUCCESS:
      return {
        ...state,
        mealLoading: false
      }
    case FETCH_CHEF_SCHEDULMEAL_SUCCESS:
      return {
        ...state,
        mealLoading: false,
        scheduleMealObj: action.response
      }
    case FETCH_CHEF_SCHEDULMEAL_FAILURE:
      return {
        ...state,
        mealLoading: false,
        mealError: action.errors || 'not found',
        scheduleMealObj: null
      }
    case FETCH_CHEF_KITCHEN_SUCCESS:
    case UPDATE_CHEF_KITCHEN_SUCCESS:
      return {
        ...state,
        mealLoading: false,
        kitchenAddress: action.response
      }
    case FETCH_CHEF_MEALS_START:
      return {
        ...state,
        mealLoading: action.page === 1 ? true : false,
        loadMore: action.page > 1 ? true : false
      }
    case FETCH_CHEF_MEALS_SUCCESS:
      return {
        ...state,
        allMeals: action.page === 1 ? action.response.results : [...state.allMeals, ...action.response.results],
        totalMeals: action.response.count,
        mealLoading: false,
        loadMore: false,
        hasNextPage: action.response.next ? true : false,
        nextPage: action.page + 1
      }
    case FETCH_CHEF_MEALS_ERROR:
      return {
        ...state,
        mealLoading: false,
        loadMore: false,
        mealError: action.errors
      }
    case FETCH_CHEF_DASHBOARD_START:
      return {
        ...state,
        dashboardLoading: true
      }
    case FETCH_CHEF_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardLoading: false,
        dashboard: action.response
      }
    case FETCH_CHEF_DASHBOARD_ERROR:
      return {
        ...state,
        dashboardLoading: false,
        dashboardError: action.errors
      }
    case FETCH_CHEF_ORDERS_START:
      return {
        ...state,
        ordersLoading: action.page === 1 ? true : false,
        loadMore: action.page > 1 ? true : false
      }
    case FETCH_CHEF_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.page === 1 ? action.response.results : [...state.orders, ...action.response.results],
        ordersLoading: false,
        loadMore: false,
        hasNextPage: action.response.next ? true : false,
        nextPage: action.page + 1
      }
    case FETCH_CHEF_ORDERS_ERROR:
      return {
        ...state,
        ordersLoading: false,
        loadMore: false,
        ordersError: action.errors
      }
    case FETCH_CHEF_PAST_ORDER_START:
      return {
        ...state,
        pastOrdersLoading: true
      }
    case FETCH_CHEF_PAST_ORDER_SUCCESS:
      return {
        ...state,
        pastOrdersLoading: false,
        pastOrders: action.page === 1 ? action.response.results : [...state.pastOrders, ...action.response.results],
        hasNextOrderPage: action.response.next ? true : false,
        nextPastPage: action.page + 1
      }
    case FETCH_CHEF_PAST_ORDER_ERROR:
      return {
        ...state,
        pastOrdersLoading: false,
        pastOrdersError: action.errors
      }
    case FETCH_CHEF_PAST_MEALS_START:
      return {
        ...state,
        pastMealsLoading: true
      }
    case FETCH_CHEF_PAST_MEALS_SUCCESS:
      return {
        ...state,
        pastMealsLoading: false,
        pastMeals: action.page === 1 ? action.response.results : [...state.pastMeals, ...action.response.results],
        hasNextCurrentOrderPage: action.response.next ? true : false,
        nextPastCurrentPage: action.page + 1
      }
    case FETCH_CHEF_PAST_MEALS_ERROR:
      return {
        ...state,
        pastMealsLoading: false,
        pastMealsError: action.errors
      }

    case FETCH_CHEF_ORDER_DETAIL_START:
      return {
        ...state,
        orderDetailLoading: true
      }
    case FETCH_CHEF_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        orderDetailLoading: false,
        orderDetail: action.response
      }
    case FETCH_CHEF_ORDER_DETAIL_ERROR:
      return {
        ...state,
        orderDetailLoading: false,
        orderDetailError: action.errors
      }
    case FETCH_CHEF_BANK_CONNECT_START:
      return {
        ...state,
        bankUrlLoading: true
      }
    case FETCH_CHEF_BANK_CONNECT_ERROR:
      return {
        ...state,
        bankurlError: action.errors,
        bankurl: null,
        bankUrlLoading: false
      }
    case FETCH_CHEF_BANK_CONNECT_SUCCESS:
      return {
        ...state,
        bankurl: action.response,
        bankurlError: null,
        bankUrlLoading: false
      }
    case FETCH_CHEF_MEALS_REVIEW_ERROR:
      return { ...state, chefMealReviewError: action.errors, chefMealReview: null, chefMealReviewLoading: false }
    case FETCH_CHEF_MEALS_REVIEW_START:
      return { ...state, chefMealReviewError: null, chefMealReview: null, chefMealReviewLoading: true }
    case FETCH_CHEF_MEALS_REVIEW_SUCCESS:
      return { ...state, chefMealReviewError: null, chefMealReview: action.response, chefMealReviewLoading: false }
    case FETCH_CHEF_UPCOMING_ORDERS_ERROR:
      return { ...state, chefUpcomingOrdersError: action.errors, chefUpcomingOrders: null, chefUpcomingOrdersLoading: false }
    case FETCH_CHEF_UPCOMING_ORDERS_START:
      return {
        ...state,
        chefUpcomingOrdersLoading: action.page === 1 ? true : false,
        loadMore: action.page > 1 ? true : false
      }
    case FETCH_CHEF_UPCOMING_ORDERS_SUCCESS:
      return {
        ...state,
        chefUpcomingOrders: action.page === 1 ? action.response.results : [...state.allMeals, ...action.response.results],
        chefUpcomingOrdersLoading: false,
        loadMore: false,
        hasNextPage: action.response.next ? true : false,
        nextPage: action.page + 1
      }
    case FETCH_CHEF_UPCOMING_MEALS_ERROR:
      return {
        ...state,
        chefUpcomingMealsLoading: false,
        loadMore: false,
        chefUpcomingMealsError: action.errors
      }
    case FETCH_CHEF_UPCOMING_MEALS_START:
      return {
        ...state,
        chefUpcomingMealsLoading: action.page === 1 ? true : false,
        loadMore: action.page > 1 ? true : false
      }
    case FETCH_CHEF_UPCOMING_MEALS_SUCCESS:
      return {
        ...state,
        chefUpcomingMeals: action.page === 1 ? action.response : [...state.chefUpcomingMeals, ...action.response.results],
        chefUpcomingMealsLoading: false,
        loadMore: false,
        hasNextPage: action.response.next ? true : false,
        nextPage: action.page + 1
      }
    case SET_SCHEDULE_EDIT:
      return {
        ...state,
        scheduleMeal: action.data
      }
    case UPDATE_CHEF_PROFILE_ERROR:
      return { ...state, updateProfileError: action.data, updateProfileLoading: false, updateProfileSuccess: null }
    case UPDATE_CHEF_PROFILE_START:
      return { ...state, updateProfileLoading: true, updateProfileError: null, updateProfileSuccess: null }
    case UPDATE_CHEF_PROFILE_SUCCESS:
      return { ...state, updateProfileSuccess: action.data, updateProfileError: null, updateProfileLoading: false }
    case GET_CHEF_BIO_ERROR:
      return { ...state, chefBio: null, getChefLoading: false }
    case GET_CHEF_BIO_START:
      return { ...state, getChefLoading: true }
    case GET_CHEF_BIO_SUCCESS:
      return { ...state, chefBio: action.data, getChefLoading: false }
    case UPDATE_CHEF_BIO_ERROR:
      return { ...state, chefBio: null, getChefLoading: false }
    case UPDATE_CHEF_BIO_START:
      return { ...state, getChefLoading: true }
    case UPDATE_CHEF_BIO_SUCCESS:
      return { ...state, chefBio: action.data, getChefLoading: false }
    case FETCH_CHEF_MEAL_DETAILS_SUCCESS:
      return {
        ...state,
        mealLoading: false,
        chefMealDetails: action.response.data
      }
    case FETCH_CHEF_CUISNES_ERROR:
      return {
        ...state
      }
    case FETCH_CHEF_CUISNES_SUCCESS:
      return {
        ...state,
        chefCuisineList: action.data
      }
    case LOGOUT_HANDLER: {
      return initialState
    }
    default:
      return state
  }
}
