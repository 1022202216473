import { LOGOUT_HANDLER } from 'actions/account'
import {
  CHEFS_LOADED,
  CLEAR_CHEFS,
  FETCH_CHEFS_COMPLETE,
  LOAD_MORE_CHEFS,
  LOAD_MORE_CHEFS_FAILURE,
  LOAD_MORE_CHEFS_SUCCESS,
  TOP_RATED_CHEFS_ERROR,
  TOP_RATED_CHEFS_SUCCESS
} from 'actions/mealAndChefData'

const initial = {
  nearby: [],
  all: [],
  nextNearby: null,
  fetchingChefs: false,
  nextpage: null,
  nextPageLoading: false,
  chefError: null,
  topRated: []
}

export default function chefs(state = initial, action) {
  switch (action.type) {
    case FETCH_CHEFS_COMPLETE:
      return Object.assign({}, state, {
        nearby: action.chefs.data?.hits?.hits,
        nextpage: action.chefs.data?.hits?.total.value,
        fetchingChefs: false
      })
    case CHEFS_LOADED:
      return Object.assign({}, state, {
        fetchingChefs: action.fetching
      })
    case CLEAR_CHEFS:
      return Object.assign({}, state, {
        fetchingChefs: action.fetching,
        nearby: [],
        all: [],
        nextNearby: null
      })
    case LOAD_MORE_CHEFS:
      return Object.assign({}, state, {
        nextPageLoading: true
      })
    case LOAD_MORE_CHEFS_SUCCESS:
      return Object.assign({}, state, {
        nextPageLoading: false,
        nearby: [...state.nearby, ...action.data.data.hits.hits]
      })
    case LOAD_MORE_CHEFS_FAILURE:
      return Object.assign({}, state, {
        nextPageLoading: false,
        chefError: 'No More Chefs'
      })
    case TOP_RATED_CHEFS_SUCCESS:
      return Object.assign({}, state, {
        topRated: action.data?.results
      })
    case TOP_RATED_CHEFS_ERROR:
      return Object.assign({}, state, {
        topRated: []
      })
    case LOGOUT_HANDLER: {
      return initial
    }
    default:
      return state
  }
}
