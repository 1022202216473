import {
  SET_GEOMETRY,
  FETCH_MARKETPLACE_START,
  FETCH_MARKETPLACE_FAILURE,
  FETCH_MARKETPLACE_SUCCESS,
  ADD_MESSAGE,
  REMOVE_MESSAGE,
  ALLOWED_LOCATION,
  NOT_ALLOWED_LOCATION
} from 'actions/geometry'
const cords = JSON.parse(window.localStorage.getItem('__dindin__coords__'))
const initial = {
  latitude: cords?.latitude || null,
  longitude: cords?.longitude || null,
  address: cords?.address || null,
  zipcode: cords?.zipcode || null,
  marketplace: [],
  marketplaceLoading: false,
  marketplaceError: null,
  locationAllowd: false,
  locationState: false
}
export default function geometry(state = initial, action) {
  switch (action.type) {
    case SET_GEOMETRY:
      return {
        ...state,
        latitude: action.latitude,
        longitude: action.longitude,
        address: action.address,
        zipcode: action.zipcode || null
      }
    case FETCH_MARKETPLACE_START:
      return Object.assign({}, state, {
        marketplaceLoading: true
      })
    case FETCH_MARKETPLACE_SUCCESS:
      return Object.assign({}, state, {
        marketplace: action.response,
        marketplaceLoading: false
      })
    case FETCH_MARKETPLACE_FAILURE:
      return Object.assign({}, state, {
        marketplaceError: action.error,
        marketplaceLoading: false
      })
    case ADD_MESSAGE:
      if (state.locationAllowd) {
        return Object.assign({}, state, {
          locationState: false
        })
      }
      return Object.assign({}, state, {
        locationState: action.payload
      })

    case REMOVE_MESSAGE:
      return Object.assign({}, state, {
        locationState: action.payload
      })

    case ALLOWED_LOCATION:
    case NOT_ALLOWED_LOCATION:
      return Object.assign({}, state, {
        locationAllowd: action.payload
      })

    default:
      return state
  }
}
