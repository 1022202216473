import { createContext, useState, useCallback } from 'react'
import Cookies from 'js-cookie'

export function userContextData() {
  const [isLogged, setIsLogged] = useState(false)
  const login = useCallback(() => {
    setIsLogged(true)
  }, [])

  const logout = useCallback(() => {
    setIsLogged(false)
    Cookies.remove('authtoken')
  }, [])
  return { isLogged, login, logout }
}

export const AuthContext = createContext({ isLogged: false, login: () => {}, logout: () => {} })
