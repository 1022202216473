import { UPDATE_PAGE_CAT } from 'actions/geometry'

const initial = {
  meals: [],
  chefs: []
}

export default function geometry(state = initial, action) {
  switch (action.type) {
    case UPDATE_PAGE_CAT:
      return {
        ...state,
        meals: action.payload.meals,
        chefs: action.payload.chefs
      }
    default:
      return state
  }
}
