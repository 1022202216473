import React from 'react'
import '../css/loader.css'

const Loader = ({ classs = 'loader' }) => {
  return (
    <div className={classs} data-testid="loader">
      <div className="innerloader">
        <div></div>
      </div>
    </div>
  )
}


export default Loader
