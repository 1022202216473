import { accountType } from 'constants/appConstants'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const SwitchModel = ({ switchAccountHandler }) => {
  const navigate = useNavigate()
  const confirmSwitch = () => {
    localStorage.setItem('account_type', accountType() === 'organization' ? 'dinner' : 'organization')
    switchAccountHandler()
    navigate('/account')
    // Will be add the reload if profile data will not update successfully
    // navigate(0)
  }

  return (
    <div className="modal-dd-center dd-modal switch-user">
      <h3 className="font-bold">Switch to {accountType() === 'organization' ? 'Diner' : 'Organization'} Account.</h3>
      <p>Are you sure you want to switch to the {accountType() === 'organization' ? 'Diner' : 'Organization'} account?</p>
      <div className="pt-3">
        <div className="flex justify-between">
          <button className="dd-button-cta text-btn" onClick={switchAccountHandler}>
            Cancel
          </button>
          <button className="dd-button-cta dd-button-cta--primary-alt" onClick={confirmSwitch}>
            Yes
          </button>
        </div>
      </div>
    </div>
  )
}

export default SwitchModel
