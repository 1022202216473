import { fetchCorpUserDetails, fetchCorpUserDistribute, fetchCorpUserRewards, fetchPrepaidAmountApi } from 'hooks/services/fetchServices'

export const FETCH_PREPAID_AMOUNT = 'FETCH_PREPAID_AMOUNT'
export const FETCH_PREPAID_AMOUNT_SUCCESS = 'FETCH_PREPAID_AMOUNT_SUCCESS'
export const FETCH_PREPAID_AMOUNT_FAILURE = 'FETCH_PREPAID_AMOUNT_FAILURE'
export function fetchPrepaidAmount(data) {
  return (dispatch) => {
    dispatch({ type: FETCH_PREPAID_AMOUNT, fetching: true })
    fetchPrepaidAmountApi(data)
      .then((res) => {
        dispatch({ type: FETCH_PREPAID_AMOUNT_SUCCESS, data: res, fetching: false })
      })
      .catch((error) => {
        dispatch({ type: FETCH_PREPAID_AMOUNT_FAILURE, data: error, fetching: false })
      })
  }
}

export const MANUAL_ORDER_SUMMARY = 'MANUAL_ORDER_SUMMARY'
export function manualOrderSummary(data) {
  return (dispatch) => {
    try {
      dispatch({ type: MANUAL_ORDER_SUMMARY, data: data })
    } catch (error) {
      console.log(error)
    }
  }
}

export const FETCH_CORP_USER = 'FETCH_CORP_USER'
export const FETCH_CORP_USER_SUCCESS = 'FETCH_CORP_USER_SUCCESS'
export const FETCH_CORP_USER_FAILURE = 'FETCH_CORP_USER_FAILURE'
export function fetchCorpUser(data) {
  return (dispatch) => {
    dispatch({ type: FETCH_CORP_USER, fetching: true })
    fetchCorpUserDetails(data)
      .then((res) => {
        dispatch({ type: FETCH_CORP_USER_SUCCESS, data: res, fetching: false })
      })
      .catch((error) => {
        dispatch({ type: FETCH_CORP_USER_FAILURE, data: error, fetching: false })
      })
  }
}

export const FETCH_CORP_USER_DISTRIBUTE = 'FETCH_CORP_USER_DISTRIBUTE'
export const FETCH_CORP_USER_DISTRIBUTE_SUCCESS = 'FETCH_CORP_USER_DISTRIBUTE_SUCCESS'
export const FETCH_CORP_USER_DISTRIBUTE_FAILURE = 'FETCH_CORP_USER_DISTRIBUTE_FAILURE'
export function getCorpUserDistribute(data, page) {
  return (dispatch) => {
    dispatch({ type: FETCH_CORP_USER_DISTRIBUTE, fetching: page == 1 ? true : false, loadingMoreEmp: page > 1 ? true : false })
    fetchCorpUserDistribute(data)
      .then((res) => {
        dispatch({
          type: FETCH_CORP_USER_DISTRIBUTE_SUCCESS,
          fetching: false,
          list: res.results,
          total: res.count,
          next: res.next,
          loadingMoreEmp: false,
          page: page
        })
      })
      .catch((error) => {
        dispatch({ type: FETCH_CORP_USER_DISTRIBUTE_FAILURE, data: error, fetching: false, loadingMoreEmp: false })
      })
  }
}

export const FETCH_USER_REWARDS = 'FETCH_USER_REWARDS'
export const FETCH_USER_REWARDS_SUCCESS = 'FETCH_USER_REWARDS_SUCCESS'
export const FETCH_USER_REWARDS_FAILURE = 'FETCH_USER_REWARDS_FAILURE'
export function fetchUserRewards(page) {
  return (dispatch) => {
    dispatch({ type: FETCH_USER_REWARDS, fetching: page == 1 ? true : false, loadingMoreRewards: page > 1 ? true : false })
    fetchCorpUserRewards(page)
      .then((res) => {
        dispatch({
          type: FETCH_USER_REWARDS_SUCCESS,
          data: res.results,
          fetching: false,
          total: res.count,
          next: res.next,
          page: page,
          loadingMoreRewards: false
        })
      })
      .catch((error) => {
        dispatch({ type: FETCH_USER_REWARDS_FAILURE, data: error, fetching: false, loadingMoreRewards: false })
      })
  }
}
