import React, { useState, useEffect } from 'react'
import { removeProfileData } from 'actions'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { dropAero, logoSvg } from 'constants/images'
import LogoutModel from 'components/Shared/LogoutModel'
import Modal from 'react-modal'
import { logoutStateHandle } from 'actions/account'

const SideBar = ({ showToggle, setShowToggle }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const profile = useSelector((state) => state.profile.profile)
  const [logout, setLogout] = useState(false)
  const [active, setActive] = useState('')
  const [name, setName] = useState('')

  const logoutHandler = () => {
    dispatch(logoutStateHandle())
    dispatch(removeProfileData())
    localStorage.removeItem('profile_data')
    localStorage.removeItem('dindin_key')
    navigate('/')
  }

  useEffect(() => {
    let activeState = window.location.pathname.split('/').filter((n) => n)[1]
    setActive(activeState)
  }, [active])

  useEffect(() => {
    profile?.first_name && profile?.last_name ? setName(`${profile?.first_name} ${profile?.last_name}`) : setName('Hello Admin')
  }, [profile])
  const logoutModelHandler = () => {
    setLogout(!logout)
  }

  return (
    <>
      <div className={showToggle ? 'tabs show' : 'tabs'}>
        <div className="inner">
          <div>
            <div className="flex justify-between items-start">
              <a className="site-logo" href="#;">
                <img src={logoSvg} alt="logo" width="71" height="58" />
              </a>
              <span
                onClick={() => {
                  setShowToggle(!showToggle)
                }}
                className="close-nav"
              >
                <img src="https://dindin-qa-backend.s3.amazonaws.com/images/icons/ic-close.svg" alt="close modal" width="24" height="24" />
              </span>
            </div>

            <label className={active === 'orders' ? 'active' : ''} onClick={() => setActive('orders')}>
              <NavLink
                onClick={() => {
                  setShowToggle(!showToggle)
                }}
                to={'/corporate-admin/orders'}
              >
                All Orders
              </NavLink>
            </label>

            <label className={active === 'users' ? 'active' : ''} onClick={() => setActive('users')}>
              <NavLink
                to={'/corporate-admin/users'}
                onClick={() => {
                  setShowToggle(!showToggle)
                }}
              >
                Corporate User Report
              </NavLink>
            </label>
          </div>
          <div className="user-info">
            <span className="user-data">
              <img src="https://dindin-qa-backend.s3.amazonaws.com/images/icons/user.svg" width="45" height="45" alt="user" />
              <strong>{name}</strong>

              <span className="drop-aero">
                <img src={dropAero} width="20" height="20" alt="drop aero" />
              </span>
            </span>
            <ul className="submenu">
              <li style={{ display: 'none' }}>
                <Link className="font-semibold" to="/change_password/">
                  Change Password
                </Link>
              </li>
              <li>
                <span className="font-semibold" onClick={logoutModelHandler}>
                  Sign Out
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Modal
        isOpen={logout}
        onRequestClose={() => null}
        shouldCloseOnEsc={false}
        contentLabel="Checkout Complete"
        className="dd-modal modal-dd-center border-2"
        overlayClassName="dd-modal-overlay"
        ariaHideApp={false}
      >
        <LogoutModel logoutModelHandler={logoutModelHandler} logoutHandler={logoutHandler} />
      </Modal>
    </>
  )
}

export default React.memo(SideBar)
