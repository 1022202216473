import axios from 'axios'
import config from 'config'

const tokenCookie = document.cookie
  .split(';')
  .map((e) => e.trim())
  .find((e) => e.indexOf('csrftoken') === 0)
const token = (() => {
  let ct = (tokenCookie + '').split('=')
  if (ct && ct.length) {
    return ct[1]
  }
  return null
})()

const getAuthHeaders = () => {
  const authToken = localStorage.getItem('dindin_key')
  return authToken ? { Authorization: `Token ${authToken}` } : {}
}

function httpClient(hostName) {
  return axios.create({
    baseURL: hostName,
    responseType: 'json',
    headers: Object.assign(
      {
        'Content-Type': 'application/json',
        'X-CSRFToken': token
      },
      getAuthHeaders()
    )
  })
}

function formDataClient(hostName) {
  return axios.create({
    baseURL: hostName,
    headers: Object.assign(
      {
        'Content-Type': 'multipart/form-data',
        'X-CSRFToken': token
      },
      getAuthHeaders()
    )
  })
}

const HTTP_CLIENT = httpClient(config.servicesHostName)
const FORM_DATA_CLIENT = formDataClient(config.servicesHostName)

export { httpClient, HTTP_CLIENT, formDataClient, FORM_DATA_CLIENT }
