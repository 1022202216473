import { LOGOUT_HANDLER } from 'actions/account'
import { DETAIL_REPORT_REMOVE, DETAIL_REPORT_SUCCESS } from '../actions/organization'

const initial = {
  order: null
}

export default function orderReport(state = initial, action) {
  switch (action.type) {
    case DETAIL_REPORT_SUCCESS:
      return {
        ...state,
        order: action.data
      }

    case DETAIL_REPORT_REMOVE:
      return {
        ...state,
        order: null
      }
    case LOGOUT_HANDLER: {
      return initial
    }
    default:
      return {
        ...state
      }
  }
}
