import { validationConst } from 'constants/appConstants'
import { WebText } from 'constants/stringConstants'
import moment from 'moment'
export function validateEmail(email) {
  return validationConst.corpEmailRegex.test(String(email).toLowerCase())
}

export function validateOTP(otp) {
  const re = /^[0-9]{4}$/im
  return re.test(String(otp).toLowerCase())
}

export function validatePhone(phone) {
  const re = /^[0-9]{10}$/im
  return re.test(String(phone).toLowerCase())
}

export function RemoveEmpty(data) {
  for (let key in data) {
    if (!data[key]) {
      delete data[key]
    }
  }
  return data
}

export const bodyscrollHide = () => {
  if (document.body.className !== WebText.modalOpen) {
    document.body.classList.add(WebText.modalOpen)
  } else {
    document.body.classList.remove(WebText.modalOpen)
  }
}

export const transactionType = (type) => {
  switch (type) {
    case 'dollar_transaction':
      return 'Wallet Transaction'
    case 'voucher_transaction':
      return 'Voucher Transaction'
    case 'dollar_gift_up':
      return 'Wallet Gift'
    case 'voucher_gift_up':
      return 'Voucher Gift'
    default:
      return ''
  }
}

export const dateWithMonth = (data) => {
  return moment(data).local().format('DD MMM yyyy')
}

export const todayDate = moment(Date.now()).format('yyyy-MM-DD')
