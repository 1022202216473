import React, { useState } from 'react'
import { barMenu, cartSvg, logoSvg } from '../../constants/images'
import { NavLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import SocialShare from 'components/Shared/SocialShare'
import NavLinks from 'components/Shared/NavLinks'

const PublicRoute = () => {
  const [showMenu, setShowMenu] = useState(false)
  const [showDropmenu, setShowDropmenu] = useState(false)
  const current = window.location.href
  const meal = current.split('/')
  const modelOpen = 'modal-open'
  const handleCart = () => {
    toast.error('You need to login before go to checkout.')
  }
  const setModal = () => {
    if (window.innerWidth < 992) {
      const body = document.querySelector('body')
      if (body.classList.contains(modelOpen)) {
        body.classList.remove(modelOpen)
      } else {
        body.classList.add(modelOpen)
      }
    }
  }
  return (
    <div className="relative header">
      {(meal[3] === 'meals' && meal[4]?.length) || (meal[3] === 'chefs' && meal[4]?.length) ? <></> : <SocialShare />}

      <div className="container sm-navbar">
        <NavLink className="site-logo" to={'/'}>
          <img src={logoSvg} alt="logo" width="71" height="58" />
        </NavLink>
        <nav className="dd-nav dd-nav--lime">
          <div className="flex mobile">
            <NavLink className="dd-nav__tab dd-nav__tab--selected mr-6 relative" to={'/login/'} onClick={handleCart}>
              <img src={cartSvg} alt="cart" width={24} height={24} className="w-6 mx-1" />
            </NavLink>
          </div>
          <button
            type="button"
            className="toggle-btn sss"
            onClick={() => {
              setShowMenu(!showMenu)
              setModal()
            }}
            aria-label="toggle button"
          >
            <img src={barMenu} />
          </button>

          <div
            className={
              showMenu ? 'container flex items-center sm-wp-container-nav pr-0 active' : 'container flex items-center sm-wp-container-nav pr-0'
            }
          >
            <div id="navMenu">
              <button
                type="button"
                className="toggle-btn close"
                onClick={() => {
                  setShowMenu(!showMenu)
                  setModal()
                }}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
              <ul className="flex">
                <NavLinks showDropmenu={showDropmenu} setShowDropmenu={setShowDropmenu} setModal={setModal} />
              </ul>
            </div>
            <div className="flex items-center justify-between" id="navMenu">
              <ul className="flex items-center desktop">
                <li id="navigation-menu">
                  {' '}
                  <div className="flex">
                    <NavLink className="dd-nav__tab dd-nav__tab--selected mr-6 relative" to={'/login/'} onClick={handleCart}>
                      <img src={cartSvg} alt="cart" width={24} height={24} className="w-6 mx-1" />
                    </NavLink>
                  </div>{' '}
                </li>
              </ul>

              <ul className="flex items-center nologin width-100-margin">
                <li className="dd-nav__tab" onClick={setModal}>
                  <NavLink to={'/login'}>Sign In</NavLink>
                </li>
                <li className="dd-nav__tab" onClick={setModal}>
                  <NavLink to={'/select-signup/'} className="dd-button-cta dd-button-cta--primary">
                    Sign Up
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default PublicRoute
