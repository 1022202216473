import React, { useContext, useState } from 'react'
import { logoWhite, defaultUser, logoutSvgWt, changePasswordSvgWt, profileSvgWt, dropAeroWt } from '../../constants/images'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import '../../assets/scss/sideBar.scss'
import SideBarContext from 'components/context/sidebarContext'
import { bodyscrollHide } from 'utils/helper'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-modal'
import LogoutModel from 'components/Shared/LogoutModel'
import { logoutStateHandle } from 'actions/account'

const SideBar = () => {
  const location = useLocation()
  const isActive = (path) => (location.pathname.startsWith(path) ? 'active' : '')
  const [open, setOpen] = useState(false)
  const [logout, setLogout] = useState(false)

  const { openSidebar, setOpenSidebar, setMealBtn } = useContext(SideBarContext)
  const profile = useSelector((state) => state.profile.profile)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const logoutHandler = () => {
    dispatch(logoutStateHandle())
    navigate('/')
  }

  useState(() => {
    isActive('/chef/menu/') ? setMealBtn(false) : setMealBtn(true)
  }, [])

  const logoutModelHandler = () => {
    setLogout(!logout)
  }

  return (
    <>
      <nav className={openSidebar ? 'sidebar show' : 'sidebar'}>
        <div>
          <img src={logoWhite} width="86" height="70" alt="logo" className="main-logo" />
          <span
            className="close-sidebar bar-menu"
            onClick={() => {
              setOpenSidebar(!openSidebar)
              bodyscrollHide()
            }}
          >
            close
          </span>
          <div className="flex user-drop items-center ml-5">
            <span
              className="block truncate"
              onClick={() => {
                setOpen(!open)
              }}
            >
              <img src={defaultUser} alt="user" className="inline-block mr-2 rounded-md w-12 h-12 object-cover object-center" />
              <span className="font-semibold">
                {profile?.first_name} {profile?.last_name}
              </span>
              <span className={`drop-aero ${open ? 'aero-up' : ''}`}>
                <img src={dropAeroWt} width="24" height="24" alt="search" />
              </span>
            </span>

            {open && (
              <div className="flex drop-down flex-col p-4 z-50">
                <div className="hover:text-red-medium">
                  <img src={profileSvgWt} alt="profile" className="pr-2 inline-block" />
                  <NavLink className="font-semibold" to={'/chef/profile'}>
                    My Profile
                  </NavLink>
                </div>
                <div className="hover:text-red-medium">
                  <img src={changePasswordSvgWt} alt="password" className="pr-2 inline-block" />
                  <NavLink className="font-semibold" to={'/chef/change-password'}>
                    Change Password
                  </NavLink>
                </div>
              </div>
            )}
          </div>
          <ul className="menu-list">
            <li>
              <NavLink
                to="/chef/dashboard"
                className={`list-item ${isActive('/chef/dashboard')}`}
                onClick={() => {
                  setMealBtn(true)
                }}
              >
                <span className="NavLink-item dashboard">
                  <span>Dashboard</span>
                </span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/chef/menu"
                className={`list-item ${isActive('/chef/menu')}`}
                onClick={() => {
                  setMealBtn(false)
                }}
              >
                <span className="NavLink-item menu">
                  <span>Menu</span>
                </span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/chef/neworders"
                className={`list-item ${isActive('/chef/neworders')}`}
                onClick={() => {
                  setMealBtn(true)
                }}
              >
                <span className="NavLink-item orders">
                  <span>Orders</span>
                </span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/chef/cooking-schedule"
                className={`list-item ${isActive('/chef/cooking-schedule')}`}
                onClick={() => {
                  setMealBtn(true)
                }}
              >
                {/* <img src={bookmarkSvg} alt="page" /> */}
                <span className="NavLink-item cooking">
                  <span>Cooking Schedule</span>
                </span>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="hover:text-red-medium logout">
          <img src={logoutSvgWt} alt="logout" className="pr-2 inline-block" />
          <span className="font-semibold cursor-pointer" onClick={logoutModelHandler}>
            Sign Out
          </span>
        </div>
      </nav>
      <Modal
        isOpen={logout}
        onRequestClose={() => null}
        shouldCloseOnEsc={false}
        contentLabel="Checkout Complete"
        className="dd-modal modal-dd-center border-2"
        overlayClassName="dd-modal-overlay"
        ariaHideApp={false}
      >
        <LogoutModel logoutModelHandler={logoutModelHandler} logoutHandler={logoutHandler} />
      </Modal>
    </>
  )
}

export default React.memo(SideBar)
