import { getChefDetails } from 'hooks/services/fetchServices'
import { fetchReviews } from './reviews'

export const FETCH_CHEF_DETAILS_START = 'FETCH_CHEF_DETAILS_START'
export const FETCH_CHEF_DETAILS_SUCCESS = 'FETCH_CHEF_DETAILS_SUCCESS'
export const FETCH_CHEF_DETAILS_FAILURE = 'FETCH_CHEF_DETAILS_FAILURE'
export function fetchChefDetails(chefId) {
  const marketPlace = JSON.parse(localStorage.getItem('global_marketplace'))
  const localMarketPlace = JSON.parse(localStorage.getItem('marketPlace'))
  return (dispatch) => {
    dispatch({ type: FETCH_CHEF_DETAILS_START, fetching: true })
    getChefDetails(chefId, localMarketPlace.market_place_id || marketPlace?.[0]?.id)
      .then((response) => {
        dispatch({ type: FETCH_CHEF_DETAILS_SUCCESS, response, fetching: false })
        dispatch(fetchReviews(response.id))
      })

      .catch((error) => {
        const errors = error.response.data
        window.location.href = '/error'
        dispatch({ type: FETCH_CHEF_DETAILS_FAILURE, errors, fetching: false })
      })
  }
}
