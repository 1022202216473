const fbBase = 'https://www.facebook.com/sharer/sharer.php?u='
const twBase = 'http://twitter.com/share'
const fbMealDetail = (url) => {
  let fbUrl = `${fbBase}${url}`
  let firstLine = `I highly recommend that you check out this amazing meal I found on Weekly Meal Prep app called Din Din for meals and items prepared by local chefs in your area.`
  let secondLine = `Use promo code SHARE10 to get 10% off on your first order. 100% of the meal price goes to local chefs!.`
  return `${fbUrl}&quote=${firstLine} ${secondLine}`
}

const twMealDetail = (url) => {
  let firstLine =
    'I highly recommend that you check out this amazing meal I found on Weekly Meal Prep app called Din Din for meals and items prepared by local chefs in your area.'
  let secondLine = `Use promo code SHARE10 to get 10%25 off on your first order. Learn More: &url=${url}&hashtags=DinDin`
  return `${twBase}?text=${firstLine} ${secondLine}`
}

const fbWebShare = (url) => {
  let base = `${fbBase}${url}`
  let firstLine = `I highly recommend that you check out this amazing chef I found on the Weekly Meal Prep app called Din Din for meals and items prepared by local chefs in your area and use`
  let secondLine = 'promo code SHARE10 to get 10% off on your first order. 100% of the meal price goes to local chefs!'
  return `${base}&quote=${firstLine} ${secondLine}`
}

const twWebShare = (url) => {
  let firstLine = `I highly recommend that you check out this amazing chef I found on the Weekly Meal Prep app called Din Din for meals and items prepared by local chefs in your area and use`
  let secondLine = `promo code SHARE10 to get 10%25 off on your first order. 100%25 of the meal price goes to local chefs!&url=${url}&hashtags=DinDin`
  return `${twBase}?text=${firstLine} ${secondLine}`
}

const fbChefDetails = (url) => {
  let fbUrl = `${fbBase}${url}`
  let firstLine = `I highly recommend that you check out this amazing chef I found on the Weekly Meal Prep app called Din Din for meals and items prepared by local chefs in your area and use`
  let secondLine = `promo code SHARE10 to get 10% off on your first order. 100% of the meal price goes to local chefs!`
  return `${fbUrl}&quote=${firstLine} ${secondLine}`
}
const twChefDetails = (url) => {
  let firstLine = `I highly recommend that you check out this amazing chef I found on the Weekly Meal Prep app called Din Din for meals and items prepared by local chefs in your area and use promo code SHARE10 to get`
  let secondLine = `10%25 off on your first order. 100%25 of the meal price goes to local chefs! Visit &url=${url}&hashtags=DinDin`
  return `${twBase}?text=${firstLine} ${secondLine}`
}

const mailChefDetails = (url, chefData) => {
  let firstLine = `mailto:?subject=My friend check out this amazing Chef I found on the Din Din platform on the Weekly Meal Prep app called 'Din Din'`
  let lastLine = `%0D%0ALearn More: ${url}.Use promo code SHARE10 to get 10% off on your first order. 100% of the meal price goes to local chefs!%0D%0A${chefData?.excerpt}`
  return `${firstLine}&body=${chefData?.full_name} | ${chefData?.kitchen.city} - ${chefData?.kitchen.state}.${lastLine}`
}

const mailMealDetails = (url, allDetails) => {
  let firstLine = `mailto:?subject=My Friend check out this amazing meal I found on the Din Din Platform`
  let secondLine = `${allDetails?.name?.split('-').join(' ')}} by ${allDetails?.chef?.full_name}`
  let lastLine = `${url}%0D%0AUse promo code SHARE10 to get 10% off on your first order. 100% of the meal price goes to local chefs!  %0D%0A${allDetails?.description}`
  return `${firstLine}&body=${secondLine}%0D%0ALearn More: ${lastLine}`
}

const mailWebDetails = (currentPageUrl) => {
  let firstLine = `mailto:?subject=Hey check out this unique Weekly Meal Prep app called 'Din Din'`
  let secondLine = `I highly recommend that you check out this Weekly Meal Prep app called Din Din for meals and items prepared by local chefs in your area.`
  let lastLine = `and use promo code SHARE10 to get 10% off your first order. 100% of the meal price goes to local chefs!`
  return `${firstLine}&body=${secondLine} Visit ${currentPageUrl} ${lastLine}`
}

export { fbMealDetail, twMealDetail, fbWebShare, twWebShare, fbChefDetails, twChefDetails, mailChefDetails, mailMealDetails, mailWebDetails }
