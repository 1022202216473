import { GET_MARKET_PLACE, GET_MARKET_PLACE_FAILURE, GET_MARKET_PLACE_SUCCESS, SET_GLOBAL_MARKET_PLACE } from 'actions/mealAndChefData'

const initial = {
  marketPlace: null,
  data: null,
  loading: false
}

export default function marketplace(state = initial, action) {
  switch (action.type) {
    case GET_MARKET_PLACE:
      return {
        ...state,
        marketPlace: null
      }
    case GET_MARKET_PLACE_SUCCESS:
      return {
        ...state,
        marketPlace: action.response.market_place_id || null,
        data: action.response.data
      }
    case GET_MARKET_PLACE_FAILURE:
      return {
        ...state,
        loading: false,
        marketPlaceError: action.error,
        data: null
      }
    case SET_GLOBAL_MARKET_PLACE:
      return {
        ...state,
        marketPlace: action.globalMarketPlace?.[0]?.id
      }
    default:
      return state
  }
}
