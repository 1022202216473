import { deleteCartItem } from 'hooks/services/deleteService'
import {
  fetchClientSecret,
  fetchDeliveryDates,
  fetchServiceFee,
  fetchUserWallet,
  getCart,
  getCreditCards
} from 'hooks/services/fetchServices'
import {
  applyCouponCodeToCart,
  confirmPayment,
  createIntent,
  mealAddToCart,
  updateCartMealQuantity
} from 'hooks/services/postService'
import { addInstruction } from 'hooks/services/putService'
import { toast } from 'react-toastify'

export const CONFIRM_PAYMENT_SUCCESS = 'CONFIRM_PAYMENT_SUCCESS'
export const CONFIRM_PAYMENT_ERROR = 'CONFIRM_PAYMENT_ERROR'
export function confirmPaymentAction(payment) {
  return (dispatch) => {
    confirmPayment(payment)
      .then((res) => {
        dispatch({ type: CONFIRM_PAYMENT_SUCCESS, data: res })
      })
      .catch((error) => {
        toast.error(error.response.data.detail)
        dispatch({
          type: CONFIRM_PAYMENT_ERROR,
          data: error.response.data.detail
        })
        console.log('payment issue', error)
      })
  }
}

export const CLIENT_SECRET_SUCCESS = 'CLIENT_SECRET_SUCCESS'
export const CLIENT_SECRET_ERROR = 'CLIENT_SECRET_ERROR'
export function getClientSecret() {
  return (dispatch) => {
    fetchClientSecret().then((res) => {
      if (res.data === false) {
        dispatch({ type: CLIENT_SECRET_ERROR, data: res })
      }
      dispatch({ type: CLIENT_SECRET_SUCCESS, data: res })
    })
  }
}
export const SET_WALLET_PAYMENT = 'SET_WALLET_PAYMENT'
export function setWalletPayment(amount) {
  return (dispatch) => {
    dispatch({
      type: SET_WALLET_PAYMENT,
      payload: {
        method: 'wallet',
        data: { amount: Number(amount).toFixed(2) }
      }
    })
  }
}

export const SET_VOUCHER_PAYMENT = 'SET_VOUCHER_PAYMENT'
export function setVoucherPayment(amount) {
  return (dispatch) => {
    dispatch({
      type: SET_VOUCHER_PAYMENT,
      payload: {
        method: 'voucher',
        data: {
          quantity: amount
        }
      }
    })
  }
}

export const REMOVE_WALLET_PAYMENT = 'REMOVE_WALLET_PAYMENT'
export function removeWalletPayment() {
  return (dispatch) => {
    dispatch({
      type: REMOVE_WALLET_PAYMENT,
      payload: {}
    })
  }
}

export const REMOVE_VOUCHER_PAYMENT = 'REMOVE_VOUCHER_PAYMENT'
export function removeVoucherPayment() {
  return (dispatch) => {
    dispatch({
      type: REMOVE_VOUCHER_PAYMENT,
      payload: {}
    })
  }
}

export const GET_DELIVERY_DATES_START = 'GET_DELIVERY_DATES_START'
export const GET_DELIVERY_DATES_SUCCESS = 'GET_DELIVERY_DATES_SUCCESS'
export const GET_DELIVERY_DATES_ERROR = 'GET_DELIVERY_DATES_ERROR'
export function getDeliveryDates() {
  return (dispatch) => {
    dispatch({ type: GET_DELIVERY_DATES_START })
    fetchDeliveryDates()
      .then((response) => {
        dispatch({ type: GET_DELIVERY_DATES_SUCCESS, data: response })
      })
      .catch((error) => {
        dispatch({ type: GET_DELIVERY_DATES_ERROR, data: error })
      })
  }
}

export const CART_ITEM_REMOVED = 'CART_ITEM_REMOVED'
export function removeCartItem(mealId) {
  return (dispatch) => {
    deleteCartItem(mealId)
      .then((cart) => {
        let voucherMeal = cart.meals.filter(
          (meal) => meal.is_voucher_meal === true
        )
        let localMeal = cart.meals.filter(
          (meal) => meal.is_voucher_meal === false
        )
        dispatch({
          type: CART_ITEM_REMOVED,
          cart,
          voucherMeal,
          localMeal
        })
      })
      .then(() => {
        dispatch(fetchCart())
        dispatch(getDeliveryDates())
      })
      .catch((error) => console.log('eeeee', error))
  }
}
export const FETCH_CART_START = 'FETCH_CART_START'
export const FETCH_CART_COMPLETE = 'FETCH_CART_COMPLETE'
export function fetchCart() {
  return (dispatch) => {
    dispatch({
      type: FETCH_CART_START
    })

    getCart()
      .then((cart) => {
        let voucherMeal = cart.meals.filter(
          (meal) => meal.is_voucher_meal === true
        )
        let localMeal = cart.meals.filter(
          (meal) => meal.is_voucher_meal === false
        )
        dispatch({
          type: FETCH_CART_COMPLETE,
          voucherMeal,
          localMeal,
          cart
        })
      })
      .finally(() => dispatch({ type: 'MINI_LOADER_UPDATE', data: 0 }))
  }
}
export const CART_QUANTITY_UPDATE = 'CART_QUANTITY_UPDATE'
export const MINI_LOADER_UPDATE = 'MINI_LOADER_UPDATE'
export function updateCartQuantity(mealId, change_type, type = 'PAID') {
  return (dispatch) => {
    updateCartMealQuantity(mealId, change_type, type)
      .then((cart) => {
        if (cart && Array.isArray(cart.meals)) {
          dispatch({
            type: CART_QUANTITY_UPDATE,
            cart
          })
        }
      })
      .then(() => dispatch(fetchCart()))
  }
}

export const ADD_SPECIAL_REQUEST = 'ADD_SPECIAL_REQUEST'
export function addSpecialRequest(id, msg) {
  return (dispatch) => {
    addInstruction(id, msg).then(() => {
      dispatch(fetchCart())
    })
  }
}

export const CLEAR_CART = 'CLEAR_CART'
export function removeCartState(cart) {
  return (dispatch) => {
    cart.meals.forEach((meal) => {
      deleteCartItem(meal.meal.guid)
        .then((cartResponse) => {
          dispatch({
            type: CLEAR_CART,
            cart,
            cartResponse
          })
        })
        .then(() => {
          dispatch({
            type: FETCH_CART_START
          })
        })
    })
  }
}

export const MEAL_DETAILS = 'MEAL_DETAILS'
export const mealDetails = () => {
  return (dispatch) => {
    dispatch({
      type: MEAL_DETAILS
    })
  }
}
export const EMPTY_CART = 'EMPTY_CART'
export function emptyCart() {
  return (dispatch) => {
    dispatch({
      type: EMPTY_CART
    })
  }
}

export const CART_ITEM_UPDATED = 'CART_ITEM_UPDATED'
export function updateCartItem(cartItem, { newQuantity }) {
  return (dispatch) => {
    dispatch({
      type: CART_ITEM_UPDATED,
      cartItem,
      newQuantity
    })
  }
}

export const CART_ITEM_ADD = 'CART_ITEM_ADD'
export const CART_ITEM_ERROR = 'CART_ITEM_ERROR'
export function addToCart(cartItem, voucher) {
  let data = {}
  if (voucher) {
    data = { meal_id: cartItem, is_voucher: voucher }
  } else {
    data = { meal_id: cartItem }
  }

  return (dispatch) => {
    mealAddToCart(data)
      .then((cartResponse) => {
        if (cartResponse && cartResponse.length > 0) {
          toast.error(cartResponse[0])
        } else {
          let voucherMeal = cartResponse.meals.filter(
            (meal) => meal.is_voucher_meal === true
          )
          let localMeal = cartResponse.meals.filter(
            (meal) => meal.is_voucher_meal === false
          )
          dispatch({
            type: CART_ITEM_ADD,
            cartItem,
            cartResponse,
            voucherMeal,
            localMeal
          })
          !cartResponse.error &&
            toast.success('Item has been added to the cart successfully')
        }
      })
      .catch((error) => {
        dispatch({
          type: CART_ITEM_ERROR,
          error: error.response.data
        })
        toast.error(error.response.data[0])
      })
  }
}

export const APPLY_COUPON_CODE = 'APPLY_COUPON_CODE'
export const APPLY_COUPON_CODE_SUCCESS = 'APPLY_COUPON_CODE_SUCCESS'
export const APPLY_COUPON_CODE_FAILURE = 'APPLY_COUPON_CODE_FAILURE'
export function applyCouponCode(code) {
  return (dispatch) => {
    dispatch({ type: APPLY_COUPON_CODE })
    applyCouponCodeToCart(code)
      .then((cartResponse) => {
        dispatch({ type: APPLY_COUPON_CODE_SUCCESS, data: cartResponse })
        if (cartResponse.is_valid)
          dispatch(
            setOrderField({
              orderPart: 'couponData',
              fieldName: null,
              value: cartResponse.data
            })
          )
      })
      .catch((error) => {
        dispatch({
          type: APPLY_COUPON_CODE_FAILURE,
          data: error.response.data.error.coupon_code
        })
      })
  }
}

export const APPLY_SERVICE_FEE = 'APPLY_SERVICE_FEE'
export const APPLY_SERVICE_FEE_SUCCESS = 'APPLY_SERVICE_FEE_SUCCESS'
export const APPLY_SERVICE_FEE_FAILURE = 'APPLY_SERVICE_FEE_FAILURE'
export function getServiceFee(state, id) {
  return (dispatch) => {
    dispatch({ type: APPLY_SERVICE_FEE })
    fetchServiceFee(state, id)
      .then((response) => {
        dispatch({ type: APPLY_SERVICE_FEE_SUCCESS, data: response })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: APPLY_SERVICE_FEE_FAILURE, data: errors })
      })
  }
}

export const CALCULATE_PRICING_CART = 'CALCULATE_PRICING_CART'
export function calculatePricingCart() {
  return (dispatch) => {
    dispatch({ type: CALCULATE_PRICING_CART })
  }
}

export const SET_ORDER_FIELD = 'SET_ORDER_FIELD'
export function setOrderField({ orderPart, fieldName, value }) {
  return (dispatch) => {
    dispatch({
      type: SET_ORDER_FIELD,
      orderPart,
      fieldName,
      value
    })
  }
}

export const USER_WALLET_START = 'USER_WALLET_START'
export const USER_WALLET_SUCCESS = 'USER_WALLET_SUCCESS'
export const USER_WALLET_ERROR = 'USER_WALLET_ERROR'
export function getUserWallet() {
  return (dispatch) => {
    dispatch({ type: USER_WALLET_START })
    fetchUserWallet()
      .then((response) => {
        dispatch({
          type: USER_WALLET_SUCCESS,
          data: response,
          amount: response[0].amount + response[0].gift_dollar
        })
      })
      .catch((error) => {
        dispatch({ type: USER_WALLET_ERROR, data: error })
      })
  }
}
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD'
export function setPaymentMethodField({ method, card }) {
  return (dispatch) => {
    dispatch({
      type: SET_PAYMENT_METHOD,
      payload: {
        method: method,
        data: {
          card: card
        }
      }
    })
  }
}

export const REMOVE_PAYMENT_METHOD = 'REMOVE_PAYMENT_METHOD'
export function removePaymentMethod() {
  return (dispatch) => {
    dispatch({
      type: REMOVE_PAYMENT_METHOD
    })
  }
}

export const PAYMENT_INTENT_UPDATED = 'PAYMENT_INTENT_UPDATED'
export function updatePaymentIntent() {
  return (dispatch, getState) => {
    const { order } = getState()
    if (order.paymentMethod.id || order.couponData || order.card) {
      createIntent(order)
        .then((paymentIntent) =>
          dispatch({
            type: PAYMENT_INTENT_UPDATED,
            paymentIntentId: paymentIntent.data.id,
            clientSecret: paymentIntent.data.client_secret,
            cartTransaction: paymentIntent.data.cart_transaction
          })
        )
        .catch((error) => console.log('dddd', error))
    }
  }
}

export const REMOVE_CART_DATA = 'REMOVE_CART_DATA'
export function removeCartData() {
  return (dispatch) => {
    dispatch({
      type: REMOVE_CART_DATA
    })
  }
}

export const FETCH_CARDS_START = 'FETCH_CARDS_START'
export const FETCH_CARDS_SUCCESS = 'FETCH_CARDS_SUCCESS'
export const FETCH_CARDS_FAILURE = 'FETCH_CARDS_FAILURE'
export function fetchCards() {
  return (dispatch) => {
    dispatch({ type: FETCH_CARDS_START, fetching: true })
    getCreditCards()
      .then((response) => {
        dispatch({ type: FETCH_CARDS_SUCCESS, response, fetching: false })
      })
      .catch((error) => {
        const errors = error.response.data
        dispatch({ type: FETCH_CARDS_FAILURE, errors, fetching: false })
      })
  }
}

export const POST_CARDS_SUCCESS = 'POST_CARDS_SUCCESS'
export function postCard(card) {
  return (dispatch) => {
    dispatch({ type: POST_CARDS_SUCCESS, card })
  }
}

export const SET_DELIVERY_ADDRESS = 'SET_DELIVERY_ADDRESS'
export function setDeliveryAddress({
  id,
  address,
  title,
  contactPerson,
  contactPersonNumber,
  city,
  state,
  postalCode,
  extraInstructions,
  preferred
}) {
  return (dispatch) => {
    dispatch({
      type: SET_DELIVERY_ADDRESS,
      id,
      address,
      title,
      contactPerson,
      contactPersonNumber,
      city,
      state,
      postalCode,
      extraInstructions,
      preferred
    })
  }
}

export const FETCH_PAYMENT_METHODS_START = 'FETCH_PAYMENT_METHODS'
export const FETCH_PAYMENT_METHODS_COMPLETE = 'FETCH_PAYMENT_METHODS_COMPLETE'
export function fetchPaymentMethods() {
  return (dispatch) => {
    dispatch({
      type: FETCH_PAYMENT_METHODS_START
    })

    const apiPromise = getCreditCards()
    apiPromise.then((paymentMethods) => {
      dispatch({
        type: FETCH_PAYMENT_METHODS_COMPLETE,
        paymentMethods
      })
    })
  }
}

export const PAYMENT_METHOD_ADDED = 'PAYMENT_METHOD_ADDED'
export function addPaymentMethod(card) {
  return {
    type: PAYMENT_METHOD_ADDED,
    card
  }
}
