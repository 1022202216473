import { homepageMeta } from 'constants/metaTags'
import React from 'react'
import { Helmet } from 'react-helmet-async'

const MetaHoc = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>Eat Din Din</title>
        <meta name="og:description" content={homepageMeta.description} />
        <link rel="canonical" href={window.location.pathname} />
      </Helmet>
      {children}
    </>
  )
}

export default MetaHoc
