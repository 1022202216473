import {
  FETCH_PROFILE_DATA_START,
  FETCH_PROFILE_DATA_SUCCESS,
  FETCH_PROFILE_DATA_FAILURE,
  UPDATE_PROFILE_DATA_START,
  UPDATE_PROFILE_DATA_SUCCESS,
  UPDATE_PROFILE_DATA_FAILURE,
  UPDATE_PROFILE_IMAGE_START,
  UPDATE_PROFILE_IMAGE_SUCCESS,
  UPDATE_PROFILE_IMAGE_FAILURE,
  FETCH_USER_ADDRESS_START,
  FETCH_USER_ADDRESS_SUCCESS,
  FETCH_USER_ADDRESS_FAILURE,
  FETCH_EATER_BENEFITS_START,
  FETCH_EATER_BENEFITS_SUCCESS,
  FETCH_EATER_BENEFITS_FAILURE,
  ADD_USER_ADDRESS_FAILURE,
  ADD_USER_ADDRESS_START,
  ADD_USER_ADDRESS_SUCCESS,
  UPDATE_USER_ADDRESS_FAILURE,
  UPDATE_USER_ADDRESS_START,
  UPDATE_USER_ADDRESS_SUCCESS,
  DELETE_USER_ADDRESS_FAILURE,
  DELETE_USER_ADDRESS_START,
  DELETE_USER_ADDRESS_SUCCESS,
  REMOVE_PROFILE_DATA,
  LOGIN_DATA,
  HEAR_ABOUT_US_ERROR,
  HEAR_ABOUT_US_SUCCESS,
  LOGOUT_HANDLER,
  LANDING_PAGE
} from 'actions/account'

const initial = {
  profile: null,
  address: [],
  profileFetching: true,
  profileError: null,
  addressError: null,
  addressFetching: null,
  eaterBenefits: [],
  eaterBenefitsError: null,
  eaterBenefitsFetching: true,
  updateProfileImage: null,
  updateProfileImageError: null,
  updateProfileImageFetching: null,
  updateProfile: null,
  updateProfileError: null,
  loginData: null,
  profileRenderCount: null,
  aboutUs: [],
  landingPageState: false
}

export default function profile(state = initial, action) {
  switch (action.type) {
    case FETCH_PROFILE_DATA_START:
    case UPDATE_PROFILE_DATA_START:
      return Object.assign({}, state, {
        profileFetching: action.fetching
      })
    case FETCH_PROFILE_DATA_SUCCESS:
      return Object.assign({}, state, {
        profile: action.response,
        profileFetching: action.fetching,
        profileRenderCount: 1
      })
    case FETCH_PROFILE_DATA_FAILURE:
      return Object.assign({}, state, {
        profileError: action.errors,
        profileFetching: action.fetching
      })
    case REMOVE_PROFILE_DATA:
      return Object.assign({}, state, {
        profile: null
      })
    case FETCH_USER_ADDRESS_START:
      return Object.assign({}, state, {
        addressFetching: action.fetching
      })
    case FETCH_USER_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        address: action.response,
        addressFetching: action.fetching
      })
    case FETCH_USER_ADDRESS_FAILURE:
      return Object.assign({}, state, {
        addressError: action.errors,
        addressFetching: action.fetching
      })
    case FETCH_EATER_BENEFITS_START:
      return Object.assign({}, state, {
        eaterBenefitsFetching: action.fetching
      })
    case FETCH_EATER_BENEFITS_SUCCESS:
      return Object.assign({}, state, {
        eaterBenefits: action.response,
        eaterBenefitsFetching: action.fetching
      })
    case FETCH_EATER_BENEFITS_FAILURE:
      return Object.assign({}, state, {
        eaterBenefitsError: action.errors,
        eaterBenefitsFetching: action.fetching
      })
    case ADD_USER_ADDRESS_START:
      return Object.assign({}, state, {
        addressFetching: action.fetching
      })
    case ADD_USER_ADDRESS_SUCCESS:
      if (action.response.preferred) {
        const newArray = [
          action.response,
          ...state.address.map((item) => ({ ...item, preferred: false }))
        ]
        return Object.assign({}, state, {
          addressFetching: action.fetching,
          address: newArray
        })
      } else {
        return Object.assign({}, state, {
          addressFetching: action.fetching,
          address: [action.response, ...state.address]
        })
      }
    case ADD_USER_ADDRESS_FAILURE:
      return Object.assign({}, state, {
        addressError: action.errors,
        addressFetching: action.fetching
      })
    case UPDATE_USER_ADDRESS_START:
      return Object.assign({}, state, {
        addressFetching: action.fetching
      })
    case UPDATE_USER_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        // address: updatedAddress,
        addressFetching: action.fetching
      })
    case UPDATE_USER_ADDRESS_FAILURE:
      return Object.assign({}, state, {
        addressError: action.errors,
        addressFetching: action.fetching
      })
    case DELETE_USER_ADDRESS_START:
      return Object.assign({}, state, {
        addressFetching: action.fetching
      })
    case DELETE_USER_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        addressFetching: action.fetching
      })
    case DELETE_USER_ADDRESS_FAILURE:
      return Object.assign({}, state, {
        addressError: action.errors,
        addressFetching: action.fetching
      })
    case UPDATE_PROFILE_DATA_SUCCESS:
      return Object.assign({}, state, {
        profileFetching: action.fetching,
        updateProfile: action.response
      })
    case UPDATE_PROFILE_DATA_FAILURE:
      return Object.assign({}, state, {
        updateProfileError: action.errors,
        profileFetching: action.fetching
      })
    case UPDATE_PROFILE_IMAGE_START:
      return Object.assign({}, state, {
        updateProfileImageFetching: action.fetching
      })
    case UPDATE_PROFILE_IMAGE_SUCCESS:
      return Object.assign({}, state, {
        updateProfileImageFetching: action.fetching,
        updateProfileImage: action.response
      })
    case UPDATE_PROFILE_IMAGE_FAILURE:
      return Object.assign({}, state, {
        updateProfileImageError: action.errors,
        updateProfileImageFetching: action.fetching
      })
    case LOGIN_DATA:
      return {
        ...state,
        loginData: action.data
      }
    case HEAR_ABOUT_US_SUCCESS:
      return {
        ...state,
        aboutUs: action.data
      }
    case HEAR_ABOUT_US_ERROR:
      return {
        ...state,
        aboutUs: []
      }
    case LOGOUT_HANDLER: {
      return initial
    }
    case LANDING_PAGE: {
      return {
        ...state,
        landingPageState: action.data
      }
    }
    default:
      return state
  }
}
