import React from 'react'
import { NavLink } from 'react-router-dom'
import AboutNavLink from './AboutNavLink'
import { useSelector } from 'react-redux'

const NavLinks = ({ showDropmenu, setShowDropmenu, setModal }) => {
  const profileFetching = useSelector((state) => state.profile.profileFetching)
  const profile = useSelector((state) => state.profile.profile)

  return (
    <>
      <li className="dd-nav__tab " onClick={setModal}>
        <NavLink to={'/how-it-works'} data-testid="howlink">
          How It Works
        </NavLink>
      </li>
      <li className="dd-nav__tab " onClick={setModal}>
        <NavLink to={'/meals'} data-testid="meallink">
          Menu
        </NavLink>
      </li>

      {!profileFetching && profile?.profile?.is_voucher_benefit && profile?.profile?.status_code === 'Activated' && (
        <li className="dd-nav__tab " onClick={setModal}>
          <NavLink to={'/voucher-meals'} data-testid="meallink">
            Voucher Menu
          </NavLink>
        </li>
      )}
      <AboutNavLink setShowDropmenu={setShowDropmenu} showDropmenu={showDropmenu} setModal={setModal} />
      <li className="dd-nav__tab " onClick={setModal}>
        <NavLink to={'/corporate'} data-testid="corplink">
          Corporate Program
        </NavLink>
      </li>
    </>
  )
}

export default NavLinks
