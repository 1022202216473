import React from 'react'
import ReactDOM from 'react-dom'
import 'assets/scss/index.scss'
import './assets/scss/styler.scss'
import App from './App'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AwsRum } from 'aws-rum-web'
import { HelmetProvider } from 'react-helmet-async'
import ReactErrorBoundary from 'ReactErrorBoundary'

try {
  const config = {
    sessionSampleRate: 1,
    guestRoleArn: process.env.REACT_APP_RUM_GUEST_ROLE_ARN,
    identityPoolId: process.env.REACT_APP_RUM_IDENTITY_POOL_ID,
    endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
    telemetries: ['performance', 'errors', 'http'],
    allowCookies: true,
    enableXRay: true
  }

  const APPLICATION_ID = process.env.REACT_APP_APPLICATION_ID
  const APPLICATION_VERSION = process.env.REACT_APP_APPLICATION_VERSION
  const APPLICATION_REGION = process.env.REACT_APP_APPLICATION_REGION

  new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config)
} catch (error) {
  console.log('rum', error)
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ReactErrorBoundary>
        <ToastContainer position="top-right" autoClose={5000} />
        <App />
      </ReactErrorBoundary>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
