import React from 'react'
import Loader from 'components/Loader'
import Navigation from 'components/Navigation'
import ChefHoc from 'components/Chef/ChefHoc'
import CorporateAdminHoc from 'components/CorporateAdmin/CorporateAdminHoc'

const FallbackPage = () => {
  const profileData = JSON.parse(localStorage.getItem('profile_data'))
  const NavigationDiv = () => {
    if (profileData?.is_chef) {
      return <ChefHoc />
    }
    if (profileData?.is_superuser) {
      return <CorporateAdminHoc />
    } else {
      return <Navigation />
    }
  }
  return (
    <>
      <NavigationDiv />
      <Loader />
    </>
  )
}

export default React.memo(FallbackPage)
