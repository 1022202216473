import { LOGOUT_HANDLER } from 'actions/account'
import {
  FETCH_CHEF_REVIEWS_COMPLETE,
  FETCH_CHEF_REVIEWS_START,
  FETCH_CHEF_REVIEWS_ERROR,
  FETCH_MEAL_REVIEWS_COMPLETE,
  FETCH_MEAL_REVIEWS_START,
  FETCH_MEAL_REVIEWS_ERROR
} from 'actions/reviews'

const initial = {
  chefFetching: false,
  chefReviews: [],
  chefError: null,
  mealFetching: false,
  mealReviews: [],
  mealError: null
}

export default function reviews(state = initial, action) {
  switch (action.type) {
    case FETCH_CHEF_REVIEWS_START:
      return {
        ...state,
        chefFetching: true
      }
    case FETCH_CHEF_REVIEWS_COMPLETE:
      return {
        ...state,
        chefFetching: false,
        chefReviews: action.response
      }
    case FETCH_CHEF_REVIEWS_ERROR:
      return {
        ...state,
        chefFetching: false,
        chefError: action.errors
      }
    case FETCH_MEAL_REVIEWS_START:
      return {
        ...state,
        mealFetching: true
      }
    case FETCH_MEAL_REVIEWS_COMPLETE:
      return {
        ...state,
        mealFetching: false,
        mealReviews: action.response
      }
    case FETCH_MEAL_REVIEWS_ERROR:
      return {
        ...state,
        mealFetching: false,
        mealError: action.errors
      }
    case LOGOUT_HANDLER: {
      return initial
    }
    default:
      return state
  }
}
